import amplitude from "amplitude-js";

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);

export interface AnalyticsEvent {
  eventName: AnalyticsEvents;
  options?: any;
}

export const sendEvent = (eventName: string, options: any) => {
  amplitude.getInstance().logEvent(eventName, {
    ...options,
    version: process.env.REACT_APP_VERSION,
  });
};

export const setIdentify = (key: string, value: string) => {
  const identify = new amplitude.Identify().set(key, value);
  amplitude.getInstance().identify(identify);
};

export const unsetIdentify = (key: string) => {
  const identify = new amplitude.Identify().unset(key);
  amplitude.getInstance().identify(identify);
};

const USER_ACCOUNT_KEY = "account";

export const setUserAccount = (value: string) => {
  setIdentify(USER_ACCOUNT_KEY, value);
};

export const unsetUserAccount = () => {
  unsetIdentify(USER_ACCOUNT_KEY);
};

export enum AnalyticsEvents {
  pageView = "page_view",
  walletConnected = "wallet_connected",
  stake = "transaction_stake",
  challenge = "transaction_challenge",
  withdrawChallenge = "transaction_withdrawChallenge",
  claim = "transaction_claimReward",
  verifyTest = "transaction_verifyTest",
  initiateUnstake = "transaction_initiateUnstake",
  withdrawStake = "transaction_withdrawStake",
  navTabClicked = "navigation_tabClick",
  footerLinkClicked = "footer_linkClick",
  createPoolSubmitted = "transaction_createPool",
  cancelWithdraw = "transaction_cancelWithdraw",
}
