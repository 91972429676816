import { Button, Grid } from "@material-ui/core";
import ClaimModal from "pages/AnteTestDetail/modals/ClaimModal";
import { useContext, useState } from "react";
import { useSelectTxnPending } from "state/selectors";
import { ActionPanelContext } from "../ActionPanelContext";
import { BigNumber } from "@ethersproject/bignumber";
import { useWallet } from "utils/wallet";

export const ChallengedActions = () => {
  const { account } = useWallet();

  const { pool, submitting } = useContext(ActionPanelContext);

  const {
    verifier,
    verifierBounty,
    challengedBalanceUnit,
    challengedStartAmount,
    challengerPayout,
  } = pool;
  const isTxnPending = useSelectTxnPending();

  const [claimVisible, setClaimVisible] = useState(false);
  const handleClaimOpen = () => {
    setClaimVisible(true);
  };

  const callerBonus = verifier === account ? verifierBounty : BigNumber.from(0);
  const decayLost = challengedStartAmount?.sub(challengedBalanceUnit);
  const challengerRewards = challengerPayout
    ?.sub(challengedStartAmount)
    .sub(callerBonus)
    .add(decayLost);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClaimOpen}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Claim Rewards
        </Button>
        <ClaimModal
          isShown={claimVisible}
          hide={() => {
            setClaimVisible(false);
          }}
          decayLost={decayLost}
          callerBonus={callerBonus}
          challengeRewards={challengerRewards}
        />
      </Grid>
    </Grid>
  );
};
