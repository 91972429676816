import { fetchAntePoolsData } from "utils/antepools/fetchAntePoolsPublic";
import { isMainnet } from "utils/constants";
import { useWallet, Wallet } from "utils/wallet";
import fetchAntePoolsDataDB from "utils/antepools/fetchAntePoolsDB";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import queryClient from "utils/queryClient";
import { PoolsCache } from "utils/antepools/cache";
import {
  ANTEPOOLS_ACCESSOR,
  GET_ANTEPOOLS_DETAILS_NODE,
  GET_ANTEPOOLS_NODE,
} from "db/queries/pool";
import { Pool } from "types";
import { queryResolver } from "db/graphql-query-resolver";
import { useRef } from "react";
import { Exact, Scalars } from "db/queries/types";

export type GetAntePoolsQueryVariables = Exact<{
  protocolName?: Scalars["String"];
  protocolNames?: Array<Scalars["String"]>;
  chainId?: Scalars["String"];
  isVisible?: Scalars["Boolean"];
  versions?: Array<Scalars["String"]>;
}>;

export type GetAntePoolsQuery = {
  [key in typeof ANTEPOOLS_ACCESSOR]?: Array<Pool>;
};

/**
 * Level 2 nested field filters must be custom implemented
 *
 * @param data
 * @param filters
 * @returns
 */
export const handlePoolFilters = <T>(
  data: Array<T>,
  filters: Record<string, any>
): Array<T> => {
  let filteredData = data;

  Object.keys(filters).forEach((field) => {
    if (filters[field] === undefined) return;

    filteredData = filteredData.filter((obj) => {
      if (field === "writerAddress") {
        return obj?.["testAuthor"]?.["address"] === filters[field];
      } else if (field === "address") {
        return obj?.["antePoolAddress"] === filters[field];
      }

      return obj[field] === filters[field];
    });
  });

  return filteredData;
};

export const getCachedPools = (walletData?: Partial<Wallet>): Array<Pool> => {
  const keyWithDetails = PoolsCache.keys.all(true, walletData);
  const key = PoolsCache.keys.all(false, walletData);

  const cachedwithDetails =
    queryClient.getQueryData<Array<Pool>>(keyWithDetails);
  if (typeof cachedwithDetails !== "undefined") return cachedwithDetails;

  return queryClient.getQueryData<Array<Pool>>(key);
};

const getFilteredPools = (data: GetAntePoolsQuery): Pool[] | undefined => {
  const fetchedPools = data?.[ANTEPOOLS_ACCESSOR];

  const filteredPools = fetchedPools?.filter(
    (pool) =>
      pool.anteTestName &&
      pool.anteTestAddress &&
      pool.stakingInfo &&
      pool.challengerInfo &&
      pool.protocolName &&
      pool.testedContracts
  );

  if (filteredPools?.length < fetchedPools?.length && isMainnet) {
    throw new Error("invalid pools fetched from DB");
  }

  return filteredPools;
};

export const formatOffChainData = (data?: GetAntePoolsQuery) => {
  if (data === undefined) return undefined;
  const filteredPools = getFilteredPools(data);
  return fetchAntePoolsDataDB(filteredPools);
};

export const onChainFetcher = async (
  walletData: Partial<Wallet>,
  pools: Pool[]
): Promise<Pool[]> => {
  return fetchAntePoolsData(walletData, pools);
};

type FetchPoolsResult = {
  pools?: Array<Pool>;
  loading?: boolean;
  loadingOffChain?: boolean;
  loadingOnChain?: boolean;
};

export const DEFAULT_VARIABLES = {
  isVisible: true,
  versions: ["v0.5", "v0.5.1", "v0.5.2"],
};

export const useAntePools = <TError = unknown>(
  withDetails: boolean = false,
  walletAddress?: string,
  variables?: GetAntePoolsQueryVariables,
  options?: UseQueryOptions<Pool[], TError, Pool[]>
) => {
  variables = {
    ...variables,
    ...DEFAULT_VARIABLES,
  };
  const result = useRef<FetchPoolsResult>({
    pools: undefined,
    loading: undefined,
    loadingOffChain: undefined,
    loadingOnChain: undefined,
  });
  const prevOffChainPools = useRef<Pool[]>();
  const { provider, networkId, account, refreshBalance, isNetworkSupported } =
    useWallet();

  if (typeof walletAddress === "undefined") {
    walletAddress = account;
  }

  const query = withDetails ? GET_ANTEPOOLS_DETAILS_NODE : GET_ANTEPOOLS_NODE;

  const offChainKey = PoolsCache.offChainKeys(query, variables) as QueryKey;

  const {
    data: offChainData,
    isLoading: isLoadingOffChain,
    isFetching,
  } = useQuery(
    offChainKey,
    async () => formatOffChainData(await queryResolver(query, variables)),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      initialData: () => {
        /**
         * If data is requested with details, we try and retrieve it from cache
         * If data is requested without details, we try and retrieve it
         * with details from cache.
         */
        const withDetailsKey = PoolsCache.offChainKeys(
          GET_ANTEPOOLS_DETAILS_NODE,
          variables
        ) as QueryKey;
        const cachedWithDetails =
          queryClient.getQueryData<Pool[]>(withDetailsKey);

        return cachedWithDetails;
      },
      ...options,
    }
  );

  const walletData: Partial<Wallet> = {
    networkId,
    provider,
    account: walletAddress,
    refreshBalance,
  };

  const key = variables
    ? PoolsCache.keys.filtered(withDetails, walletData, variables)
    : PoolsCache.keys.all(withDetails, walletData);

  const { data: onChainData, isLoading: isLoadingOnChain } = useQuery(
    key,
    () => onChainFetcher(walletData, offChainData),
    {
      enabled:
        isFetching === false &&
        offChainData !== undefined &&
        walletData.provider !== undefined &&
        walletAddress !== undefined &&
        walletData.account !== undefined &&
        walletData.account !== null &&
        isNetworkSupported,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      initialData: () => {
        const cacheWithoutFilters = getCachedPools(walletData);

        const cachedData =
          cacheWithoutFilters && variables
            ? handlePoolFilters(cacheWithoutFilters, variables)
            : cacheWithoutFilters;

        return cachedData;
      },
    }
  );

  if (prevOffChainPools.current !== offChainData) {
    prevOffChainPools.current = offChainData;
    result.current.pools = onChainData ?? offChainData;
  } else {
    result.current.pools = onChainData ?? result.current.pools;
  }

  result.current = {
    ...result.current,
    loading: isLoadingOffChain || isLoadingOnChain,
    loadingOffChain: isLoadingOffChain,
    loadingOnChain: isLoadingOnChain,
  };

  return result.current;
};
