import { makeStyles } from "@material-ui/core";
import { AnteHeaders, AnteHeadersType } from "components/AnteTable/AnteHeaders";
import { ProtocolTestRowProps } from "pages/ProtocolProfilePage/useColumns";

type StyleProps = {
  numColumns: number;
};

export const useStyles = makeStyles((theme) => ({
  testHeaders: {
    gridTemplateColumns: (props: StyleProps) =>
      `minmax(100px,2fr) 32px repeat(${
        props.numColumns - 2
      }, minmax(min-content, 1fr))`,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: (props: StyleProps) =>
        `repeat(${props.numColumns - 1}, minmax(min-content, 1fr))`,
    },
  },
}));

export const TestHeaders = (props: AnteHeadersType<ProtocolTestRowProps>) => {
  const styles = useStyles({ numColumns: props.headers.length });
  return <AnteHeaders {...props} classNames={styles.testHeaders} />;
};
