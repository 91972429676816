import { BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import { Web3Utils } from "utils/web3-utils";
import { AnalyticsEvents } from "analytics/analytics";
import { formatPool, formatPools } from "./utils";
import { Pool } from "types";
import { PoolsCache } from "utils/antepools/cache";
import { Ante, ChainId, Network, Pool as PoolSDK } from "@antefinance/ante-sdk";
import { Wallet } from "utils/wallet";
import { fetchAntePoolDataDB } from "./fetchAntePoolsDB";
import { getV05VersionByChain } from "utils/utils";

export const fetchAntePoolPublicData = async (
  walletData: Partial<Wallet>,
  pool: Pool
): Promise<Pool> => {
  const poolData = await fetchAntePoolsData(walletData, [pool]);
  return poolData?.[0];
};

export const fetchAntePoolsData = async (
  walletData: Partial<Wallet>,
  pools
): Promise<Array<Pool>> => {
  const data: Pool[] = await Promise.all(
    pools
      .filter((p: Pool | null | undefined) => p)
      .map(async (pool: Pool) => {
        // If user is connected to a network other than the pool network,
        // ignore fetching on chain data
        if (
          !pool.chainId ||
          walletData.networkId.toLowerCase() !== pool.chainId.toLowerCase()
        ) {
          return fetchAntePoolDataDB(pool);
        }

        return await fetchPool(pool, walletData);
      })
  );

  return formatPools(data);
};

export const fetchPoolStub = async (poolStub, walletData: Partial<Wallet>) => {
  const { anteTestAddress } = poolStub;

  const testData = await fetchTest(anteTestAddress, walletData);

  poolStub = {
    ...poolStub,
    ...testData,
    chainId: walletData.networkId,
  };

  poolStub.testAuthor = {
    address: testData.testAuthor,
    ensName: "",
  };

  const pool = await fetchPool(poolStub, walletData);

  return formatPool(pool);
};

export const fetchTest = async (
  testAddress: string,
  walletData: Partial<Wallet>
) => {
  const { provider, networkId } = walletData;

  const ante = new Ante(Network[ChainId[parseInt(networkId, 16)]], provider);
  const testInstance = ante.getTest(
    testAddress,
    getV05VersionByChain(networkId)
  );

  const methods = [
    { method: () => testInstance.getTestAuthor(), valueName: "testAuthor" },
    { method: () => testInstance.getProtocolName(), valueName: "protocolName" },
    { method: () => testInstance.getTestName(), valueName: "anteTestName" },
    {
      method: () => testInstance.getTestedContracts(),
      valueName: "testedContracts",
    },
  ];

  const { testAuthor, protocolName, testedContracts, anteTestName } =
    await Web3Utils.batch(methods);

  return {
    testAuthor,
    protocolName,
    testedContracts,
    anteTestName,
  };
};

export const fetchPool = async (pool: Pool, walletData: Partial<Wallet>) => {
  const { provider, networkId, account, refreshBalance } = walletData;

  const {
    protocolName,
    testedContracts,
    antePoolAddress,
    anteTestName: testName,
  } = pool;
  const ante = new Ante(Network[ChainId[parseInt(networkId, 16)]], provider);

  const poolInstance = ante.getPool(
    antePoolAddress,
    getV05VersionByChain(networkId)
  ) as PoolSDK.AntePoolInstance;

  const methods = [
    {
      method: () => poolInstance.getStakingDetails(),
      transform: (value: PoolSDK.PoolSideInfo) => ({
        ...value,
        numUsers: value.numUsers.toNumber(),
      }),
      valueName: "stakingInfo",
    },
    {
      method: () => poolInstance.getChallengerDetails(),
      transform: (value: PoolSDK.PoolSideInfo) => ({
        ...value,
        numUsers: value.numUsers.toNumber(),
      }),
      valueName: "challengerInfo",
    },
    {
      method: () => poolInstance.getTotalPendingWithdraw(),
      valueName: "totalPendingWithdraw",
    },
    {
      method: () => poolInstance.getUserStoredBalance(account, false),
      valueName: "stakedBalanceUnit",
    },
    {
      method: () => poolInstance.getUserStoredBalance(account, true),
      valueName: "challengedBalanceUnit",
    },
    {
      method: () => poolInstance.getPendingWithdrawAmount(account),
      valueName: "pendingWithdrawAmount",
    },
    {
      method: () => poolInstance.getPendingFailure(),
      valueName: "pendingFailure",
    },
    {
      method: () => poolInstance.getTimesVerified(),
      transform: (value: string) => parseInt(value),
      valueName: "numTimesVerified",
    },
    {
      method: () => poolInstance.getLastVerifiedBlock(),
      transform: (value: string) => parseInt(value),
      valueName: "lastVerifiedBlock",
    },
    {
      method: () => poolInstance.getVerifier(),
      valueName: "verifier",
    },
    {
      method: () => poolInstance.getUserStartAmount(account, false),
      valueName: "stakedStartAmount",
    },
    {
      method: () => poolInstance.getUserStartAmount(account, true),
      valueName: "challengedStartAmount",
    },
    {
      method: () => poolInstance.getVerifierBounty(),
      valueName: "verifierBounty",
    },
    {
      method: () => poolInstance.getCheckTestAllowedBlock(account),
      valueName: "checkTestAllowedBlock",
    },
  ];

  const data = await Web3Utils.batch(methods);
  const {
    stakingInfo,
    challengerInfo,
    totalPendingWithdraw,
    stakedBalanceUnit,
    challengedBalanceUnit,
    pendingWithdrawAmount,
    pendingFailure,
    numTimesVerified,
    lastUpdateBlock,
    lastVerifiedBlock,
    verifier,
    stakedStartAmount,
    challengedStartAmount,
    verifierBounty,
    checkTestAllowedBlock,
  } = data;

  const secondaryMethods = [
    {
      method: () => poolInstance.getPendingWithdrawAllowedTime(account),
      transform: (timestampInSeconds: string) => Number(timestampInSeconds),
      valueName: "pendingWithdrawAllowedTime",
      skip: pendingWithdrawAmount?.eq(0),
    },
    {
      method: () => poolInstance.getChallengerPayout(account),
      valueName: "challengerPayout",
      skip: challengedStartAmount?.eq(0),
    },
  ];

  const secondaryData = await Web3Utils.batch(secondaryMethods);
  const { pendingWithdrawAllowedTime, challengerPayout } = secondaryData;

  const sendTransaction = async (
    method: any,
    toastMsg: string,
    analyticsProps
  ) => {
    if (!account) {
      return Promise.reject();
    }

    await Web3Utils.send(
      method,
      {
        toastMessage: toastMsg,
        analyticsProperties: analyticsProps,
      },
      ante.getProvider()
    );

    refreshBalance();

    const poolData = await fetchAntePoolPublicData(walletData, pool);

    PoolsCache.setPool(poolData, walletData);
  };

  const stake = async (value: BigNumber): Promise<any> =>
    sendTransaction(
      () =>
        poolInstance.stake(
          { amount: value },
          Web3Utils.getWeb3Signer(provider, account)
        ),
      "Staking...",
      {
        eventName: AnalyticsEvents.stake,
        options: {
          value: Number(formatEther(value)),
          testName,
        },
      }
    );

  const challenge = async (value: BigNumber): Promise<any> =>
    sendTransaction(
      () =>
        poolInstance.challenge(
          { amount: value },
          Web3Utils.getWeb3Signer(provider, account)
        ),
      "Challenging...",
      {
        eventName: AnalyticsEvents.challenge,
        options: {
          value: Number(formatEther(value)),
          testName,
        },
      }
    );

  const withdrawChallenge = async (): Promise<any> =>
    sendTransaction(
      () =>
        poolInstance.unstakeAll(
          { isChallenger: true },
          Web3Utils.getWeb3Signer(provider, account)
        ),
      "Withdrawing challenge...",
      {
        eventName: AnalyticsEvents.withdrawChallenge,
        options: { testName },
      }
    );

  const claim = async (): Promise<any> =>
    sendTransaction(
      () => poolInstance.claim(Web3Utils.getWeb3Signer(provider, account)),
      "Claiming reward...",
      {
        eventName: AnalyticsEvents.claim,
        options: { testName },
      }
    );

  const checkTest = async (): Promise<any> =>
    sendTransaction(
      () => poolInstance.checkTest(Web3Utils.getWeb3Signer(provider, account)),
      "Verifying test...",
      {
        eventName: AnalyticsEvents.verifyTest,
        options: { testName },
      }
    );

  const unstakeAll = async (isChallenger: boolean): Promise<any> =>
    sendTransaction(
      () =>
        poolInstance.unstakeAll(
          { isChallenger },
          Web3Utils.getWeb3Signer(provider, account)
        ),
      isChallenger ? "Unstaking challenger stake..." : "Initiating unstake...",
      {
        eventName: isChallenger
          ? AnalyticsEvents.withdrawChallenge
          : AnalyticsEvents.initiateUnstake,
        options: { testName },
      }
    );

  const withdrawStake = async (): Promise<any> =>
    sendTransaction(
      () =>
        poolInstance.withdrawStake(Web3Utils.getWeb3Signer(provider, account)),
      "Withdrawing stake...",
      {
        eventName: AnalyticsEvents.withdrawStake,
        options: { testName },
      }
    );

  const cancelPendingWithdraw = async (): Promise<any> =>
    sendTransaction(
      () =>
        poolInstance.cancelPendingWithdraw(
          Web3Utils.getWeb3Signer(provider, account)
        ),
      "Cancelling withdrawal...",
      {
        eventName: AnalyticsEvents.cancelWithdraw,
        options: { testName },
      }
    );

  return {
    ...pool,
    stakingInfo,
    challengerInfo,
    totalPendingWithdraw,
    stakedBalanceUnit,
    challengedBalanceUnit,
    pendingWithdrawAmount,
    pendingWithdrawAllowedTime,
    pendingFailure,
    protocolName,
    numTimesVerified,
    lastUpdateBlock,
    lastVerifiedBlock,
    verifier,
    testName,
    challengerPayout,
    stakedStartAmount,
    challengedStartAmount,
    verifierBounty,
    checkTestAllowedBlock,
    testedContracts,
    sendTransaction,
    stake,
    challenge,
    withdrawChallenge,
    claim,
    checkTest,
    unstakeAll,
    withdrawStake,
    cancelPendingWithdraw,
  };
};
