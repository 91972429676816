import { makeStyles } from "@material-ui/core";
import GearBg from "assets/img/gearwave.png";

export const useStyles = makeStyles({
  root: {
    background: `center / cover no-repeat url(${GearBg})`,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
