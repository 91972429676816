import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { PoolSideInfo } from "config/types";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";

import { Avl, Pool, StakerInfo } from "types";
import {
  generateOptions,
  generateData,
} from "pages/AnteTestDetail/components/tvlChart";
import colors from "style/colors";
import ThreeEth from "assets/img/three-eth.svg";
import Participant from "assets/img/participant.svg";
import StakersPopup from "./StakersPopup";
import { updateStakerAmounts } from "utils/antepools/utils";
import { usePoolBalances } from "hooks/antepools/usePoolBalances";
import { formatTokenValue, getNetworkById } from "utils/utils";

const summaryStyles = makeStyles({
  legend: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  stakedLegend: {
    justifyContent: "flex-end",
  },
});

interface AntePoolSummaryProps {
  pool: Pool;
  stakingInfo: PoolSideInfo;
  challengerInfo: PoolSideInfo;
  verifiedStakers: StakerInfo[];
  verifiedChallengers: StakerInfo[];
  pendingWithdraw: number;
  stakedAvl: Array<Avl>;
  challengerAvl: Array<Avl>;
  stakedAndPendingWithdraw: number;
  stakedTotalAmount: number;
  challengedTotalAmount: number;
}

const AntePoolSummary: React.FC<AntePoolSummaryProps> = ({
  pool,
  stakingInfo,
  challengerInfo,
  verifiedStakers,
  verifiedChallengers,
  stakedAvl,
  challengerAvl,
  stakedAndPendingWithdraw,
  stakedTotalAmount,
  pendingWithdraw,
  challengedTotalAmount,
}) => {
  const chain = getNetworkById(pool.chainId);
  const token = chain.token;
  const { balances } = usePoolBalances(pool);
  const { stakerBalances, challengerBalances } = balances;
  const styles = summaryStyles();
  let options;
  if (stakedAvl) {
    const allAvl = stakedAvl.concat(challengerAvl);
    const values = allAvl.map((avl) => avl.value);
    const maxValue = Math.max(...values);
    options = generateOptions(maxValue, chain.token);
  }

  let verifiedStakersFiltered = verifiedStakers?.filter(
    ({ amount }) => amount !== "0"
  );
  let verifiedChallengersFiltered = verifiedChallengers?.filter(
    ({ amount }) => amount !== "0"
  );
  const hasVerifiedStaker = verifiedStakersFiltered?.length > 0;
  const hasVerifiedChallenger = verifiedChallengersFiltered?.length > 0;
  const stakeTip =
    `Total: ${formatTokenValue(stakedTotalAmount.toFixed(16), token)}` +
    "\n" +
    `Pending Withdraw: ${formatTokenValue(pendingWithdraw.toFixed(16), token)}`;

  const colorLegend = (color) => (
    <div
      style={{
        backgroundColor: color,
        height: "24px",
        width: "24px",
        borderRadius: "50%",
      }}
    />
  );

  verifiedStakers =
    verifiedStakers !== undefined &&
    stakerBalances &&
    Object.entries(stakerBalances)
      ? updateStakerAmounts(verifiedStakers, stakerBalances)
      : verifiedStakers;

  verifiedChallengers =
    verifiedChallengers !== undefined &&
    challengerBalances &&
    Object.entries(challengerBalances)
      ? updateStakerAmounts(verifiedChallengers, challengerBalances)
      : verifiedChallengers;

  const emptyAvl = [{ value: 0, timestamp: 0 }];
  return (
    <div className="antePoolSummary">
      <br />
      <Line
        data={(canvas) =>
          generateData(
            canvas,
            stakedAvl ? stakedAvl : emptyAvl,
            challengerAvl ? challengerAvl : emptyAvl
          )
        }
        // @ts-ignore
        options={options}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">
                <div className={cn(styles.legend, styles.stakedLegend)}>
                  Staked
                  {colorLegend(colors.stakeBlue)}
                </div>
              </TableCell>
              <TableCell align="center" />
              <TableCell>
                <div className={styles.legend}>
                  {colorLegend(colors.challengePink)}
                  Challenged
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ verticalAlign: "bottom" }}>
              <TableCell align="right">
                {pool.pendingFailure ? (
                  <Typography variant="h6">-</Typography>
                ) : (
                  <Tooltip
                    title={
                      <div style={{ whiteSpace: "pre-line" }}>{stakeTip}</div>
                    }
                    placement="top"
                    arrow
                  >
                    <Typography variant="h6">
                      <span>
                        {formatTokenValue(
                          stakedAndPendingWithdraw.toFixed(3),
                          token
                        )}
                      </span>
                    </Typography>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align="center">
                <Tooltip
                  title={
                    "Total value locked includes capital from stakers which have initiated withdrawals"
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <img src={ThreeEth} alt="three eth logos" />
                    <Typography variant="body1">Total value locked</Typography>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>
                {pool.pendingFailure ? (
                  <Typography variant="h6">-</Typography>
                ) : (
                  <Tooltip
                    title={formatTokenValue(
                      challengedTotalAmount.toFixed(16),
                      token
                    )}
                    placement="top"
                    arrow
                  >
                    <Typography variant="h6">
                      <span>
                        {formatTokenValue(
                          challengedTotalAmount.toFixed(3),
                          token
                        )}
                      </span>
                    </Typography>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: "bottom" }}>
              <TableCell align="right">
                <div style={{ display: "inline-flex" }}>
                  <Typography display="inline" variant="h6">
                    {stakingInfo.numUsers}
                  </Typography>
                  {hasVerifiedStaker && (
                    <StakersPopup
                      title={"Verified Stakers"}
                      stakers={verifiedStakers}
                      popDirection={"left"}
                      chain={chain}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                <Tooltip
                  title={
                    "Number of participants does not include stakers which have initiated withdrawal"
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <img src={Participant} alt="participant" />
                    <Typography variant="body1">Participants</Typography>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div style={{ display: "inline-flex" }}>
                  <Typography display="inline" variant="h6">
                    {challengerInfo.numUsers}
                  </Typography>
                  {hasVerifiedChallenger && (
                    <StakersPopup
                      title={"Verified Challengers"}
                      stakers={verifiedChallengers}
                      popDirection={"right"}
                      chain={chain}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AntePoolSummary;
