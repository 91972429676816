import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const useStyles = makeStyles({
  progress: {
    marginTop: "16px",
    borderColor: "#2196F3",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: colors.primaryBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  warning: {
    overflowWrap: "anywhere",
    marginTop: "16px",
    borderColor: "#DA160C",
    backgroundColor: "rgba(218,22,12,0.10)",
    display: "flex",
    alignItems: "flex-start",
    color: "#DA160C",
    padding: "8px 16px",
    "& p": {
      marginTop: "6px",
    },
  },
  leftSpacing: {
    marginLeft: "8px",
  },
  complete: {
    marginTop: "16px",
    borderColor: "#4caf50",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
  },
  completeIcon: {
    color: "#4caf50",
  },
});
