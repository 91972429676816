import { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import VerifyImage from "assets/img/verify.svg";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";

interface VerifyModalProps {
  isShown: boolean;
  hide: () => void;
}

const VerifyModal = ({ isShown, hide }: VerifyModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await pool.checkTest();
      hide();
      // trigger success popup
    } catch (error: any) {
      console.error(error);
      window.rollbar.warn("VerifyModal:handleSubmit", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
        <DialogTitle>Verify Ante Test</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img src={VerifyImage} alt="Check Test" height="128" />
          </div>
          <DialogContentText>
            <Typography variant="body1" display="block" gutterBottom>
              You may verify the test as often as you want, but calling the test
              will cost you gas.
            </Typography>
            <Typography variant="h6" display="block" gutterBottom>
              First Caller Bonus
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              If you are the person who triggers a failed test, you will receive
              an extra reward worth{" "}
              <strong>5% of the entire staker balance</strong>. The remaining
              95% of the staker balance will be split according to your share.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button
            style={{ width: "85px" }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {submitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerifyModal;
