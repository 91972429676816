import gql from "graphql-tag";
import { PROTOCOL_VERSION_FIELDS } from "../fragments/protocolVersion";

export const PROTOCOL_VERSIONS_ACCESSOR = "protocolVersionCollections";

export const GET_PROTOCOL_VERSIONS_NODE = gql`
${PROTOCOL_VERSION_FIELDS}
query GetProtocolVersions($protocol: String) {
  ${PROTOCOL_VERSIONS_ACCESSOR}(query: { protocol: $protocol }) {
    ...ProtocolVersionFields
  }
}
`;
