import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalizationState } from "state/types";
import { Currency } from "types/Currency";

const initialState: LocalizationState = {
  currency: {
    code: "USD",
    symbol: "$",
  },
};

export const localizationSlice = createSlice({
  name: "Localization",
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = action.payload;
    },
  },
});

export const { setCurrency } = localizationSlice.actions;

export default localizationSlice.reducer;
