import {
  GET_PROTOCOL_VERSIONS_NODE,
  PROTOCOL_VERSIONS_ACCESSOR,
} from "db/queries/protocolVersion";
import { ProtocolVersion } from "types";
import { queryResolver } from "db/graphql-query-resolver";
import { UseQueryOptions, useQuery, QueryKey } from "react-query";
import { Exact, Scalars } from "db/queries/types";

export type GetProtocolVersionsQueryVariables = Exact<{
  protocol: Scalars["String"];
}>;

export type GetProtocolVersionsQuery = {
  [key in typeof PROTOCOL_VERSIONS_ACCESSOR]?: Array<ProtocolVersion>;
};

export const useProtocolVersions = <
  TData = GetProtocolVersionsQuery,
  TError = unknown
>(
  variables?: GetProtocolVersionsQueryVariables,
  options?: UseQueryOptions<GetProtocolVersionsQuery, TError, TData>
) => {
  const query = GET_PROTOCOL_VERSIONS_NODE;

  const key = [query, variables] as QueryKey;
  const { data, isLoading } = useQuery(
    key,
    () => queryResolver(query, variables),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      ...options,
    }
  );

  return {
    versions: data?.[PROTOCOL_VERSIONS_ACCESSOR],
    loading: isLoading,
  };
};
