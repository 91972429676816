import React, { useContext, useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { parseEther, formatUnits } from "@ethersproject/units";

import { TOKEN_CAP } from "utils/constants";
import { useWallet } from "utils/wallet";
import StakeInput from "components/StakeInput";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";
import { formatTokenValue, getNetworkById } from "utils/utils";

interface AddChallengeModalProps {
  isShown: boolean;
  hide: () => void;
}

const AddChallengeModal = ({ isShown, hide }: AddChallengeModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);

  const chain = getNetworkById(pool.chainId);
  const token = chain.token;
  const { challenge, challengedBalanceUnit: currentBalance } = pool;
  const [addChallengeAmt, setAddChallengeAmt] = useState(0.0);
  const [helperText, setHelperText] = useState("");
  const [errorEntry, setErrorEntry] = useState(false);
  const [firstEntry, setFirstEntry] = useState(false);

  const handleInputChange = (value: string) => {
    setAddChallengeAmt(Number(value));
    setFirstEntry(true);
  };

  const currentBalanceEther = Number(formatUnits(currentBalance));

  const { balance } = useWallet();

  const validateSubmit = async () => {
    if (
      addChallengeAmt < TOKEN_CAP[pool.chainId.toLowerCase()]?.min ||
      addChallengeAmt + currentBalanceEther >
        TOKEN_CAP[pool.chainId.toLowerCase()]?.max
    ) {
      console.warn("INVALID: ", addChallengeAmt);
      setErrorEntry(true);
    } else if (addChallengeAmt * 10 ** chain.decimals > balance.number) {
      console.warn("INVALID: ", addChallengeAmt, " > ", balance.number);
      setErrorEntry(true);
    } else {
      setErrorEntry(false);
      // TODO: Add AddChallenge Hook
      const challengeAmountWei = parseEther(
        addChallengeAmt.toFixed(chain.decimals)
      );

      setSubmitting(true);
      try {
        await challenge(challengeAmountWei);
        hide();
        // trigger success popup
      } catch (error: any) {
        console.error(error);
        window.rollbar.warn("AddChallengeModal:validateSubmit", error);
        // trigger fail popup
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (addChallengeAmt * 10 ** chain.decimals > balance.number) {
      setHelperText(
        "Amount is greater than wallet balance: " + balance.formattedString
      );
      setErrorEntry(true);
    } else if (
      addChallengeAmt < TOKEN_CAP[pool.chainId.toLowerCase()]?.min ||
      addChallengeAmt + currentBalanceEther >
        TOKEN_CAP[pool.chainId.toLowerCase()]?.max
    ) {
      setHelperText(
        addChallengeAmt < TOKEN_CAP[pool.chainId.toLowerCase()]?.min
          ? `Amount must be greater than ${
              TOKEN_CAP[pool.chainId.toLowerCase()]?.min
            } ${token}`
          : `Total amount must be less than ${
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max
            }.0 ${token}`
      );
      setErrorEntry(firstEntry);
    } else {
      setHelperText("");
      setErrorEntry(false);
    }
  }, [
    addChallengeAmt,
    balance,
    currentBalanceEther,
    firstEntry,
    token,
    pool.chainId,
    chain.decimals,
  ]);

  return (
    <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
      <DialogTitle>Add Challenge</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" display="block" gutterBottom>
            Enter the additional amount you would like to challenge (min{" "}
            {formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.min.toString(),
              token
            )}
            , up to{" "}
            {formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
              token
            )}{" "}
            total)
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <StakeInput
            id="add-challenge-amount"
            onChange={handleInputChange}
            helperText={helperText}
            error={errorEntry}
            isChallenge
            currentBalance={currentBalance}
            chain={getNetworkById(pool.chainId)}
          />
        </DialogContentText>
        <DialogContentText>
          <TableContainer>
            <Table size="small" aria-label="challenge breakdown">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Current balance
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(currentBalanceEther.toFixed(16))}
                      placement="top"
                      arrow
                    >
                      <span>
                        {formatTokenValue(currentBalanceEther.toFixed(3))}
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Additional challenge
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(addChallengeAmt.toFixed(16))}
                      placement="top"
                      arrow
                    >
                      <span>
                        {formatTokenValue(addChallengeAmt.toFixed(3))}
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Total balance</strong>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        (currentBalanceEther + addChallengeAmt).toFixed(16)
                      )}
                      placement="top"
                      arrow
                    >
                      <span>
                        <strong>
                          {formatTokenValue(
                            (currentBalanceEther + addChallengeAmt).toFixed(3)
                          )}
                        </strong>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="body2"
            display="block"
            color="textSecondary"
            gutterBottom
          >
            *Total {token} cannot exceed{" "}
            {formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
              token
            )}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Cancel
        </Button>
        <Button
          onClick={validateSubmit}
          variant="contained"
          color="primary"
          disabled={submitting || errorEntry || addChallengeAmt === 0}
        >
          {submitting ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddChallengeModal;
