import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StakeModal from "pages/AnteTestDetail/modals/StakeModal";
import ChallengeModal from "pages/AnteTestDetail/modals/ChallengeModal";
import { useContext, useState } from "react";
import { useSelectTxnPending } from "state/selectors";
import { ActionPanelContext } from "../ActionPanelContext";

export const NoPositionActions = () => {
  const { submitting } = useContext(ActionPanelContext);
  const [stakeVisible, setStakeVisible] = useState(false);
  const [challengeVisible, setChallengeVisible] = useState(false);
  const isTxnPending = useSelectTxnPending();

  const handleStakeOpen = () => {
    setStakeVisible(true);
  };
  const handleChallengeOpen = () => {
    setChallengeVisible(true);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStakeOpen}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Stake
        </Button>
        <StakeModal
          isShown={stakeVisible}
          hide={() => {
            setStakeVisible(false);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChallengeOpen}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Challenge
        </Button>
        <ChallengeModal
          isShown={challengeVisible}
          hide={() => {
            setChallengeVisible(false);
          }}
        />
      </Grid>
    </Grid>
  );
};
