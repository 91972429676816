import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";

import colors from "style/colors";

import StakeIcon from "assets/img/thumbs-stake.svg";
import ChallengeIcon from "assets/img/thumbs-challenge.svg";
import { formatTokenValue } from "utils/utils";

interface PositionChipProps {
  isChallenger?: boolean;
  amount?: string;
  status?: string;
  isFailed?: boolean;
  isPending?: boolean;
  token?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    borderRadius: "20px",
    whiteSpace: "nowrap",
    fontWeight: 600,
    display: "inline-flex",
    flexDirection: "row",
    lineHeight: "16px",
    fontSize: "14px",
    padding: "8px 8px",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  staked: {
    backgroundColor: colors.stakeBlue,
  },
  challenged: {
    backgroundColor: colors.challengePink,
  },
  failed: {
    backgroundColor: colors.failedRed,
  },
  pending: {
    backgroundColor: colors.black42,
  },
  icon: {
    width: "16px",
    marginRight: "4px",
  },
}));

const PositionChip = ({
  isChallenger,
  amount,
  token,
  status,
  isFailed = false,
  isPending = false,
}: PositionChipProps) => {
  const styles = useStyles();

  let content;
  if (isFailed) {
    content = "Failed";
  } else if (isPending) {
    content = "Pending";
  } else {
    content = formatTokenValue(amount, token);
  }

  const body = (
    <div
      className={cn(styles.root, {
        [styles.staked]: !isChallenger,
        [styles.challenged]: isChallenger,
        [styles.failed]: isFailed,
        [styles.pending]: isPending,
      })}
    >
      {!isFailed &&
        !isPending &&
        (isChallenger ? (
          <img src={ChallengeIcon} alt="" className={styles.icon} />
        ) : (
          <img src={StakeIcon} alt="" className={styles.icon} />
        ))}
      {content}
    </div>
  );
  return status ? (
    <Tooltip title={status} placement="top" arrow>
      {body}
    </Tooltip>
  ) : (
    body
  );
};

export default PositionChip;
