import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const useStyles = makeStyles({
  networksContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  networkItem: {
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "1rem",
    border: `1px solid ${colors.black23}`,
    padding: "0.5rem",
    borderRadius: "0.5rem",
    alignItems: "center",
    gap: "0.5rem",
  },
  networkLabel: {
    fontWeight: 500,
  },
  logo: {
    borderRadius: "1rem",
    width: "2rem",
    height: "2rem",
  },
});
