import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

import GoldMedal from "assets/img/medal-gold.svg";
import SilverMedal from "assets/img/medal-silver.svg";
import BronzeMedal from "assets/img/medal-bronze.svg";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    background: "white",
    width: "100%",
    padding: "16px 24px",
    marginBottom: "8px",
    gap: "16px",
    position: "relative",
    lineHeight: "28px",
    cursor: "pointer",
    boxShadow:
      "0px 3px 8px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
    "&:last-child": {
      marginBottom: "0",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    width: "100%",
  },
  expandedSection: {
    width: "100%",
  },
  hideSection: {
    display: "none",
  },
  rotate: {
    transform: "rotate(180deg)",
  },
  testList: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "calc(10% + 24px)",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
  protocolGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  protocolName: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    color: colors.black54,
  },
  testName: {
    fontSize: "16px",
    width: "fit-content",
  },
  medal: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "28px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  first: {
    backgroundImage: `url(${GoldMedal})`,
  },
  second: {
    backgroundImage: `url(${SilverMedal})`,
  },
  third: {
    backgroundImage: `url(${BronzeMedal})`,
  },
  tableRow: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: "white",
    width: "100%",
    padding: "16px 24px",
    marginBottom: "8px",
    gap: "16px",
    position: "relative",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  rowItem: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  rowItemSmall: {
    fontWeight: 400,
  },
  link: {
    color: colors.linkBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  iconContainer: {
    position: "absolute",
    right: "18px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      right: "12px",
    },
  },
  expandIcon: {
    height: "24px",
    width: "24px",
    [theme.breakpoints.down("md")]: {
      height: "16px",
      width: "16px",
    },
  },
}));
