import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import { panelStyles } from "style/styles";

import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { ConnectWalletContainer } from "components/ConnectWallet/ConnectWalletContainer";

export const ConnectWalletView = () => {
  const panelClasses = panelStyles();

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} md={8} lg={9}>
        <Card className={panelClasses.root}>
          <div>
            <CardContent>
              <ConnectWalletContainer />
            </CardContent>
            <CardActions
              style={{ justifyContent: "center", marginBottom: "12px" }}
            >
              <ConnectWalletButton />
            </CardActions>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};
