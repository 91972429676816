import { Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Argument } from "classnames";
import React, { ComponentType, ReactNode } from "react";
import {
  Column,
  TableOptions,
  TableState,
  useSortBy,
  useTable,
} from "react-table";
import { useStyles } from "./AnteTable.styles";
import { AnteRow, AnteRowType } from "./AnteRow";
import { AnteHeaders, AnteHeadersType } from "./AnteHeaders";

export type AlignType = "left" | "center" | "right";

export type AnteColumn<T extends {}> = Column<T> & {
  align?: AlignType;
  tooltip?: string;
  styles?: ClassNameMap;
  SkeletonCell?: ReactNode;
  classNames?: Argument;
};

interface AnteTableProps<T extends {}> extends TableOptions<T> {
  columns: Array<AnteColumn<T>>;
  data: Array<T>;
  isLoading: boolean;
  RowComponent?: ComponentType<AnteRowType<T>>;
  HeadersComponent?: ComponentType<AnteHeadersType<T>>;
  emptyText?: ReactNode;
  initialState?: Partial<TableState<T>>;
}

export const AnteTable = <T extends Record<string, any>>({
  data,
  columns,
  isLoading,
  RowComponent,
  HeadersComponent,
  emptyText,
  initialState,
}: AnteTableProps<T>) => {
  const styles = useStyles({ numColumns: columns.length });

  const tableData = React.useMemo(
    () => (isLoading ? Array(10).fill({}) : data),
    [isLoading, data]
  );

  const tableColumns = React.useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            Cell: column.SkeletonCell ? column.SkeletonCell : <></>,
          }))
        : columns,
    [isLoading, columns]
  );

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable<T>(
      {
        columns: tableColumns,
        data: tableData,
        initialState: initialState,
        disableSortRemove: true,
      },
      useSortBy
    );

  return (
    <React.Fragment>
      <div
        data-testid="ante-table"
        {...getTableProps([
          {
            className: styles.root,
          },
        ])}
      >
        {HeadersComponent ? (
          <HeadersComponent headers={headers} />
        ) : (
          <AnteHeaders headers={headers} />
        )}
        <div
          {...getTableBodyProps([
            {
              className: styles.content,
            },
          ])}
        >
          {rows.map((row, i) => {
            prepareRow(row);
            return RowComponent ? (
              <RowComponent
                key={row.getRowProps().key}
                numberOfColumns={columns.length}
                rowData={row}
              />
            ) : (
              <AnteRow
                key={row.getRowProps().key}
                numberOfColumns={columns.length}
                rowData={row}
              />
            );
          })}
        </div>
        {!isLoading &&
          data.length === 0 &&
          (emptyText ? (
            emptyText
          ) : (
            <Typography variant="body1" className={styles.emptyTableContainer}>
              No data to display
            </Typography>
          ))}
      </div>
    </React.Fragment>
  );
};
