import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";
import WithdrawImage from "assets/img/start-withdrawal.svg";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";

interface UnlockStakeModalProps {
  isShown: boolean;
  hide: () => void;
}

const UnlockStakeModal = ({ isShown, hide }: UnlockStakeModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);
  const { unstakeAll } = pool;

  const handleClick = async () => {
    try {
      setSubmitting(true);
      // success popup
      await unstakeAll(false);
      hide();
    } catch (error: any) {
      console.error(error);
      window.rollbar.warn("UnlockStakeModal:handleClick", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
        <DialogTitle>Start Stake Withdrawal</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img src={WithdrawImage} alt="Start Withdrawal" height="128" />
          </div>
          <DialogContentText>
            <Typography variant="body1" display="block" gutterBottom>
              It will take around 1 day to unlock your stake for withdrawal.
              During this time, you will no longer accumulate decay rewards from
              Challengers.
            </Typography>
            <Typography
              variant="body2"
              display="block"
              color="textSecondary"
              gutterBottom
            >
              Note: If the test fails before you withdraw your stake, you will
              still forfeit your stake.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleClick}
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {submitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnlockStakeModal;
