import { formatTokenValue } from "utils/utils";

export const generateOptions = (maxValue: number, token: string) => ({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (dataPoint) => {
          return ` ${formatTokenValue(dataPoint.formattedValue, token)}`;
        },
      },
    },
  },
  datasets: {
    line: {
      stepped: true,
      // tension: 0.01,
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day",
      },
    },
    y: {
      beginAtZero: true,
      suggestedMax: maxValue * 1.05,
    },
  },
  elements: {
    point: {
      pointRadius: 1,
      pointHitRadius: 5,
    },
  },
});
export const generateData = (canvas, stakedAvl, challengedAvl) => {
  const stakedData = stakedAvl.map((avl) => ({
    x: avl.timestamp,
    y: avl.value,
  }));
  const challengedData = challengedAvl.map((avl) => ({
    x: avl.timestamp,
    y: avl.value,
  }));

  // Duplicate the latest value to make sure the chart always appears update up to today
  const now = Date.now();
  if (stakedData.length > 0) {
    stakedData.push({ x: now, y: stakedData[stakedData.length - 1].y });
  }
  if (challengedData.length > 0) {
    challengedData.push({
      x: now,
      y: challengedData[challengedData.length - 1].y,
    });
  }

  const ctx = canvas.getContext("2d");
  const { height, width } = canvas;
  const stakedGradient = ctx.createLinearGradient(
    width / 2,
    0,
    width / 2,
    height
  );
  stakedGradient.addColorStop(0, "rgba(0, 100, 214, 0.25)");
  stakedGradient.addColorStop(0.5, "rgba(0, 100, 214, 0)");
  const challengedGradient = ctx.createLinearGradient(
    width / 2,
    0,
    width / 2,
    height
  );
  challengedGradient.addColorStop(0, "rgba(222, 69, 142, 0.25)");
  challengedGradient.addColorStop(0.5, "rgba(222, 69, 142, 0)");
  return {
    datasets: [
      {
        label: "Challenged AVL",
        data: challengedData,
        borderColor: "rgba(222, 69, 142, 1)",
        fillColor: challengedGradient,
        fill: {
          target: "origin",
          above: challengedGradient,
        },
      },
      {
        label: "Staked AVL",
        data: stakedData,
        borderColor: "rgba(0, 100, 214, 1)",
        fill: {
          target: "origin",
          above: stakedGradient,
        },
      },
    ],
  };
};
