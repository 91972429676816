import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const accordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      boxShadow: "none",
      borderRadius: "0",
      padding: "0",
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "0",
        minHeight: "48",
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // @ts-ignore
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      "&$expanded": {
        margin: "0",
      },
    },
    expanded: {
      minHeight: "48",
      margin: "0",
    },
  })
);

export const panelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "8px",
      boxShadow:
        "0px 5px 16px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.1)",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "16px",
      },
    },
  })
);

export const cardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "8px",
      boxShadow:
        "0px 5px 16px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.1)",
    },
  })
);

export const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "16px",
      boxShadow:
        "0px 5px 32px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.1)",
    },
    rainbowBorder: {
      border: "2px solid",
      borderRadius: "16px",
      backgroundOrigin: "border-box",
      backgroundClip: "content-box, border-box",
      borderImage: "linear-gradient(90deg, #0064D6 0%, #DE458E 100%)",
      borderImageSlice: 0,
      backgroundImage:
        "linear-gradient(white, white), linear-gradient(90deg, #0064D6 0%, #DE458E 100%)",
    },
  })
);
