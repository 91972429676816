import { BigNumber } from "@ethersproject/bignumber";

import { PoolSideInfo } from "config/types";
import { Pool } from "types";

import { formatPools } from "./utils";

const poolInfo = (poolSideInfo: PoolSideInfo) => {
  return {
    numUsers: poolSideInfo?.numUsers,
    totalAmount: BigNumber.from(poolSideInfo?.totalAmount),
  };
};

export const fetchAntePoolDataDB = async (pool?: Pool): Promise<Pool> => {
  if (pool === undefined || pool === null) return undefined;
  return (await fetchAntePoolsDataDB([pool]))?.[0];
};

const fetchAntePoolsDataDB = (pools: Pool[]) => {
  const data = pools.reduce((result, pool) => {
    if (pool.stakingInfo && pool.challengerInfo) {
      result.push({
        ...pool,
        testName: pool.anteTestName,
        stakingInfo: poolInfo(pool.stakingInfo),
        challengerInfo: poolInfo(pool.challengerInfo),
        totalPendingWithdraw: BigNumber.from(pool.pendingWithdrawAmount),
      });
    } else {
      // invalid pool data
    }
    return result;
  }, []);
  return formatPools(data);
};

export default fetchAntePoolsDataDB;
