import { queryResolver } from "db/graphql-query-resolver";
import {
  GET_TEST_WRITERS_NODE,
  TEST_WRITERS_ACCESSOR,
} from "db/queries/testWriters";
import { Exact, Scalars } from "db/queries/types";
import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { TestWriter } from "types/TestWriter";

type FetchWritersResult = {
  writers: Array<TestWriter>;
  loading: boolean;
};

export type GetTestWritersQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type GetTestWritersQuery = {
  [key in typeof TEST_WRITERS_ACCESSOR]?: Array<TestWriter>;
};

export const useTestWriters = <TData = GetTestWritersQuery, TError = unknown>(
  variables?: GetTestWritersQueryVariables,
  options?: UseQueryOptions<GetTestWritersQuery, TError, TData>
): FetchWritersResult => {
  const query = GET_TEST_WRITERS_NODE;

  const key = [query, variables] as QueryKey;
  const { data, isLoading } = useQuery(
    key,
    () => queryResolver(query, variables),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      ...options,
    }
  );

  return {
    writers: data?.[TEST_WRITERS_ACCESSOR],
    loading: isLoading,
  };
};
