import { Ante, ChainId, Network } from "@antefinance/ante-sdk";
import { useEffect, useState } from "react";
import { useWallet } from "utils/wallet";

const useAnteSDK = () => {
  const { provider, networkId } = useWallet();
  const [ante, setAnte] = useState<Ante>();

  useEffect(() => {
    const connectedNetwork = Network[ChainId[parseInt(networkId, 16)]];
    if (!connectedNetwork) return;
    setAnte(new Ante(connectedNetwork, provider));
  }, [provider, networkId]);

  return ante;
};

export default useAnteSDK;
