import { makeStyles } from "@material-ui/core";
import colors from "style/colors";
import fontType from "style/typography";
import { globalStyles } from "utils/styles";

export const useStyles = makeStyles((theme) => ({
  root: { padding: "8px" },
  nameContainer: {
    ...fontType.eyebrow,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  addressContainer: {
    ...fontType.subtextMedium,
    marginBottom: "8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  protocolTitle: {
    ...fontType.titleMedium,
    margin: ".5rem 0",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  author: {
    marginBottom: "24px",
  },
  description: {
    ...fontType.bodyMedium,
    color: colors.black54,
  },
  logoContainer: {
    borderRadius: "8px",
    width: "5rem",
    height: "5rem",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "3rem",
      height: "3rem",
    },
  },
  verified: {
    width: "22px",
    height: "22px",
    marginLeft: "9px",
    display: "flex",
  },
  protocolInfoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  protocolLeftSideContainer: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      alignItems: "center",
      gap: ".5rem",
    },
  },
  protocolRightSideContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  protocolContactsContainer: {
    display: "flex",
    margin: ".5rem 0",
    justifyContent: "space-between",
  },
  protocolSocialMediaContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
    "& a": {
      marginLeft: 14,
    },
  },
  protocolUrl: {
    ...fontType.buttonMedium,
    fontWeight: 400,
    marginBottom: 10,
  },
  contactUs: {
    ...fontType.bodySmall,
    marginTop: "6rem",
    marginBottom: -8,
    color: colors.black69,
    "& a": {
      color: colors.linkBlue,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },
  featuredTitle: {
    fontWeight: 500,
    color: colors.black69,
  },
  link: {
    ...globalStyles.link,
    display: "block",
    ...fontType.buttonMedium,
    fontWeight: 400,
    margin: "0.375rem 0",
  },
}));
