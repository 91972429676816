import { Typography } from "@material-ui/core";
import { Audit as AuditType } from "types";
import { useStyles } from "./SecurityCard.styles";
import { useTypography } from "style/typography";
import { Audit } from "./Audit";

type AuditsProps = {
  audits: Array<AuditType>;
};

export const Audits = ({ audits }: AuditsProps) => {
  const styles = useStyles();
  const typography = useTypography();

  if (audits.length === 0) return null;
  return (
    <div className={styles.stats}>
      <Typography className={typography.labelMedium}>Audits</Typography>
      {audits.map((audit) =>
        audit.url ? (
          <a
            key={audit.name}
            href={audit.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Audit key={audit.name} audit={audit} />
          </a>
        ) : (
          <Audit key={audit.name} audit={audit} />
        )
      )}
    </div>
  );
};
