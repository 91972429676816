import {
  GET_SOURCE_CODES_NODE,
  SOURCE_CODES_ACCESSOR,
} from "db/queries/sourceCodes";
import { SourceCode } from "types";
import { queryResolver } from "db/graphql-query-resolver";
import { UseQueryOptions, useQuery, QueryKey } from "react-query";
import { Exact, Scalars } from "db/queries/types";

export type GetSourceCodesQueryVariables = Exact<{
  addresses: Array<Scalars["String"]>;
  chainId: Scalars["String"];
}>;

export type GetSourceCodesQuery = {
  [key in typeof SOURCE_CODES_ACCESSOR]?: Array<SourceCode>;
};

export const useSourceCodes = <TData = GetSourceCodesQuery, TError = unknown>(
  variables?: GetSourceCodesQueryVariables,
  options?: UseQueryOptions<GetSourceCodesQuery, TError, TData>
) => {
  const query = GET_SOURCE_CODES_NODE;

  const key = [query, variables] as QueryKey;
  const { data, isLoading } = useQuery(
    key,
    () => queryResolver(query, variables),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      ...options,
    }
  );

  return {
    sourceCodes: data?.[SOURCE_CODES_ACCESSOR],
    loading: isLoading,
  };
};
