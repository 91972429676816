import { Card, CardContent, Typography } from "@material-ui/core";
import { AnteTable } from "components/AnteTable";
import useColumns, {
  ProtocolTestRowProps,
} from "pages/ProtocolProfilePage/useColumns";
import { EmptyTable } from "../EmptyTable";
import { TestRow } from "./TestRow";
import cn from "classnames";
import { cardStyles as useCardStyles } from "style/styles";
import { useStyles } from "./SecurityCard.styles";
import { Protocol } from "types";
import { SecuritySkeleton } from "./SecurityCard.skeleton";
import KPI, { KPIProps } from "components/KPI/KPI";
import colors from "style/colors";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import { useTypography } from "style/typography";
import { Audits } from "./Audits";
import { ProtocolVersion } from "types";
import { BugBounty } from "./BugBounty";
import { TestHeaders } from "./TestHeaders";
import { useGlobalStyles } from "utils/styles";
import { LATEST_APP_BASE_URL } from "utils/constants";

type SecurityCardProps = {
  isLoading: boolean;
  protocol: Protocol;
  versions: Array<ProtocolVersion>;
  kpis: Array<KPIProps>;
  tests: Array<ProtocolTestRowProps>;
};
export const SecurityCard = ({
  isLoading,
  protocol,
  versions,
  tests,
  kpis,
}: SecurityCardProps) => {
  const cardStyles = useCardStyles();
  const styles = useStyles();
  const typography = useTypography();
  const globalStyles = useGlobalStyles();
  const { columns } = useColumns(protocol?.name);
  const { loading: isLoadingEthPrice, failureCount } = useFetchTokenPrice();
  const latestVersion = versions?.[0];

  const renderContent = () => {
    if (isLoading) {
      return <SecuritySkeleton />;
    }
    return (
      <div>
        <Typography
          variant={"h6"}
          className={styles.securityTitle}
          data-testid="security-title"
        >
          Security Scorecard
        </Typography>
        <br />
        <div className={styles.statsRow}>
          {latestVersion?.audits && <Audits audits={latestVersion.audits} />}
          {latestVersion?.bugBounty && (
            <BugBounty bugBounty={latestVersion.bugBounty} />
          )}
        </div>
        <Typography variant={"h6"} className={typography.labelLarge}>
          Ante
        </Typography>
        <div className={globalStyles.warningBox}>
          <Typography className={typography.bodySmall}>
            This only includes tests with Ante v0.5 pools. To see all tests, go
            to the&nbsp;
            <a href={LATEST_APP_BASE_URL} rel="noopener noreferrer">
              latest version of the app
            </a>
            .
          </Typography>
        </div>
        <div className={styles.kpisContainer}>
          {kpis.map((kpi, idx) => (
            <div key={idx} className={styles.kpi}>
              <KPI
                label={kpi.label}
                labelColor={colors.highEmphasis}
                value={kpi.value}
                footnote={kpi.footnote}
                tooltip={kpi.tooltip}
                size="medium"
                isLoaded={
                  !isLoading && (!isLoadingEthPrice || failureCount > 0)
                }
              />
            </div>
          ))}
        </div>
        <AnteTable
          data={tests}
          columns={columns}
          isLoading={isLoading}
          RowComponent={TestRow}
          HeadersComponent={TestHeaders}
          emptyText={
            <EmptyTable
              protocolName={protocol?.name}
              protocolWebsite={protocol?.websiteUrl}
            />
          }
          initialState={{
            sortBy: [
              {
                id: "ante-value-locked",
                desc: true,
              },
            ],
          }}
        />
      </div>
    );
  };
  return (
    <Card className={cn(cardStyles.root, styles.root)}>
      <CardContent>{renderContent()}</CardContent>
    </Card>
  );
};
