import { Card, Container, Typography } from "@material-ui/core";
import classNames from "classnames";
import { cardStyles } from "style/styles";
import { useStyles } from "./DisclaimerPage.styles";

export const DisclaimerPage = () => {
  const cardClasses = cardStyles();
  const styles = useStyles();
  return (
    <Container>
      <Card className={classNames(cardClasses.root, styles.container)}>
        <Typography variant="h4">Disclaimer</Typography>
        <br />
        <Typography variant="body1">
          This website-hosted user interface (this “Interface”) is made
          available by the Ante Protocol Foundation.
        </Typography>
        <br />
        <Typography variant="body1">
          This Interface is an open source software portal to Ante, a protocol
          which is a community-driven collection of blockchain-enabled smart
          contracts and tools maintained by the Ante Protocol Foundation.
        </Typography>
        <br />
        <Typography variant="body1">
          <b>
            THIS INTERFACE AND THE ANTE PROTOCOL ARE PROVIDED “AS IS”, AT YOUR
            OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND
          </b>
          . The Ante Protocol Foundation does not provide, own, or control Ante.
          By using or accessing this Interface or Ante, you agree that no
          developer or entity involved in creating, deploying or maintaining
          this Interface or Ante will be liable for any claims or damages
          whatsoever associated with your use, inability to use, or your
          interaction with other users of, this Interface or Ante, including any
          direct, indirect, incidental, special, exemplary, punitive or
          consequential damages, or loss of profits, cryptocurrencies, tokens,
          or anything else of value.
        </Typography>
        <br />
        <Typography variant="body1">
          By using or accessing this Interface, you represent that you are not
          subject to sanctions or otherwise designated on any list of prohibited
          or restricted parties or excluded or denied persons, including but not
          limited to the lists maintained by the United States' Department of
          Treasury's Office of Foreign Assets Control, the United Nations
          Security Council, the European Union or its Member States, or any
          other government authority.
        </Typography>
      </Card>
    </Container>
  );
};
