import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { LeaderboardPage } from "pages/Leaderboard";
import { AnteTestDetail } from "pages/AnteTestDetail";
import { CommunityLeaderboard } from "pages/CommunityLeaderboard";
import { ProtocolProfilePage } from "pages/ProtocolProfilePage";
import { NotFoundPage } from "pages/NotFoundPage";
import { AppRoute } from "components/AppRoute";
import { CreatePool } from "pages/CreatePool";
import { DisclaimerPage } from "pages/DisclaimerPage";
import { useFirewall } from "hooks/useFirewall";
import { useEffect } from "react";
import { AnalyticsEvents, sendEvent } from "analytics/analytics";
import { initOnboard } from "utils/onboard/onboardUtils";

initOnboard();

const Routes = () => {
  const history = useHistory();

  const trackPageView = () => {
    sendEvent(AnalyticsEvents.pageView, {
      path: window.location.pathname + window.location.hash,
    });
  };

  useEffect(() => {
    // Track the first pageview upon load
    trackPageView();
    // Track the subsequent pageviews
    history.listen(trackPageView);
  }, [history]);

  const { NoticeElement, isRestricted, loading } = useFirewall();
  if (!loading && isRestricted) {
    return NoticeElement;
  }

  return (
    <Switch>
      <AppRoute path="/" exact component={LeaderboardPage} />
      <AppRoute path="/contract/:chainId/:address" component={AnteTestDetail} />
      <AppRoute path="/contract/:address" component={AnteTestDetail} />
      <AppRoute path="/protocol/:name" component={ProtocolProfilePage} />
      <AppRoute path="/create-pool" component={CreatePool} />
      <AppRoute path="/disclaimer" component={DisclaimerPage} />
      <AppRoute
        path="/community-leaderboard"
        component={CommunityLeaderboard}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
