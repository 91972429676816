import { makeStyles } from "@material-ui/core";

export const useLeaderboardStyles = makeStyles((theme) => ({
  grid: {
    width: "100%",
    margin: "0",
    display: "flex",
    justifyContent: "center",
  },
  tableContainer: {
    padding: "32px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 32px 0",
    },
  },
  stats: {
    display: "block",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    padding: "32px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-24px",
      padding: "0 0 32px 0",
    },
  },
  title: {
    marginBottom: "12px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  controls: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    marginTop: "12px",
  },
}));
