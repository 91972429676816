import { Ante, ChainId, Network } from "@antefinance/ante-sdk";
import { Pool } from "types";
import { WalletData } from "utils/wallet";
import { Web3Utils } from "utils/web3-utils";

const fetchAntePoolBalancesPublicData = async (
  walletData: Partial<WalletData>,
  pool: Pool,
  stakers: string[],
  isChallenger: boolean
) => {
  const { provider, networkId } = walletData;
  const { antePoolAddress } = pool;

  const ante = new Ante(Network[ChainId[parseInt(networkId, 16)]], provider);
  const poolInstance = ante.getPool(antePoolAddress, pool.version);
  const methods = stakers.map((staker) => {
    return {
      method: () => poolInstance.getUserStoredBalance(staker, isChallenger),
      valueName: staker,
    };
  });
  const data = await Web3Utils.batch(methods);
  return data;
};

export default fetchAntePoolBalancesPublicData;
