import gql from "graphql-tag";
import { TEST_WRITER_FIELDS } from "../fragments/testWriter";

export const TEST_WRITERS_ACCESSOR = "testWriterLeaderboardCollections";

export const GET_TEST_WRITERS_NODE = gql`
${TEST_WRITER_FIELDS}
query GetTestWriters($address: String) {
  ${TEST_WRITERS_ACCESSOR}(
    query: { address: $address }
    sortBy: ANTETESTSSUBMITTED_DESC
  ) {
    ...TestWriterFields
  }
}
`;
