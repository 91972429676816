import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { useWallet } from "utils/wallet";
import colors from "style/colors";
import { Chain } from "utils/constants";
import { SelectNetworkModal } from "components/SelectNetworkModal";
import { useState } from "react";

const useStyles = makeStyles({
  root: {
    color: colors.primaryBlue,
    textTransform: "none",
    fontSize: "16px",
    backgroundColor: "transparent",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },
});

type ConnectWalletButtonProps = {
  chain?: Chain;
};

const ConnectWalletButton = ({ chain }: ConnectWalletButtonProps) => {
  const { account, connect, isNetworkSupported, networkId, setChain } =
    useWallet();
  const styles = useStyles();
  const [isShown, setIsShown] = useState<boolean>(false);

  const hideNetworkSelector = () => {
    setIsShown(false);
  };

  if (account && !isNetworkSupported) {
    return (
      <>
        <Button
          onClick={() => setIsShown(true)}
          className={styles.root}
          data-testid="connect-wallet-button"
        >
          Unsupported network
        </Button>
        <SelectNetworkModal isShown={isShown} hide={hideNetworkSelector} />
      </>
    );
  }

  if (account && chain && networkId !== chain?.id) {
    return (
      <Button
        onClick={() => setChain(chain.id)}
        className={styles.root}
        data-testid="connect-wallet-button"
      >
        Switch to {chain.label}
      </Button>
    );
  }

  return (
    <Button
      onClick={() => connect()}
      className={styles.root}
      data-testid="connect-wallet-button"
    >
      Connect to wallet
    </Button>
  );
};

export default ConnectWalletButton;
