import React from "react";

import { sendEvent, AnalyticsEvents } from "analytics/analytics";

export type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const ExternalLink: React.FunctionComponent<ExternalLinkProps> = (props) => {
  const { children, ...rest } = props;
  const handleClick = () => {
    sendEvent(AnalyticsEvents.footerLinkClicked, {
      href: props.href,
    });
  };

  return (
    <a
      onClick={handleClick}
      rel="noopener noreferrer"
      target="_blank"
      {...rest}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
