import React from "react";
import useClipboard from "react-use-clipboard";

import { useWallet } from "utils/wallet";
import { formatTokenValue, shortenString } from "utils/utils";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import OpenInNew from "@material-ui/icons/OpenInNew";
import SvgIcon from "@material-ui/core/SvgIcon";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

import { useSnackbar } from "notistack";
import { useButtonStyles, usePopoverStyles } from "./WalletPopover.styles";
import { CHAIN_EXPLORERS } from "utils/constants";
import { useMediaQuery, useTheme } from "@material-ui/core";

const WalletPopover = () => {
  const {
    account,
    networkId,
    networkName,
    disconnect,
    connectedWallet,
    balance,
    token,
  } = useWallet();
  const buttonStyles = useButtonStyles();
  const popoverStyles = usePopoverStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDisconnect = () => {
    disconnect();
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [, setCopied] = useClipboard(account ?? "", {
    successDuration: 5000,
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    setCopied();
    enqueueSnackbar("Address copied to clipboard", {
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        className={buttonStyles.root}
        onClick={handleClick}
      >
        {!isMobile && balance && (
          <Typography className={buttonStyles.balance}>
            {formatTokenValue(balance.formattedString, token)}
          </Typography>
        )}
        <Typography variant="body1" className={buttonStyles.account}>
          {shortenString(account)}
        </Typography>
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Container className={popoverStyles.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                {connectedWallet && `Connected with ${connectedWallet.label}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={popoverStyles.account}>
                {shortenString(account)}
              </Typography>
              <Typography variant="body2">{networkName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={popoverStyles.linkContainer}
              >
                <Link
                  onClick={handleCopy}
                  color="textSecondary"
                  className={popoverStyles.iconLink}
                >
                  <SvgIcon fontSize="small" style={{ marginRight: "6px" }}>
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                  </SvgIcon>
                  <span className={popoverStyles.linkText}>Copy Address</span>
                </Link>
                {CHAIN_EXPLORERS[networkId] && (
                  <a
                    href={`${CHAIN_EXPLORERS[networkId].address}${account}`}
                    color="textSecondary"
                    target="_blank"
                    rel="noreferrer"
                    className={popoverStyles.iconLink}
                  >
                    <OpenInNew
                      fontSize="small"
                      style={{ marginRight: "6px" }}
                    />
                    <span className={popoverStyles.linkText}>
                      View in Explorer
                    </span>
                  </a>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={popoverStyles.balanceTitle}
              >
                Balance
              </Typography>
              <Typography variant="h6">
                {balance &&
                  `${formatTokenValue(balance.formattedString, token)}`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Divider />

        <List>
          {/* For later: Transaction History */}
          <MenuItem onClick={onDisconnect}>Disconnect</MenuItem>
        </List>
      </Popover>
    </div>
  );
};

export default WalletPopover;
