import { Skeleton } from "@material-ui/lab";

export const SecuritySkeleton = () => {
  return (
    <>
      <Skeleton
        animation="wave"
        variant="text"
        data-testid="security-skeleton"
      />
    </>
  );
};
