import gql from "graphql-tag";
import { SOURCE_CODE_FIELDS } from "../fragments/sourceCode";

export const SOURCE_CODES_ACCESSOR = "sourceCodeCollections";

export const GET_SOURCE_CODES_NODE = gql`
${SOURCE_CODE_FIELDS}
query GetSourceCodes($addresses: [String], $chainId: String) {
  ${SOURCE_CODES_ACCESSOR}(query: { address_in: $addresses, chainId: $chainId }) {
    ...SourceCodeFields
  }
}
`;
