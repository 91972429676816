import { useCallback, useEffect, useState } from "react";
import Fuse from "fuse.js";
import { Rating, ratings } from "types";
import { AnteTable } from "components/AnteTable";
import useColumns, { ProtocolRowProps } from "./useColumns";
import { ProtocolsFilters } from "./ProtocolsFilters";
import { getFormattedProtocols } from "./utils";
import { ProtocolRow } from "./ProtocolRow";
import { useAntePools } from "hooks/antepools/useAntePools";
import { useLeaderboardStyles } from "pages/Leaderboard/Leaderboard.styles";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import { PoolChainTotals } from "pages/Leaderboard";
import { computePoolsTotals } from "pages/Leaderboard/utils";
import { useProtocols } from "hooks/protocols/useProtocols";

export type Filters = {
  name: string;
  rating: "all" | Rating;
};

export type ProtocolsViewProps = {
  onKPIsChange: (totals: PoolChainTotals) => void;
};

const ProtocolsView = ({ onKPIsChange }: ProtocolsViewProps) => {
  const { pools, loadingOffChain } = useAntePools();
  const [filters, setFilters] = useState<Filters>({
    name: "",
    rating: "all",
  });
  const { prices } = useFetchTokenPrice();
  const { protocols, loading: loadingProtocols } = useProtocols();
  const leaderboardStyles = useLeaderboardStyles();
  const { columns } = useColumns();
  const [formattedProtocols, setFormattedProtocols] = useState({
    data: [],
    formatting: true,
  });

  useEffect(() => {
    if (pools === undefined) return;
    onKPIsChange(computePoolsTotals(pools));
  }, [pools, onKPIsChange]);

  const removeUnnamedProtocols = (
    displayedProtocols: Array<ProtocolRowProps>
  ): Array<ProtocolRowProps> => {
    return displayedProtocols.filter((protocol) => protocol.headingProps.name);
  };
  const updateFormattedProtocols = useCallback(async () => {
    if (!pools || !protocols) return [];
    setFormattedProtocols({
      data: await getFormattedProtocols(pools, protocols, prices),
      formatting: false,
    });
  }, [pools, protocols, prices]);

  useEffect(() => {
    updateFormattedProtocols();
  }, [updateFormattedProtocols]);

  let displayedProtocols = removeUnnamedProtocols(formattedProtocols.data);
  for (const filter of Object.keys(filters)) {
    if (filter === "rating" && filters[filter] !== "all") {
      displayedProtocols = displayedProtocols.filter(
        (protocol) =>
          ratings.indexOf(protocol[filter]) <=
          ratings.indexOf(filters[filter] as Rating)
      );
    } else if (filter === "name" && filters[filter]) {
      const fuse = new Fuse(displayedProtocols, {
        keys: ["headingProps.name"],
        threshold: 0.1,
      });
      displayedProtocols = fuse
        .search(filters[filter])
        .map((result) => result.item);
    }
  }

  return (
    <>
      <div className={leaderboardStyles.header}>
        <ProtocolsFilters
          filters={filters}
          onChange={(newFilters) => setFilters(newFilters)}
        />
      </div>
      <AnteTable
        data={displayedProtocols}
        columns={columns}
        isLoading={
          loadingOffChain || loadingProtocols || formattedProtocols.formatting
        }
        RowComponent={ProtocolRow}
        initialState={{
          sortBy: [
            {
              id: "ante-value-locked",
              desc: true,
            },
          ],
        }}
      />
    </>
  );
};

export { ProtocolsView };
