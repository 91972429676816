import { makeStyles } from "@material-ui/core";

import colors from "style/colors";

const createPoolStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "688px",
    margin: "auto",
    marginBottom: "16px",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "32px",
    },
  },
  heading: {
    marginBottom: "0px",
  },
  image: {
    margin: "0px 40px 24px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 24px",
    },
  },
  info: {
    color: colors.black77,
  },
  infoCard: {
    color: colors.unverifiedOrange,
    marginTop: "16px",
    borderColor: colors.unverifiedOrange,
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    marginBottom: "16px",
  },
  infoLabel: {
    fontWeight: "bold",
    color: colors.unverifiedOrange,
  },
  leftSpacing: {
    marginLeft: "8px",
  },
  link: {
    color: colors.primaryBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  inputContainer: {
    position: "relative",
    marginTop: "8px",
  },
  inputWarning: {
    width: "100%",
    borderColor: "#DA160C",
    backgroundColor: "rgba(218,22,12,0.10)",
    color: "#B00020",
  },
  addressInput: {
    padding: "8px",
  },
  warning: {
    color: "#DA160C",
    position: "absolute",
    bottom: "-8px",
    left: "0",
    transform: "translateY(100%)",
  },
  button: {
    marginTop: "40px",
    alignSelf: "flex-end",
  },
  videoPlayer: {
    height: "0",
    width: "100%",
    paddingBottom: "33.34%",
  },
  video: {
    width: "100%",
  },
}));

export default createPoolStyles;
