import { makeStyles } from "@material-ui/core";
import { AnteHeaders, AnteHeadersType } from "components/AnteTable/AnteHeaders";
import { PoolRowProps } from "./useColumns";

type StyleProps = {
  numColumns: number;
};

export const useStyles = makeStyles((theme) => ({
  poolHeaders: {
    gridTemplateColumns: (props: StyleProps) =>
      `minmax(100px,3fr) minmax(min-content, 1fr) 32px repeat(${
        props.numColumns - 3
      }, minmax(min-content, 1fr))`,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: (props: StyleProps) =>
        `minmax(min-content, 1fr) 32px repeat(${
          props.numColumns - 3
        }, minmax(min-content, 1fr))`,
    },
  },
}));

export const PoolHeaders = (props: AnteHeadersType<PoolRowProps>) => {
  const styles = useStyles({ numColumns: props.headers.length });
  return <AnteHeaders {...props} classNames={styles.poolHeaders} />;
};
