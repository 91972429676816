import { Grid, Typography } from "@material-ui/core";
import WalletImage from "assets/img/wallet-not-connected.svg";
import { useWallet } from "utils/wallet";

type ConnectWalletContainerProps = {
  title?: string;
  message?: string;
};

export const ConnectWalletContainer = ({
  title,
  message,
}: ConnectWalletContainerProps) => {
  const { account, isNetworkSupported } = useWallet();
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {title
            ? title
            : account && !isNetworkSupported
            ? "This network is unsupported"
            : "No wallet detected"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center">
          <img src={WalletImage} alt="wallet" height="128" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          {message ? message : "Connect your wallet to interact with ANTE"}
        </Typography>
      </Grid>
    </Grid>
  );
};
