import { makeStyles } from "@material-ui/core";
import colors from "style/colors";
import fontType from "style/typography";

export const useStyles = makeStyles({
  root: { minHeight: "calc(100vh - 300px)" },
  nameContainer: {
    ...fontType.eyebrow,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addressContainer: {
    ...fontType.subtextMedium,
    marginBottom: "8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  protocolTitle: { ...fontType.titleMedium, marginBottom: "8px" },
  tabs: {
    marginTop: "1rem",
  },
  tabHeading: {
    ...fontType.tabHeading,
    "&:hover": {
      background: colors.linkBlue10,
    },
  },
  communityLabel: {
    backgroundColor: colors.statPurple,
    color: "white",
    padding: "4px 8px",
    borderRadius: "16px",
  },
  newTest: {
    marginLeft: "8px",
    color: colors.black69,
    backgroundColor: colors.newYellow,
    fontSize: ".5625rem",
    height: "18px",
    fontWeight: 600,
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  unverifiedLabel: {
    backgroundColor: colors.unverifiedOrange,
    color: "white",
    padding: "4px 8px",
    borderRadius: "16px",
  },
  author: {
    marginBottom: "24px",
  },
  protocolProfileLink: {
    color: colors.linkBlue,
  },
  noContentContainer: {
    marginTop: "8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
