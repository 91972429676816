import { gql } from "graphql-tag";

export const PROTOCOL_VERSION_FIELDS = gql`
  fragment ProtocolVersionFields on ProtocolVersionCollection {
    _id
    protocol
    version
    bugBounty {
      value
      name
      url
    }
    audits {
      name
      date
      url
    }
    resources {
      url
      linkText
    }
  }
`;
