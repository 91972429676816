import { ChainId, Network } from "@antefinance/ante-sdk";
import { BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import { TokenPrice } from "hooks/useFetchTokenPrice";
import {
  ProtocolChainStats,
  ProtocolChainStatsMap,
} from "pages/ProtocolProfilePage/utils";
import { PoolVersionType, Rating } from "types";
import { Currency } from "types/Currency";

import {
  BASE_CHAIN,
  Chain,
  NEW_THRESHOLD,
  SupportedChainId,
  SUPPORTED_CHAINS,
  SUPPORTED_CURRENCIES,
} from "./constants";

export function shortenString(str: string, chars: number = 4): string {
  return `${str.slice(0, chars + 2)}...${str.slice(-chars)}`;
}

export function getNetworkById(chainId: string): Chain | undefined {
  return Object.values(SUPPORTED_CHAINS).find(
    (chain) => chain.id.toLowerCase() === chainId?.toLowerCase()
  );
}

export function getTokenByChain(chainId: string): string | undefined {
  return getNetworkById(chainId)?.token;
}

export const newPoolDateThreshold = (): number => {
  return new Date().getTime() - NEW_THRESHOLD;
};

export function bnToStringPrecision(
  value: BigNumber,
  precision: number = 3
): string | undefined {
  try {
    return toLocaleNumber(Number(formatEther(value)), precision);
  } catch (e) {
    return "N/A";
  }
}

export const capitalize = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getRating = (tvl: number, trustScore: number): Rating => {
  let rating: Rating = "NR";
  if (tvl >= 100 && trustScore >= 90) {
    rating = "S";
  } else if (tvl >= 30 && trustScore >= 85) {
    rating = "AA";
  } else if (tvl >= 10 && trustScore >= 80) {
    rating = "A";
  }
  return rating;
};

export const toLocaleNumber = (
  value?: number,
  precision: number = 2
): string => {
  if (value === undefined || value == null || isNaN(value)) return "-";
  return value.toLocaleString("en-US", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};

export const toHex = (number?: number): string | undefined => {
  return number === undefined ? undefined : `0x${number.toString(16)}`;
};

export const formatTokenValue = (value?: string, token?: string): string => {
  if (token === undefined) {
    return "-";
  }

  return `${value} ${token}`;
};

export const formatBNFiatValue = (
  value: BigNumber,
  isSuffix: boolean = false,
  useSymbol: boolean = true
): string => {
  return formatFiatValue(bnToStringPrecision(value, 2), isSuffix, useSymbol);
};

export const formatFiatValue = (
  value: string,
  isSuffix: boolean = true,
  useSymbol: boolean = true
): string => {
  const currency = SUPPORTED_CURRENCIES.usd;
  const displayedCurrency = useSymbol ? currency.symbol : currency.code;

  return isSuffix
    ? `${displayedCurrency}${value}`
    : `${value} ${displayedCurrency}`;
};

export const convertTokenToFiat = (
  chainId: SupportedChainId,
  tokenValue: number,
  prices?: TokenPrice
): number => {
  if (!prices) {
    return NaN;
  }

  return tokenValue * getTokenPrice(chainId, prices, SUPPORTED_CURRENCIES.usd);
};

export const convertBNTokenToBNFiat = (
  chainId: SupportedChainId,
  tokenValue: BigNumber,
  prices?: TokenPrice
): BigNumber | undefined => {
  if (!prices) {
    return undefined;
  }

  return tokenValue.mul(
    Math.round(getTokenPrice(chainId, prices, SUPPORTED_CURRENCIES.usd))
  );
};

export const convertTokenToCurrency = (
  chainId: SupportedChainId,
  fromTokenValue: number,
  toCurrency: Currency,
  prices?: TokenPrice
): number => {
  if (!prices) {
    return NaN;
  }

  return fromTokenValue * getTokenPrice(chainId, prices, toCurrency);
};

const getTokenPrice = (
  chainId: SupportedChainId,
  prices?: TokenPrice,
  currency: Currency = SUPPORTED_CURRENCIES.usd
): number => {
  const chain = getNetworkById(chainId);

  if (
    !chain ||
    prices === undefined ||
    !prices.hasOwnProperty(chain?.priceProviderId) ||
    !prices[chain?.priceProviderId].hasOwnProperty(currency.code.toLowerCase())
  ) {
    return NaN;
  }

  return prices[chain?.priceProviderId][currency.code.toLowerCase()];
};

export const getUnderlyingAssetsTooltipFromChainStats = (
  statsByChain: ProtocolChainStatsMap,
  accessor: keyof ProtocolChainStats,
  prices?: TokenPrice
): React.ReactNode[] => {
  if (Object.keys(statsByChain).length === 0 || !prices) return undefined;

  return Object.keys(statsByChain).map((chainId) => {
    const chain = getNetworkById(chainId);
    if (!chain) return null;

    let tooltip = [
      <b key={`stats-${accessor}-${chainId}`}>
        <span>
          {statsByChain[chainId][accessor]} {chain?.token}
        </span>
      </b>,
    ];

    const tokenToCurrency = convertTokenToCurrency(
      chainId,
      Number(statsByChain[chainId][accessor]),
      SUPPORTED_CURRENCIES.eth,
      prices
    );
    if (chainId !== BASE_CHAIN.id) {
      tooltip.push(
        <span key={`stats-value-${accessor}-${chainId}`}>
          &nbsp; ({isNaN(tokenToCurrency) ? "-" : tokenToCurrency}{" "}
          {SUPPORTED_CURRENCIES.eth.symbol})
        </span>
      );
    }

    return <div>{tooltip}</div>;
  });
};

export const ALTERNATE_PROTOCOL_NAMES = {
  Aave: "AAVE",
  arbitrum: "Arbitrum",
  ETH: "Ethereum",
  ETH2: "Ethereum",
  dai: "DAI",
  USDC: "USD Coin",
  USDT: "Tether",
};

const FACTORY_VERSION_NETWORK = {
  "v0.5": [
    Network.mainnet,
    Network.rinkeby,
    Network.goerli,
    Network.polygon,
    Network.mumbai,
    Network.binance,
    Network.binanceTestnet,
    Network.fantom,
    Network.fantomTestnet,
  ],
  "v0.5.1": [Network.avalanche, Network.avalancheFuji],
  "v0.5.2": [
    Network.arbitrumOne,
    Network.arbitrumGoerli,
    Network.scrollL1Testnet,
    Network.scrollL2Testnet,
    Network.optimism,
    Network.optimismGoerli,
    Network.aurora,
    Network.auroraTestnet,
  ],
};

export const getV05VersionByChain = (chainId: string): PoolVersionType => {
  const connectedNetwork = Network[ChainId[parseInt(chainId, 16)]];
  for (const version in FACTORY_VERSION_NETWORK) {
    if (FACTORY_VERSION_NETWORK[version].includes(connectedNetwork)) {
      return version as PoolVersionType;
    }
  }

  return null;
};
