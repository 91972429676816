import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import WithdrawImage from "assets/img/withdraw.svg";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";

interface CancelWithdrawalModalProps {
  isShown: boolean;
  hide: () => void;
}

const CancelWithdrawalModal = ({
  isShown,
  hide,
}: CancelWithdrawalModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await pool.cancelPendingWithdraw();
      hide();
      // trigger success popup
    } catch (error: any) {
      console.error(error);
      window.rollbar.warn("CancelWithdrawalModal:handleSubmit", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
        <DialogTitle>Cancel Withdrawal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "16px",
              }}
            >
              <img src={WithdrawImage} alt="withdraw wallet" height="128" />
            </div>
            <Typography
              variant="body1"
              display="block"
              color="textPrimary"
              gutterBottom
            >
              Canceling your withdrawal will reinstate your stake and allow you
              to resume accumulating decay rewards from Challengers. However, if
              you want to withdraw your stake, you will need to restart the 1
              day unlocking period.
            </Typography>
            <Typography variant="body2" display="block" gutterBottom>
              Note: If the test fails before you withdraw your stake, you will
              still forfeit your stake.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button
            style={{ width: "85px" }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {submitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelWithdrawalModal;
