import { makeStyles } from "@material-ui/core";
import fontType from "style/typography";

export const useStyles = makeStyles((theme) => ({
  root: {},
  tableHeaders: {
    display: "flex",
    gap: "16px",
    margin: "16px 24px",
  },
  tableHeader: {
    ...fontType.labelMedium,
  },
  rank: {
    width: "10%",
    [theme.breakpoints.down("md")]: {
      width: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "20%",
    },
  },
  contributor: {
    width: "35%",
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },
  testCount: {
    width: "20%",
    textAlign: "end",
    [theme.breakpoints.down("md")]: {
      width: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "40%",
      textAlign: "center",
    },
  },
  date: {
    width: "35%",
    marginLeft: "24px",
    [theme.breakpoints.down("md")]: {
      width: "35%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  rankLabel: {
    width: "28px",
    display: "flex",
    justifyContent: "center",
  },
}));
