import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";

import colors from "style/colors";
import { cardStyles } from "style/styles";

import Hidden from "@material-ui/core/Hidden";

import DropdownIcon from "assets/img/chevron-down.svg";
import DocsIcon from "assets/img/docs.svg";
import CommunityGitHubIcon from "assets/img/code-community.svg";
import CoreGitHubIcon from "assets/img/code-core.svg";

const linkDropdownStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    color: colors.linkBlue,
    fontSize: "18px",
    textTransform: "none",
    lineHeight: "28px",
    minHeight: "48px",
    margin: "0px 12px",
    justifyContent: "left",
    alignItems: "center",
    fontWeight: 400,
    opacity: 1,
    cursor: "pointer",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
      margin: "0px 8px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 16px",
    },
    "&:hover": {
      "& $links": {
        display: "flex",
      },
    },
  },
  dropdown: {
    position: "relative",
    display: "inline-flex",
    transition: ".3s",
    color: colors.linkBlue,
    "&:hover": {
      color: colors.linkBlue60,
    },
  },
  dropdownMobile: {
    color: colors.black69,
    marginBottom: "8px",
  },
  leftSpacing: {
    marginLeft: "4px",
  },
  links: {
    color: colors.black69,
    fontSize: "14px",
    display: "none",
    position: "absolute",
    bottom: "-6px",
    left: "-12px",
    transform: "translateY(100%)",
    flexDirection: "column",
    background: "white",
    padding: "12px",
    whiteSpace: "nowrap",
    "& a": {
      display: "inline-flex",
    },
    "& a:hover": {
      color: colors.linkBlue,
      textDecoration: "underline",
    },
    "& a img": {
      marginRight: "8px",
      width: "16px",
    },
  },
  linksMobile: {
    color: colors.black69,
    fontSize: "14px",
    display: "block",
    listStyleType: "none",
    minHeight: "108px",
    "& li": {
      height: "36px",
    },
    "& li:hover": {
      color: colors.linkBlue,
      textDecoration: "underline",
    },
    "& li a": {
      display: "inline-flex",
    },
    "& li a img": {
      marginRight: "8px",
      width: "16px",
    },
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const links = [
  {
    imgSrc: DocsIcon,
    label: "Documentation",
    url: "https://docs.ante.finance",
    target: "_blank",
  },
  {
    imgSrc: CommunityGitHubIcon,
    label: "Community Ante Tests",
    url: "https://github.com/antefinance/ante-community-tests",
    target: "_blank",
  },
  {
    imgSrc: CoreGitHubIcon,
    label: "GitHub (core contracts)",
    url: "https://github.com/antefinance/ante-v0-core",
    target: "_blank",
  },
];

const LinkDropdown = () => {
  const styles = linkDropdownStyles();
  return (
    <div>
      <div className={styles.root}>
        <Hidden smDown implementation="css">
          <div className={styles.dropdown}>
            Developers{" "}
            <img src={DropdownIcon} alt="" className={styles.leftSpacing} />
            <div className={cn(styles.links, cardStyles().root)}>
              {links.map((link) => (
                <a
                  href={link.url}
                  className={styles.link}
                  key={link.url}
                  target={link.target}
                >
                  <img src={link.imgSrc} alt={link.label} /> {link.label}
                </a>
              ))}
            </div>
          </div>
        </Hidden>
        <Hidden mdUp implementation="css">
          <div className={styles.dropdownMobile}>Developers</div>
          <div className={styles.linksMobile}>
            {links.map((link) => (
              <li key={link.url}>
                <a href={link.url} key={link.url} target={link.target}>
                  <img src={link.imgSrc} alt={link.label} /> {link.label}
                </a>
              </li>
            ))}
          </div>
        </Hidden>
      </div>
    </div>
  );
};

export default LinkDropdown;
