import { gql } from "graphql-tag";

export const ANTEPOOL_CORE_FIELDS = gql`
  fragment AntePoolCoreFields on AntepoolCollection {
    _id
    antePoolAddress
    anteTestAddress
    anteTestName
    testDescription
    chainId
    numTimesVerified
    pendingFailure
    pendingWithdrawAmount
    protocolName
    version
    testAuthor {
      address
      ensName
    }
    testedContracts
    lastVerifiedBlock
    verifier
    teamVerified
    writtenByTeam
    isPartner
    protocolStaked
    isCommunityPool
    challengerInfo {
      decayMultiplier
      numUsers
      totalAmount
    }
    stakingInfo {
      decayMultiplier
      numUsers
      totalAmount
    }
    verifiedStakers {
      displayName
      ensName
      amount
      address
    }
    verifiedChallengers {
      displayName
      ensName
      amount
      address
    }
  }
`;

export const ANTEPOOL_DETAILS_FIELDS = gql`
  fragment AntePoolDetailsFields on AntepoolCollection {
    totalStaked {
      timestamp
      value
    }
    totalChallengerStaked {
      timestamp
      value
    }
  }
`;
