import { Typography } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { Audit as AuditType } from "types";
import { useStyles } from "./SecurityCard.styles";

type AuditProps = {
  audit: AuditType;
};

export const Audit = ({ audit }: AuditProps) => {
  const styles = useStyles();

  return (
    <div className={styles.audit}>
      <Typography variant="h5" className={styles.auditName}>
        {audit?.name}
      </Typography>
      <Typography variant="caption">
        {format(parseISO(audit?.date), "MMM y")}
      </Typography>
    </div>
  );
};
