import { queryResolver } from "db/graphql-query-resolver";
import {
  ANTEPOOL_ACCESSOR,
  GET_ANTEPOOLS_DETAILS_NODE,
  GET_ANTEPOOLS_NODE,
  GET_ANTEPOOL_DETAILS_NODE,
  GET_ANTEPOOL_NODE,
} from "db/queries/pool";
import { Exact, Scalars } from "db/queries/types";
import { useRef } from "react";
import { useQuery, QueryKey } from "react-query";
import { Pool } from "types";
import { PoolsCache } from "utils/antepools/cache";
import { fetchAntePoolDataDB } from "utils/antepools/fetchAntePoolsDB";
import { fetchAntePoolPublicData } from "utils/antepools/fetchAntePoolsPublic";
import queryClient from "utils/queryClient";
import { useWallet, Wallet } from "utils/wallet";
import {
  DEFAULT_VARIABLES,
  getCachedPools,
  handlePoolFilters,
} from "./useAntePools";

type FetchPoolResult = {
  pool?: Pool;
  loading?: boolean;
  loadingOffChain?: boolean;
  loadingOnChain?: boolean;
};

export type GetAntePoolQueryVariables = Exact<{
  address: Scalars["String"];
  chainId: Scalars["String"];
  isVisible: Scalars["Boolean"];
}>;

export type GetAntePoolQuery = {
  [key in typeof ANTEPOOL_ACCESSOR]?: Pool;
};

export const onChainFetcher = async (
  walletData: Partial<Wallet>,
  pool: Pool
): Promise<Pool> => {
  return fetchAntePoolPublicData(walletData, pool);
};

export const useAntePool = (
  address: string,
  withDetails: boolean = false,
  chainId?: string,
  walletAddress?: string
): FetchPoolResult => {
  const variables: GetAntePoolQueryVariables = {
    address,
    chainId,
    ...DEFAULT_VARIABLES,
  };
  const result = useRef<FetchPoolResult>({
    pool: undefined,
    loading: undefined,
    loadingOffChain: undefined,
    loadingOnChain: undefined,
  });
  const prevOffChainPool = useRef<Pool>();

  const { provider, networkId, account, refreshBalance, isNetworkSupported } =
    useWallet();
  if (typeof walletAddress === "undefined") {
    walletAddress = account;
  }

  const query = withDetails ? GET_ANTEPOOL_DETAILS_NODE : GET_ANTEPOOL_NODE;

  const offChainKey = PoolsCache.offChainKeys(query, variables) as QueryKey;

  const {
    data: offChainData,
    isLoading: isLoadingOffChain,
    isFetching,
    isPlaceholderData,
  } = useQuery<Pool>(
    offChainKey,
    async () => {
      const data = (await queryResolver(query, variables))?.[ANTEPOOL_ACCESSOR];
      return await fetchAntePoolDataDB(data);
    },
    {
      enabled: typeof address !== "undefined",
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      initialData: () => {
        const allWithDetailsKey = PoolsCache.offChainKeys(
          GET_ANTEPOOLS_DETAILS_NODE,
          DEFAULT_VARIABLES
        ) as QueryKey;
        const allCached = queryClient.getQueryData<Pool[]>(allWithDetailsKey);
        if (typeof allCached !== "undefined") {
          return allCached?.find(
            (pool) =>
              pool.antePoolAddress === address &&
              ((chainId !== undefined && pool.chainId === chainId) ||
                chainId === undefined)
          );
        }

        // If requested without details try and retrieve it with details
        const withDetailsKey = PoolsCache.offChainKeys(
          GET_ANTEPOOL_DETAILS_NODE,
          DEFAULT_VARIABLES
        ) as QueryKey;
        const cached = queryClient.getQueryData(withDetailsKey) as Pool;

        return cached;
      },
      placeholderData: () => {
        const allWithoutDetailsKey = PoolsCache.offChainKeys(
          GET_ANTEPOOLS_NODE,
          DEFAULT_VARIABLES
        ) as QueryKey;

        const allCached =
          queryClient.getQueryData<Pool[]>(allWithoutDetailsKey);

        if (typeof allCached !== "undefined") {
          return allCached?.find(
            (pool) =>
              pool.antePoolAddress === address &&
              ((chainId !== undefined && pool.chainId === chainId) ||
                chainId === undefined)
          );
        }

        return undefined;
      },
    }
  );

  const walletData: Partial<Wallet> = {
    networkId,
    provider,
    account: walletAddress,
    refreshBalance,
  };

  const key = PoolsCache.keys.single(withDetails, address, walletData);

  const { data: onChainData, isLoading: isLoadingOnChain } = useQuery<Pool>(
    key,
    async () => onChainFetcher(walletData, offChainData),
    {
      enabled:
        isFetching === false &&
        offChainData !== undefined &&
        isPlaceholderData === false &&
        walletData.provider !== undefined &&
        walletAddress !== undefined &&
        isNetworkSupported,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      initialData: () => {
        if (isPlaceholderData === true) return undefined;

        const cached = getCachedPools(walletData);
        if (typeof cached === "undefined") return undefined;

        const cachedData = handlePoolFilters(cached, {
          address: address,
          chainId: chainId,
        });

        return cachedData?.[0];
      },
    }
  );

  if (prevOffChainPool.current !== offChainData) {
    prevOffChainPool.current = offChainData;
    result.current.pool = onChainData ?? offChainData;
  } else {
    result.current.pool = onChainData ?? result.current.pool;
  }

  result.current = {
    ...result.current,
    loading: isLoadingOffChain || isLoadingOnChain,
    loadingOffChain: isLoadingOffChain,
    loadingOnChain: isLoadingOnChain,
  };

  return result.current;
};
