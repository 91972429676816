import { ImgHTMLAttributes, ReactElement } from "react";
import { Rating } from "types/Rating";
import TrustABadge from "../../assets/ratings/badges/ante-trust-a.svg";
import TrustA from "../../assets/ratings/ante-trust-a.svg";
import TrustAABadge from "../../assets/ratings/badges/ante-trust-aa.svg";
import TrustAA from "../../assets/ratings/ante-trust-aa.svg";
import TrustNRBadge from "../../assets/ratings/badges/ante-trust-nr.svg";
import TrustNR from "../../assets/ratings/ante-trust-nr.svg";
import TrustSBadge from "../../assets/ratings/badges/ante-trust-s.svg";
import TrustS from "../../assets/ratings/ante-trust-s.svg";
import { Tooltip, Zoom } from "@material-ui/core";
import { formatTokenValue } from "utils/utils";
import { SUPPORTED_CURRENCIES } from "utils/constants";

type RatingMap = {
  [key in Rating]: ImgHTMLAttributes<HTMLImageElement> & {
    badgeSrc: string;
    tooltip?: ReactElement;
  };
};

type TrustBadgeProps = {
  rating: Rating;
  badge?: boolean;
  showTooltip?: boolean;
};

const ratingMap: RatingMap = {
  S: {
    badgeSrc: TrustSBadge,
    src: TrustS,
    alt: "Ante Trust S",
    tooltip: (
      <p>
        S-rated protocols have an overall <b>trust score over 90</b> and{" "}
        <b>
          at least {formatTokenValue("100", SUPPORTED_CURRENCIES.eth.symbol)}{" "}
          staked
        </b>{" "}
        in total across all of their Ante Tests
      </p>
    ),
  },
  AA: {
    badgeSrc: TrustAABadge,
    src: TrustAA,
    alt: "Ante Trust AA",
    tooltip: (
      <p>
        AA-rated protocols have an overall <b>trust score over 85</b> and{" "}
        <b>
          at least {formatTokenValue("30", SUPPORTED_CURRENCIES.eth.symbol)}{" "}
          staked
        </b>{" "}
        in total across all of their Ante Tests
      </p>
    ),
  },
  A: {
    badgeSrc: TrustABadge,
    src: TrustA,
    alt: "Ante Trust A",
    tooltip: (
      <p>
        A-rated protocols have an overall <b>trust score over 80</b> and{" "}
        <b>
          at least {formatTokenValue("10", SUPPORTED_CURRENCIES.eth.symbol)}{" "}
          staked
        </b>{" "}
        in total across all of their Ante Tests
      </p>
    ),
  },
  NR: {
    badgeSrc: TrustNRBadge,
    src: TrustNR,
    alt: "Ante Trust NR",
    tooltip: (
      <p>
        NR-rated protocols have an overall <b>trust score below 80</b> or{" "}
        <b>
          less than {formatTokenValue("10", SUPPORTED_CURRENCIES.eth.symbol)}{" "}
          staked
        </b>{" "}
        in total across all of their Ante Tests
      </p>
    ),
  },
};

const TrustBadge = ({
  rating,
  badge = true,
  showTooltip = false,
}: TrustBadgeProps) => {
  const renderBadge = () => {
    return (
      <img
        src={badge ? ratingMap[rating].badgeSrc : ratingMap[rating].src}
        alt={ratingMap[rating].alt}
      />
    );
  };

  if (showTooltip && ratingMap[rating].tooltip !== undefined) {
    return (
      <Tooltip
        title={ratingMap[rating].tooltip}
        placement="top"
        TransitionComponent={Zoom}
        arrow
        interactive
      >
        {renderBadge()}
      </Tooltip>
    );
  }

  return renderBadge();
};

export default TrustBadge;
