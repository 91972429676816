import cn, { Argument } from "classnames";
import { Row } from "react-table";
import { useStyles } from "./AnteTable.styles";
import { useAlignStyles } from "./useAlignStyles";

export type AnteRowType<T extends {}> = {
  rowData: Row<T>;
  numberOfColumns: number;
  classNames?: Argument;
  prepend?: JSX.Element | string;
};

export const AnteRow = <T extends Record<string, any>>({
  numberOfColumns,
  rowData,
  classNames,
  prepend,
}: AnteRowType<T>) => {
  const styles = useStyles({ numColumns: numberOfColumns });
  const { getTextStyle } = useAlignStyles();

  return (
    <div
      {...rowData.getRowProps([
        {
          className: cn(styles.row, classNames),
        },
      ])}
    >
      {prepend}
      {rowData.cells.map((cell) => {
        return (
          <div
            {...cell.getCellProps([
              {
                className: cn(
                  styles.cell,
                  getTextStyle(cell.column.align),
                  cell.column.classNames
                ),
              },
            ])}
          >
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );
};
