import React from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "style/theme";

import Web3WalletProvider from "utils/wallet";
import { QueryClientProvider } from "react-query";
import { SnackbarUtilsConfigurator } from "utils/SnackbarUtils";
import queryClient from "utils/queryClient";
import store from "./state";
import { HelmetProvider } from "react-helmet-async";

const Providers: React.FC = ({ children }) => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <SnackbarUtilsConfigurator />
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <Web3WalletProvider>{children}</Web3WalletProvider>
              </QueryClientProvider>
            </Provider>
          </SnackbarProvider>
        </CssBaseline>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default Providers;
