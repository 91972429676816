import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import WithdrawImage from "assets/img/withdraw.svg";
import { formatUnits } from "@ethersproject/units";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";
import { formatTokenValue, getTokenByChain } from "utils/utils";

interface WithdrawChallengeModalProps {
  isShown: boolean;
  hide: () => void;
}

const WithdrawChallengeModal = ({
  isShown,
  hide,
}: WithdrawChallengeModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);

  const {
    challengedStartAmount: startAmount,
    challengedBalanceUnit: currentBalance,
    withdrawChallenge,
  } = pool;
  const token = getTokenByChain(pool.chainId);
  const decayLost = Number(formatUnits(startAmount.sub(currentBalance)));
  const initialBalance = Number(formatUnits(startAmount));
  const currentBalanceETH = Number(formatUnits(currentBalance));

  const withdrawChallengeSubmit = async () => {
    setSubmitting(true);
    try {
      await withdrawChallenge();
      hide();
      // trigger success popup
    } catch (error: any) {
      console.error(error);
      window.rollbar.warn(
        "WithdrawChallengeModal:withdrawChallengeSubmit",
        error
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
        <DialogTitle>Withdraw Challenge</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img src={WithdrawImage} alt="Withdraw Challenge" height="128" />
          </div>
          <DialogContentText>
            <h3>Challenge Breakdown</h3>
            <TableContainer>
              <Table size="small" aria-label="challenge breakdown">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Initial balance
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={formatTokenValue(
                          initialBalance.toFixed(16),
                          token
                        )}
                        placement="top"
                        arrow
                      >
                        <span>
                          {formatTokenValue(initialBalance.toFixed(3), token)}
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Decay lost
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={formatTokenValue(decayLost.toFixed(16), token)}
                        placement="top"
                        arrow
                      >
                        <span>
                          {formatTokenValue(decayLost.toFixed(3), token)}
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>Total balance</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={formatTokenValue(
                          currentBalanceETH.toFixed(16),
                          token
                        )}
                        placement="top"
                        arrow
                      >
                        <span>
                          <strong>
                            {formatTokenValue(
                              currentBalanceETH.toFixed(3),
                              token
                            )}
                          </strong>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button
            onClick={withdrawChallengeSubmit}
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {submitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WithdrawChallengeModal;
