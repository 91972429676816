import XIcon from "assets/img/x.svg";
import { bannerStyles } from "./TopBanner.styles";

const TopBanner = ({ closeBanner }) => {
  const styles = bannerStyles();
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        While Ante v0.5 has been audited (read our&nbsp;
        <a
          href="https://medium.com/ante-finance/ante-v0-5-zellic-audit-walkthrough-859dc9c74a47"
          className={styles.link}
          target="_blank"
          rel="noreferrer noopener"
        >
          walkthrough
        </a>
        ), please exercise caution when interacting with smart contracts.
      </div>
      <div className={styles.closeButton} onClick={closeBanner}>
        <img src={XIcon} alt="close" />
      </div>
    </div>
  );
};

export { TopBanner };
