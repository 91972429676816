import { LATEST_APP_BASE_URL } from "utils/constants";
import { bannerStyles } from "../TopBanner/TopBanner.styles";

const V06Banner = () => {
  const styles = bannerStyles();
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        A newer version of Ante is available!&nbsp;
        <a
          href={LATEST_APP_BASE_URL}
          className={styles.link}
          rel="noreferrer noopener"
        >
          Check it out here
        </a>
      </div>
    </div>
  );
};

export default V06Banner;
