import React from "react";
import { Pool } from "types";

export type ActionPanelContextType = {
  pool?: Pool;
  loading?: boolean;
  submitting?: boolean;
  setSubmitting?: (submitting: boolean) => void;
};

export const ActionPanelContext = React.createContext<ActionPanelContextType>(
  {}
);
