import { Typography } from "@material-ui/core";
import { useStyles } from "./EmptyTable.styles";

type EmptyTableProps = {
  protocolName: string;
  protocolWebsite: string;
};
export const EmptyTable = ({
  protocolName,
  protocolWebsite,
}: EmptyTableProps) => {
  const styles = useStyles();

  return (
    <Typography variant="body1" className={styles.text}>
      {protocolName} does not have any Ante Tests written. <br />
      <a
        href={protocolWebsite}
        className={styles.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        Reach out to them
      </a>{" "}
      or{" "}
      <a
        href="https://docs.ante.finance/antev05/for-devs/writing-an-ante-test"
        className={styles.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        submit a test for the protocol.
      </a>
    </Typography>
  );
};
