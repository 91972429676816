import { useEffect, useState } from "react";
import Fuse from "fuse.js";
import { useLocalStorage } from "react-use-storage";
import { Pool } from "types";

import { ConnectWalletView } from "pages/ConnectWallet";
import { ANTE_DEPLOYED_POOLS, SupportedChainId } from "utils/constants";
import { useWallet } from "utils/wallet";
import { getFormattedPools } from "./utils";
import { useLeaderboardStyles } from "../../Leaderboard.styles";
import { useAntePools } from "hooks/antepools/useAntePools";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import { PoolChainTotals } from "pages/Leaderboard";
import { HIDDEN_NETWORKS_KEY, TestsFilters } from "./TestsFilters";
import { computePoolsTotals } from "pages/Leaderboard/utils";
import useColumns, { PoolRowProps } from "./useColumns";
import { AnteTable } from "components/AnteTable";
import { PoolRow } from "./PoolRow";
import { PoolHeaders } from "./PoolHeaders";
import { PoolPositions } from "types";
import { ethers } from "ethers";
import { getNetworkById } from "utils/utils";

export type Filters = {
  name: string;
};

export type TestsViewProps = {
  onKPIsChange: (totals: PoolChainTotals) => void;
};

const TestsView = ({ onKPIsChange }: TestsViewProps) => {
  const { pools, loadingOffChain } = useAntePools();

  const { prices } = useFetchTokenPrice();
  const [filters, setFilters] = useState<Filters>({
    name: "",
  });
  const wallet = useWallet();
  const styles = useLeaderboardStyles();

  const [hiddenNetworks] =
    useLocalStorage<SupportedChainId[]>(HIDDEN_NETWORKS_KEY);
  const [showFailed] = useLocalStorage("leaderboard_show_failed_tests", false);
  const [showUnverified] = useLocalStorage(
    "leaderboard_show_unverified_tests",
    true
  );
  const [showUserInterface] = useLocalStorage(
    "leaderboard_show_user_interface_tests",
    false
  );

  const [displayedPools, setDisplayedPools] = useState<Array<PoolRowProps>>([]);
  const [currentTimestamp, setCurrentTimestamp] = useState<number>();
  const { getColumns } = useColumns();

  useEffect(() => {
    const fetchTimestamp = async () => {
      const web3 = new ethers.providers.Web3Provider(wallet.provider);
      const blockNumber = await web3.getBlockNumber();
      const block = await web3.getBlock(blockNumber);
      setCurrentTimestamp(Number(block.timestamp));
    };

    if (wallet.provider) {
      fetchTimestamp();
    }
  }, [wallet.provider]);

  useEffect(() => {
    if (pools === undefined) return;

    const handleFilters = (pools: Array<Pool>): Array<Pool> => {
      let filteredPools = pools;
      for (const filter of Object.keys(filters)) {
        if (filter === "name" && filters[filter]) {
          const fuse = new Fuse(filteredPools, {
            keys: ["testName", "protocolName"],
            threshold: 0.1,
          });
          filteredPools = fuse
            .search(filters[filter])
            .map((result) => result.item);
        }
      }
      return filteredPools;
    };

    let poolsFiltered: Pool[] = pools;

    try {
      if (!showFailed) {
        poolsFiltered = pools.filter(({ pendingFailure }) => !pendingFailure);
      }

      if (wallet.provider && showUserInterface) {
        poolsFiltered = poolsFiltered.filter(
          ({ poolPosition }) => !(poolPosition === PoolPositions.None)
        );
      }

      onKPIsChange(computePoolsTotals(poolsFiltered));

      // still want total TVL and # active pools to include unverified pools so do final filtering here
      if (!showUnverified) {
        poolsFiltered = poolsFiltered.filter(
          ({ antePoolAddress, teamVerified }) =>
            ANTE_DEPLOYED_POOLS.includes(antePoolAddress) || teamVerified
        );
      }

      if (hiddenNetworks) {
        poolsFiltered = poolsFiltered.filter(
          (pool) => !hiddenNetworks.includes(pool.chainId.toLowerCase())
        );
      }

      poolsFiltered = handleFilters(poolsFiltered);

      const connectedChain = getNetworkById(wallet.networkId);
      const formattedPools = getFormattedPools(
        poolsFiltered,
        currentTimestamp,
        prices,
        connectedChain
      );

      setDisplayedPools(formattedPools);
    } catch (e: any) {
      console.error("leaderboard error", e);
      window.rollbar.warn("TestsView", e);
    }
  }, [
    onKPIsChange,
    filters,
    prices,
    pools,
    showFailed,
    showUnverified,
    showUserInterface,
    hiddenNetworks,
    currentTimestamp,
    wallet.networkId,
    wallet.provider,
  ]);

  const _renderTable = () => {
    if (showUserInterface && (!wallet.account || !wallet.isNetworkSupported)) {
      return <ConnectWalletView />;
    }

    return (
      <AnteTable
        data={displayedPools}
        columns={getColumns(wallet.account !== undefined)}
        isLoading={loadingOffChain}
        RowComponent={PoolRow}
        HeadersComponent={PoolHeaders}
        initialState={{
          sortBy: [
            {
              id: "ante-value-locked",
              desc: true,
            },
          ],
        }}
      />
    );
  };

  return (
    <>
      <div className={styles.header}>
        <TestsFilters
          filters={filters}
          onChange={(newFilters) => setFilters(newFilters)}
        />
      </div>
      <br />
      {_renderTable()}
    </>
  );
};

export { TestsView };
