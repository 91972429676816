import { Tooltip } from "@material-ui/core";
import cn, { Argument } from "classnames";
import { ColumnInstance } from "react-table";
import { useStyles } from "./AnteTable.styles";
import DownArrow from "assets/img/downarrow.svg";
import { useAlignStyles } from "./useAlignStyles";

export type AnteHeadersType<T extends {}> = {
  headers: ColumnInstance<T>[];
  classNames?: Argument;
};

const AnteHeaders = <T extends Record<string, any>>({
  headers,
  classNames,
}: AnteHeadersType<T>) => {
  const styles = useStyles({ numColumns: headers.length });
  const { getTextStyle } = useAlignStyles();

  return (
    <div className={cn(styles.headers, classNames)}>
      {headers.map((column) => (
        <div
          {...column.getHeaderProps([
            column.getSortByToggleProps(),
            {
              className: cn(styles.header, getTextStyle(column.align)),
            },
          ])}
        >
          {column.tooltip ? (
            <Tooltip arrow placement="top" title={column.tooltip}>
              <span>{column.render("Header")}</span>
            </Tooltip>
          ) : (
            column.render("Header")
          )}
          {column.isSorted ? (
            <img
              src={DownArrow}
              className={cn(styles.filterIcon, {
                [styles.ascending]: !column.isSortedDesc,
              })}
              alt="down arrow"
            />
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export { AnteHeaders };
