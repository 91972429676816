import React, { forwardRef } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SnackbarContent } from "notistack";

import EtherscanLogo from "assets/img/etherscan-logo.svg";
import { Chain, CHAIN_EXPLORERS } from "utils/constants";

export enum TxnStatuses {
  Pending = "Transaction pending...",
  Confirmed = "Transaction complete!",
  Reverted = "Transaction failed!",
}

const TxFlow = forwardRef<
  HTMLDivElement,
  {
    message: string | React.ReactNode;
    txnHash: string;
    status: TxnStatuses;
    closeSnackbar: () => void;
    chain: Chain;
  }
>((props, ref) => {
  const { chain, message, txnHash, status, closeSnackbar } = props;

  let statusColor: string;
  if (status === TxnStatuses.Pending) {
    statusColor = "#555555";
  } else if (status === TxnStatuses.Confirmed) {
    statusColor = "#1AB097";
  } else if (status === TxnStatuses.Reverted) {
    statusColor = "#DA160C";
  }

  return (
    <SnackbarContent
      ref={ref}
      style={{ pointerEvents: "auto", marginTop: "8px" }}
    >
      <Card
        style={{ padding: "12px", width: "225px", backgroundColor: "white" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Typography
            variant="body2"
            style={{ fontWeight: 600, color: "#777777" }}
          >
            <span style={{ alignItems: "center" }}>{message}</span>
          </Typography>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Typography
          variant="body2"
          style={{ color: statusColor, marginBottom: "8px", fontWeight: 600 }}
        >
          {status}
        </Typography>
        {CHAIN_EXPLORERS[chain.id] && (
          <Typography variant="caption">
            <span>
              <a
                href={`${CHAIN_EXPLORERS[chain.id].transaction}${txnHash}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  color: "#2D9CDB",
                }}
              >
                <span style={{ marginRight: "6px" }}>View in Explorer</span>
                {CHAIN_EXPLORERS[chain.id].logo && (
                  <img src={EtherscanLogo} alt="etherscan logo" height="16" />
                )}
              </a>
            </span>
          </Typography>
        )}
      </Card>
    </SnackbarContent>
  );
});

export default TxFlow;
