import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { SUPPORTED_CHAINS } from "utils/constants";
import { useWallet } from "utils/wallet";
import { useStyles } from "./SelectNetworkModal.styles";

type SelectNetworkModalProps = {
  isShown: boolean;
  hide: () => void;
};

export const SelectNetworkModal = ({
  isShown,
  hide,
}: SelectNetworkModalProps) => {
  const styles = useStyles();
  const { account, setChain } = useWallet();

  const handleChainChange = (chainId: string) => {
    if (account) {
      setChain(chainId);
    }
    hide();
  };

  const _renderSupportedChains = () => {
    return Object.keys(SUPPORTED_CHAINS).map((networkKey) => {
      const network = SUPPORTED_CHAINS[networkKey];

      return (
        <div
          key={network.id}
          className={styles.networkItem}
          onClick={() => handleChainChange(network.id)}
        >
          {network.logo && (
            <img
              src={network.logo}
              alt={network.label}
              className={styles.logo}
            />
          )}
          <Typography variant={"body1"} className={styles.networkLabel}>
            Switch to {network.label}
          </Typography>
        </div>
      );
    });
  };

  return (
    <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
      <DialogTitle>Unsupported network</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            Please connect to a supported network
          </Typography>
        </DialogContentText>
        <div className={styles.networksContainer}>
          {_renderSupportedChains()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};
