import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  bnToStringPrecision,
  formatTokenValue,
  getTokenByChain,
} from "utils/utils";
import { BigNumber } from "@ethersproject/bignumber";
import WithdrawImage from "assets/img/withdraw.svg";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";

function createData(
  rewardComponent: string,
  value: string,
  tooltipText: string
) {
  return { rewardComponent, value, tooltipText };
}

interface ClaimModalProps {
  isShown: boolean;
  hide: () => void;
  decayLost: BigNumber;
  callerBonus: BigNumber;
  challengeRewards: BigNumber | undefined;
}

const ClaimModal = ({
  isShown,
  hide,
  decayLost,
  callerBonus,
  challengeRewards,
}: ClaimModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);

  const {
    claim,
    challengedStartAmount: initialBalance,
    challengerPayout,
  } = pool;
  const token = getTokenByChain(pool.chainId);
  const rows = [
    createData(
      "Initial balance",
      `${formatTokenValue(bnToStringPrecision(initialBalance), token)}`,
      `${formatTokenValue(bnToStringPrecision(initialBalance, 16), token)}`
    ),
    createData(
      "Decay lost",
      `${formatTokenValue(bnToStringPrecision(decayLost), token)}`,
      `${formatTokenValue(bnToStringPrecision(decayLost, 16), token)}`
    ),
    createData(
      "First caller bonus",
      `${formatTokenValue(bnToStringPrecision(callerBonus), token)}`,
      `${formatTokenValue(bnToStringPrecision(callerBonus, 16), token)}`
    ),
    createData(
      "Challenger rewards",
      `${formatTokenValue(bnToStringPrecision(challengeRewards), token)}`,
      `${formatTokenValue(bnToStringPrecision(challengeRewards, 16), token)}`
    ),
  ];

  const handleClaim = async () => {
    try {
      setSubmitting(true);
      await claim();
      hide();
      // trigger success popup
    } catch (error: any) {
      console.error(error);
      window.rollbar.warn("ClaimModal:handleClaim", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
        <DialogTitle>Claim Rewards</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img src={WithdrawImage} alt="Claim Rewards" height="128" />
            <h3>Rewards Breakdown</h3>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {rows.map((row) => {
                    let color;
                    if (row.rewardComponent === "Decay lost") {
                      color = "#DA160C";
                    } else if (
                      row.rewardComponent === "First caller bonus" ||
                      row.rewardComponent === "Challenger rewards"
                    ) {
                      color = "#1BAE34";
                    } else {
                      color = "#555555";
                    }
                    return (
                      <TableRow key={row.rewardComponent}>
                        <TableCell>{row.rewardComponent}</TableCell>
                        <TableCell align="right">
                          <Tooltip
                            title={row.tooltipText}
                            placement="top"
                            arrow
                          >
                            <span style={{ color }}>{row.value}</span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell>
                      <strong>Total rewards</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={`${formatTokenValue(
                          bnToStringPrecision(challengerPayout, 16),
                          token
                        )}`}
                        placement="top"
                        arrow
                      >
                        <span>
                          <strong>
                            {formatTokenValue(
                              bnToStringPrecision(challengerPayout),
                              token
                            )}
                          </strong>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button
            style={{ width: "85px" }}
            onClick={handleClaim}
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {submitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClaimModal;
