import { Card, CircularProgress, Typography } from "@material-ui/core";
import { Pool } from "types";
import { useStyles } from "./ProgressNotification.styles";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { Link } from "react-router-dom";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import { useSelectCreatePool } from "state/selectors";

interface ProgressNotificationProps {
  pool: Pool;
}

export const ProgressNotification = ({ pool }: ProgressNotificationProps) => {
  const styles = useStyles();
  const createPoolState = useSelectCreatePool();
  const { hasSubmitted, isSubmitting, submissionError } = createPoolState;

  // no progress message if user hasn't submitted yet
  if (!hasSubmitted) {
    return null;
  }
  if (isSubmitting) {
    return (
      <Card variant="outlined" className={styles.progress}>
        <CircularProgress />
        <Typography variant="body1" className={styles.leftSpacing}>
          Pool creation in progress. This may take up to several minutes...
        </Typography>
      </Card>
    );
  }
  if (submissionError) {
    return (
      <Card variant="outlined" className={styles.warning}>
        <ErrorOutline fontSize="large" />
        <Typography variant="body1" className={styles.leftSpacing}>
          {`Error: ${submissionError}`}
        </Typography>
      </Card>
    );
  }
  if (pool) {
    return (
      <Card variant="outlined" className={styles.complete}>
        <CheckCircleOutline fontSize="large" className={styles.completeIcon} />
        <Typography variant="body1" className={styles.leftSpacing}>
          Pool Creation Successful!{" "}
          <Link
            to={{
              pathname: `/contract/${pool.chainId}/${pool.antePoolAddress}`,
              state: {
                contractAddress: pool.antePoolAddress,
                testAddress: pool.anteTestAddress,
              },
            }}
            className={styles.link}
          >
            View here
          </Link>
        </Typography>
      </Card>
    );
  }
  if (!isSubmitting) {
    // transaction was successful, pool is being created
    return (
      <Card variant="outlined" className={styles.progress}>
        <CircularProgress />
        <Typography variant="body1">
          Fetching data for newly created pool...
        </Typography>
      </Card>
    );
  }

  return null;
};
