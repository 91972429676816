import { Link } from "react-router-dom";

import Skeleton from "@material-ui/lab/Skeleton";

import { CHAIN_EXPLORERS, FRANKIE_POOL, JSEAM_POOL } from "utils/constants";
import { PoolBadges } from "components/PoolBadges/PoolBadges";
import { getBadges } from "utils/antepools/utils";
import { Pool } from "types";
import { getNetworkById, shortenString } from "utils/utils";
import { Typography } from "@material-ui/core";
import { useStyles } from "./TestHeader.styles";
import sanitizeHtml from "sanitize-html";

interface TestHeaderProps {
  loading?: boolean;
  contractAddress?: string;
  pool: Pool;
}

const sanitizeOptions = {
  allowedTags: ["b", "i", "em", "strong", "a"],
};

const TestHeader = ({ loading, contractAddress, pool }: TestHeaderProps) => {
  const styles = useStyles();
  const { protocolName, testName, testAuthor } = pool;
  const chain = getNetworkById(pool.chainId);

  const renderProtocolLink = () => {
    if (loading === true) {
      return <Skeleton variant="text" animation="wave" width={80} />;
    }

    return (
      <div
        data-testid="protocol-link-container"
        className={styles.nameContainer}
      >
        {protocolName ? (
          <Link
            className={styles.link}
            to={`/protocol/${protocolName}`}
            data-testid="protocol-link"
          >
            {protocolName}
          </Link>
        ) : (
          protocolName
        )}
      </div>
    );
  };

  const displayName = testAuthor?.ensName
    ? testAuthor.ensName
    : testAuthor
    ? shortenString(testAuthor?.address)
    : null;

  const authorAddress = testAuthor.address;

  const sanitizedDescription = sanitizeHtml(
    pool.testDescription,
    sanitizeOptions
  );

  return (
    <div className={styles.testHeaderRoot}>
      <div className={styles.headerContainer}>
        {renderProtocolLink()}
        <img src={chain.logo} alt={chain.label} width={24} height={24} />
        <Typography variant="body1" display="inline">
          {chain.label}
        </Typography>
        <div className={styles.rightSideHeader}>
          <PoolBadges badges={getBadges(pool)} />
        </div>
      </div>
      {!loading ? (
        <div className={styles.protocolTitle}>{testName}</div>
      ) : (
        <Skeleton variant="text" animation="wave" width={240} height={36} />
      )}
      <div className={styles.addressContainer}>
        {CHAIN_EXPLORERS[chain.id] ? (
          <a
            href={`${CHAIN_EXPLORERS[chain.id].address}${contractAddress}`}
            color="textSecondary"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {contractAddress}
          </a>
        ) : (
          contractAddress
        )}
      </div>
      {contractAddress === JSEAM_POOL.address && (
        <div className={styles.author}>
          Written by{" "}
          <a
            href={JSEAM_POOL.githubUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {JSEAM_POOL.name}
          </a>
        </div>
      )}
      {contractAddress === FRANKIE_POOL.address && (
        <div className={styles.author}>
          Written by{" "}
          <a
            href={FRANKIE_POOL.githubUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {FRANKIE_POOL.name}
          </a>
        </div>
      )}
      {!(
        contractAddress === JSEAM_POOL.address ||
        contractAddress === FRANKIE_POOL.address
      ) &&
        authorAddress !== null && (
          <div className={styles.author}>
            Written by{" "}
            {CHAIN_EXPLORERS[chain.id] ? (
              <a
                href={`${CHAIN_EXPLORERS[chain.id].address}${authorAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {displayName}
              </a>
            ) : (
              displayName
            )}
          </div>
        )}
      <Typography
        variant="body2"
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
      />
    </div>
  );
};

export default TestHeader;
