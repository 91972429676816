import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import colors from "style/colors";
import fontType from "style/typography";

const useStyles = makeStyles({
  root: {},
  kpiLabel: {
    ...fontType.labelSmall,
    color: colors.black69,
  },
  kpiStat: {
    ...fontType.kpiMedium,
    color: colors.statPurple,
  },
  kpiSubtext: {
    ...fontType.subtextSmall,
    color: colors.black42,
  },
});

export interface KPIProps {
  label: React.ReactNode;
  labelColor?: string;
  value: React.ReactNode;
  valueColor?: string;
  footnote?: React.ReactNode;
  footnoteColor?: string;
  status?: React.ReactNode;
  statusColor?: string;
  tooltip?: React.ReactNode;
  size?: "medium" | "small";
  isLoaded?: boolean;
}

function KPI({
  label,
  value,
  footnote,
  status,
  labelColor = colors.greyscale,
  valueColor = colors.statPurple,
  footnoteColor = colors.greyscaleSecondary,
  statusColor = "#7777777",
  tooltip,
  size,
  isLoaded,
}: KPIProps) {
  const styles = useStyles();
  return (
    <div data-testselector={`kpi-${label}`}>
      {tooltip ? (
        <Tooltip title={tooltip} placement="top" arrow>
          <div>
            {label && (
              <Typography
                variant="body2"
                display="block"
                className={styles.kpiLabel}
              >
                {label}
              </Typography>
            )}
            <Typography variant="h5" display="block" className={styles.kpiStat}>
              {isLoaded ? value + " " : <Skeleton />}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <>
          {label && (
            <Typography
              variant="body2"
              display="block"
              className={styles.kpiLabel}
            >
              {label}
            </Typography>
          )}
          <Typography variant="h5" display="block" className={styles.kpiStat}>
            {isLoaded ? value + " " : <Skeleton />}
          </Typography>
        </>
      )}
      {status && (
        <Typography variant="caption" style={{ color: statusColor }}>
          {status}
        </Typography>
      )}
      {footnote && (
        <Typography
          variant="body2"
          display="block"
          className={styles.kpiSubtext}
        >
          {isLoaded ? footnote : <Skeleton width={100} />}
        </Typography>
      )}
    </div>
  );
}

export default KPI;
