import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getCodeCall } from "utils/apicall";
import { useWallet } from "utils/wallet";

import BytecodeOnlyImage from "assets/img/bytecode.svg";
import NoContractsImage from "assets/img/no-contracts.svg";

import { testCodeStyles } from "./TestCodeView";
import { useSourceCodes } from "hooks/sourceCode/useSourceCodes";
import { Skeleton } from "@material-ui/lab";
import { Chain, CHAIN_EXPLORERS } from "utils/constants";

interface ContractsViewProps {
  testedContracts?: string[];
  chain: Chain;
}

const ContractsView = ({ testedContracts, chain }: ContractsViewProps) => {
  const wallet = useWallet();
  const [sourceCode, setSourceCode] = useState({});
  const { sourceCodes, loading } = useSourceCodes({
    addresses: testedContracts,
    chainId: chain.id,
  });

  useEffect(() => {
    if (loading) {
      return;
    }
    const sourceCodeMap =
      sourceCodes?.reduce(
        (result, code) => ({
          ...result,
          [code.address]: {
            sourceCode: code.sourceCode,
            contractName: code.contractName,
          },
        }),
        {}
      ) ?? {};
    if (testedContracts === undefined || testedContracts.length === 0) {
      setSourceCode({
        noContracts: "No contracts specified by Ante Test author.",
      });
      return;
    }
    const fetchCode = async (address) => {
      if (sourceCodeMap.hasOwnProperty(address)) {
        return {
          code: sourceCodeMap[address].sourceCode,
          contractName: sourceCodeMap[address].contractName,
          address,
        };
      } else {
        try {
          const code = await CHAIN_EXPLORERS[chain.id].getSourceCode(address);
          if (code[0]["SourceCode"]) {
            const contractName = code[0]["ContractName"];
            let obj = code[0]["SourceCode"];
            if (obj.startsWith("{{") && obj.endsWith("}}")) {
              obj = obj.substring(1, obj.length - 1);
              obj = JSON.parse(obj)["sources"];
            }
            return {
              code: obj,
              contractName,
              address,
            };
          } else {
            const bytecode = await getCodeCall(wallet, address);
            if (bytecode === "0x") {
              return {
                code: "Externally Owned Address",
                contractName: "Address",
                address,
              };
            } else if (bytecode.startsWith("0x")) {
              return {
                code: "Bytecode only available.",
                contractName: "Bytecode",
                address,
              };
            } else {
              return {
                code: bytecode,
                contractName: "Other",
                address,
              };
            }
          }
        } catch (e) {
          return {
            code: "Error when fetching source code:\n" + e,
            address,
          };
        }
      }
    };

    const fetchAll = async () => {
      let fetchPromises = [];
      testedContracts.forEach((address) => {
        fetchPromises.push(fetchCode(address));
      });
      const fetchedCode = await Promise.all(fetchPromises);
      let parsedCode = {};
      fetchedCode.forEach(({ code, contractName, address }) => {
        let mainCode = "None Detected";
        if (typeof code === "string") {
          mainCode = code;
        } else {
          Object.keys(code).forEach((key) => {
            if (key.includes("/" + contractName + ".sol")) {
              mainCode = code[key]["content"];
            }
          });
        }
        if (mainCode in parsedCode) {
          parsedCode[mainCode].push(address);
        } else {
          parsedCode[mainCode] = [address];
        }
      });
      setSourceCode(parsedCode);
    };
    fetchAll();
  }, [sourceCodes, testedContracts, wallet, loading, chain.id]);

  const styles = testCodeStyles();

  const ContractViewBuilder = () => {
    if (testedContracts === undefined || testedContracts.length === 0) {
      return (
        <Box display="flex" justifyContent="center">
          <Paper variant="outlined">
            <Box m={2}>
              <p>{sourceCode["noContracts"]}</p>
            </Box>
            <img src={NoContractsImage} alt="No Contracts" height="128" />
          </Paper>
        </Box>
      );
    }

    return (
      <Grid item xs={12}>
        {Object.entries(sourceCode).map(
          ([key, value]: [string, string[] | any]) => (
            <Grid item xs={12} key={key}>
              <Paper variant="outlined">
                <div className={styles.address}>
                  <strong className={styles.anteTest}>
                    {value.length > 1 ? "Addresses" : "Address"}
                  </strong>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {value.map((address: string) =>
                      CHAIN_EXPLORERS[chain.id] ? (
                        <a
                          key={address}
                          href={`${
                            CHAIN_EXPLORERS[chain.id].address
                          }${address}`}
                          color="textSecondary"
                          target="_blank"
                          rel="noreferrer"
                          className={styles.link}
                        >
                          {address}
                        </a>
                      ) : (
                        <div>{address}</div>
                      )
                    )}
                  </div>
                </div>
              </Paper>
              <Grid item xs={12}>
                <Box
                  bgcolor="#F1F1F1"
                  borderRadius={16}
                  fontSize={14}
                  maxHeight={400}
                  overflow="auto"
                  p={2}
                  style={{ margin: "16px 0" }}
                >
                  <pre>
                    <code>{key}</code>
                  </pre>
                  {key === "Bytecode only available." && (
                    <img
                      src={BytecodeOnlyImage}
                      alt="Bytecode Contract"
                      height="128"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    );
  };

  if (loading) {
    return <Skeleton animation="wave" />;
  }
  return (
    <Grid container spacing={2} style={{ marginTop: "8px" }}>
      <Grid item xs={12}>
        <ContractViewBuilder />
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" className={styles.etherscan}>
          Powered by Etherscan.io APIs
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ContractsView;
