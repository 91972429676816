import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export type CellStyles = {
  heading: string;
  value: string;
  subValue: string;
  skeletonContainer: string;
  newTest: string;
  eyebrow: string;
  eyebrowText: string;
  tooltip: string;
  skeletonHeadingValue: string;
  skeletonValue: string;
  relative: string;
  absoluteCentered: string;
  chainLogo: string;
  horizontalContainer: string;
  headerChainsContainer: string;
  protocolHeader: string;
};

export const useCellStyles = makeStyles((theme) => ({
  headingCellContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      gridColumn: "1 / -1",
      marginBottom: ".5rem",
      alignItems: "center",
    },
  },
  heading: {
    color: colors.primaryBlue,
    whiteSpace: `nowrap`,
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "normal",
      textAlign: "center",
      margin: "0 4px",
    },
  },
  value: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "16px",
    color: colors.greyscale,
  },
  subValue: {
    color: colors.mediumEmphasis,
    display: "block",
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
  },
  newTest: {
    marginLeft: "8px",
    color: colors.black69,
    backgroundColor: colors.newYellow,
    fontSize: ".5625rem",
    height: "18px",
    fontWeight: 600,
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  eyebrow: {
    marginBottom: "4px",
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8px",
    },
  },
  eyebrowText: {
    textTransform: "uppercase",
    lineHeight: "16px",
    fontWeight: 600,
  },
  tooltip: {
    textAlign: "center",
  },
  skeletonHeadingValue: {
    maxWidth: "250px",
    width: "100%",
    margin: "0 auto",
  },
  skeletonValue: {
    maxWidth: "100px",
    width: "100%",
  },
  relative: {
    position: "relative",
  },
  absoluteCentered: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    display: "inline-flex",
    gap: "0.1rem",
    position: "relative",
  },
  protocolHeader: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  headerChainsContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
    },
  },
  chainLogo: {
    width: "24px",
    height: "24px",
  },
}));
