import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  badges: {
    display: "inline-flex",
    gap: "0.25rem",
    verticalAlign: "text-top",
  },
  tooltip: {
    textAlign: "center",
  },
});
