import gql from "graphql-tag";
import { PROTOCOL_CORE_FIELDS } from "../fragments/protocol";

export const PROTOCOLS_ACCESSOR = "protocolCollections";

export const PROTOCOL_ACCESSOR = "protocolCollection";

export const GET_PROTOCOLS_NODE = gql`
${PROTOCOL_CORE_FIELDS}
query GetProtocols($isVisible: Boolean) {
  ${PROTOCOLS_ACCESSOR}(query: { isHidden_ne: $isVisible }) {
   ...ProtocolCoreFields
  }
}
`;

export const GET_PROTOCOL_NODE = gql`
${PROTOCOL_CORE_FIELDS}
query GetProtocol($name: String, $isVisible: Boolean) {
  ${PROTOCOL_ACCESSOR}(query: { name: $name, isHidden_ne: $isVisible }) {
   ...ProtocolCoreFields
  }
}
`;
