import { useState, useEffect } from "react";

const CountdownTimer = ({ duration }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((time) => time - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor(timeLeft % 60);

  return <span>{`${hours}h ${minutes}m ${seconds}s`}</span>;
};

export default CountdownTimer;
