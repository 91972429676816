import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const useStyles = makeStyles({
  viewSelector: {
    borderRadius: ".5rem",
    display: "inline-flex",
    background: "#fff",
    border: ".25rem solid #fff",
  },
  option: {
    borderRadius: ".25rem",
    color: colors.linkBlue,
    cursor: "pointer",
    fontSize: "1rem",
    padding: ".25rem 1rem",
    transitionDuration: ".3s",
    "&:hover": {
      background: colors.linkBlue10,
    },
    "&.selected": {
      color: colors.black77,
      background: colors.linkBlue23,
      fontWeight: 600,
    },
  },
});
