import { FormControl, FormGroup, MenuItem, Select } from "@material-ui/core";
import { SearchField } from "components/SearchField/SearchField";
import { Rating } from "types";
import { SelectInput, useStyles } from "./ProtocolsFilters.styles";
import { Filters } from "../ProtocolsView";
import { DashboardSelector } from "../../DashboardSelector/DashboardSelector";

type FiltersProps = {
  filters: Filters;
  onChange: (newFilters: Filters) => void;
};

export const ProtocolsFilters = ({ filters, onChange }: FiltersProps) => {
  const styles = useStyles();

  const renderRatingOptions = () => {
    const ratingOptions = [
      {
        value: "all",
        label: "All ratings",
      },
      {
        value: "S",
        label: "S only",
      },
      {
        value: "AA",
        label: "AA or higher",
      },
      {
        value: "A",
        label: "A or higher",
      },
    ];

    return ratingOptions.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <FormGroup className={styles.controls}>
      <DashboardSelector />
      <SearchField
        placeholder="Search protocols..."
        onChange={(event) => onChange({ ...filters, name: event.target.value })}
        value={filters.name}
      />
      <FormControl className={styles.formControl}>
        <Select
          labelId="rating-select"
          value={filters.rating}
          onChange={(event) =>
            onChange({ ...filters, rating: event.target.value as Rating })
          }
          inputProps={{
            className: styles.select,
          }}
          input={<SelectInput />}
        >
          {renderRatingOptions()}
        </Select>
      </FormControl>
    </FormGroup>
  );
};
