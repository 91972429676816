import KPI from "components/KPI/KPI";
import { useContext } from "react";
import { ActionPanelContext } from "../ActionPanelContext";

export const NoPositionContent = () => {
  const { loading } = useContext(ActionPanelContext);
  return (
    <KPI
      label="My Position"
      value="None"
      footnote={
        <>
          You don't have a position in this Ante Pool. <em>Stake</em> to support
          or <em>Challenge</em> to doubt the protocol being tested.
        </>
      }
      isLoaded={!loading}
    />
  );
};
