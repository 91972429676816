import { Button, Grid, Typography } from "@material-ui/core";
import CancelWithdrawalModal from "pages/AnteTestDetail/modals/CancelWithdrawModal";
import { useContext, useState } from "react";
import { useSelectTxnPending } from "state/selectors";
import WithdrawStakeModal from "pages/AnteTestDetail/modals/WithdrawStakeModal";
import CountdownTimer from "../../countdownTimer";
import { ActionPanelContext } from "../ActionPanelContext";

type StakedPositionUnlockedActionsProps = {
  unlocking: boolean;
  currentTimestamp: number;
  secondsUntilUnlock: number;
};

export const StakedPositionUnlockedActions = ({
  unlocking,
  currentTimestamp,
  secondsUntilUnlock,
}: StakedPositionUnlockedActionsProps) => {
  const { submitting } = useContext(ActionPanelContext);
  const [withdrawStakeVisible, setWithdrawStakeVisible] = useState(false);
  const [cancelWithdrawVisible, setCancelWithdrawVisible] = useState(false);

  const handleWithdrawStakeOpen = () => {
    setWithdrawStakeVisible(true);
  };

  const isTxnPending = useSelectTxnPending();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleWithdrawStakeOpen}
          fullWidth
          disabled={unlocking || submitting || isTxnPending}
        >
          Withdraw Stake
        </Button>

        <WithdrawStakeModal
          isShown={withdrawStakeVisible}
          hide={() => {
            setWithdrawStakeVisible(false);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setCancelWithdrawVisible(true);
          }}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Cancel Withdraw
        </Button>
        {unlocking && (
          <Typography
            variant="caption"
            component="div"
            style={{
              color: "#777777",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            <span>Available in </span>
            {currentTimestamp && (
              <CountdownTimer duration={secondsUntilUnlock} />
            )}
          </Typography>
        )}
        <CancelWithdrawalModal
          isShown={cancelWithdrawVisible}
          hide={() => {
            setCancelWithdrawVisible(false);
          }}
        />
      </Grid>
    </Grid>
  );
};
