import React, { useState, useEffect, useContext } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { parseEther, formatUnits } from "@ethersproject/units";

import { TOKEN_CAP } from "utils/constants";
import { useWallet } from "utils/wallet";
import StakeInput from "components/StakeInput";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";
import { formatTokenValue, getNetworkById } from "utils/utils";

interface ChallengeModalProps {
  isShown: boolean;
  hide: () => void;
}

const ChallengeModal = ({ isShown, hide }: ChallengeModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);

  const chain = getNetworkById(pool.chainId);
  const token = chain.token;
  const totalStaked = pool.stakingInfo.totalAmount.add(
    pool.totalPendingWithdraw
  );
  const totalChallenged = pool.challengerInfo.totalAmount;
  const [challengeAmount, setChallengeAmount] = useState(0);
  const [helperText, setHelperText] = useState("");
  const [errorEntry, setErrorEntry] = useState(false);
  const [firstEntry, setFirstEntry] = useState(false);
  const [potentalReward, setPotentialReward] = useState(0);

  const { balance } = useWallet();

  useEffect(() => {
    if (isShown && challengeAmount) {
      const challengeAmountWei = parseEther(
        challengeAmount.toFixed(chain.decimals)
      );
      try {
        const reward = totalStaked
          .mul(challengeAmountWei)
          .div(totalChallenged.add(challengeAmountWei))
          .mul(19)
          .div(20);
        setPotentialReward(Number(formatUnits(reward)));
      } catch (e) {
        console.error(e);
      }
    }
  }, [
    totalStaked,
    totalChallenged,
    challengeAmount,
    setPotentialReward,
    isShown,
    chain.decimals,
  ]);

  const handleInputChange = (value: string) => {
    setChallengeAmount(Number(value));
    setFirstEntry(true);
  };

  const validateSubmit = async (event) => {
    if (
      challengeAmount < TOKEN_CAP[pool.chainId.toLowerCase()]?.min ||
      challengeAmount > TOKEN_CAP[pool.chainId.toLowerCase()]?.max
    ) {
      console.warn("INVALID: ", challengeAmount);
      setErrorEntry(true);
    } else if (challengeAmount * 10 ** chain.decimals > balance.number) {
      console.warn("INVALID: ", challengeAmount, " > ", balance.number);
      setErrorEntry(true);
    } else {
      setErrorEntry(false);
      const challengeAmountWei = parseEther(
        challengeAmount.toFixed(chain.decimals)
      );

      setSubmitting(true);
      try {
        await pool.challenge(challengeAmountWei);
        hide();
        // trigger success popup
      } catch (error: any) {
        console.error(error);
        window.rollbar.warn("ChallengeModal:validateSubmit", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (challengeAmount * 10 ** chain.decimals > balance.number) {
      setHelperText(
        "Amount is greater than wallet balance: " + balance.formattedString
      );
      setErrorEntry(true);
    } else if (
      challengeAmount < TOKEN_CAP[pool.chainId.toLowerCase()]?.min ||
      challengeAmount > TOKEN_CAP[pool.chainId.toLowerCase()]?.max
    ) {
      setHelperText(
        challengeAmount < TOKEN_CAP[pool.chainId.toLowerCase()]?.min
          ? `Amount must be greater than ${formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.min.toString(),
              token
            )}`
          : `Amount must be less than ${formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
              token
            )}`
      );
      setErrorEntry(firstEntry);
    } else {
      setHelperText("");
      setErrorEntry(false);
    }
  }, [
    balance,
    challengeAmount,
    firstEntry,
    token,
    pool.chainId,
    chain.decimals,
  ]);

  return (
    <div>
      <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
        <DialogTitle>Challenge Ante Test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" display="block" gutterBottom>
              Enter the amount you would like to challenge (min{" "}
              {formatTokenValue(
                TOKEN_CAP[pool.chainId.toLowerCase()]?.min.toString(),
                token
              )}
              , up to{" "}
              {formatTokenValue(
                TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
                token
              )}{" "}
              total)
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <StakeInput
              id="challenge-amount"
              onChange={handleInputChange}
              helperText={helperText}
              error={errorEntry}
              isChallenge
              chain={getNetworkById(pool.chainId)}
            />
          </DialogContentText>
          <DialogContentText>
            <Typography variant="subtitle1" display="block" gutterBottom>
              <strong>Estimated Decay and Potential Earnings</strong>
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Your challenge balance will undergo decay and decrease by roughly{" "}
              <strong>20%</strong> per year.{" "}
              {totalStaked.eq(0) ? (
                "Since there is no staked balance at the moment, there are no rewards at the current time."
              ) : (
                <>
                  <span>
                    Based on your proposed challenge amount and the current
                    stake and challenge balances, if the test were found to
                    fail, you could earn{" "}
                  </span>
                  <Tooltip
                    title={formatTokenValue(potentalReward.toFixed(16), token)}
                    placement="top"
                    arrow
                  >
                    <strong>
                      {formatTokenValue(potentalReward.toFixed(3), token)}
                    </strong>
                  </Tooltip>
                  .
                </>
              )}
            </Typography>
            <Typography
              variant="body2"
              display="block"
              color="textSecondary"
              gutterBottom
            >
              Note: You must hold your challenge for at least 12 blocks before
              you can call Check Test.
            </Typography>
            <Typography
              variant="body2"
              display="block"
              color="textSecondary"
              gutterBottom
            >
              *Total {token} cannot exceed{" "}
              {formatTokenValue(
                TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
                token
              )}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button
            onClick={validateSubmit}
            variant="contained"
            color="primary"
            disabled={submitting || errorEntry || challengeAmount === 0}
          >
            {submitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChallengeModal;
