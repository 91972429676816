import { configureStore } from "@reduxjs/toolkit";
import antePoolsReducer from "state/antepools/antepools";
import createPoolReducer from "state/createPool/createPool";
import localizationReducer from "state/localization";

const store = configureStore({
  reducer: {
    pools: antePoolsReducer,
    createPool: createPoolReducer,
    localization: localizationReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
