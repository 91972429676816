import { queryResolver } from "db/graphql-query-resolver";
import { GET_PROTOCOLS_NODE, PROTOCOLS_ACCESSOR } from "db/queries/protocol";
import { Exact, Scalars } from "db/queries/types";
import { UseQueryOptions, useQuery, QueryKey } from "react-query";
import { Protocol } from "types";

export type GetProtocolsQuery = {
  [key in typeof PROTOCOLS_ACCESSOR]?: Array<Protocol>;
};

export type GetProtocolsQueryVariables = Exact<{
  isVisible?: Scalars["Boolean"];
}>;

export const useProtocols = <TData = GetProtocolsQuery, TError = unknown>(
  variables?: GetProtocolsQueryVariables,
  options?: UseQueryOptions<GetProtocolsQuery, TError, TData>
) => {
  variables = {
    ...variables,
    isVisible: true,
  };
  const query = GET_PROTOCOLS_NODE;

  const key = [query] as QueryKey;
  const { data, isLoading } = useQuery(
    key,
    () => queryResolver(query, variables),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      ...options,
    }
  );

  return {
    protocols: data?.[PROTOCOLS_ACCESSOR],
    loading: isLoading,
  };
};
