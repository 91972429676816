import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";

import { useWallet } from "utils/wallet";
import { Chain, GAS_BUFFER, TOKEN_CAP } from "utils/constants";
import { makeStyles } from "@material-ui/core";
import colors from "style/colors";
import { formatTokenValue } from "utils/utils";

const useStyles = makeStyles({
  linkText: {
    color: colors.linkBlue,
    fontWeight: 600,
    cursor: "pointer",
    marginLeft: "4px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

type StakeInputProps = {
  id: string;
  helperText: string;
  error: boolean;
  onChange: (value: string) => void;
  chain: Chain;
  isChallenge?: boolean;
  currentBalance?: BigNumber;
};

const StakeInput = ({
  id,
  onChange,
  helperText,
  error,
  chain,
  isChallenge = false,
  currentBalance = BigNumber.from(0),
}: StakeInputProps) => {
  const [inputValue, setInputValue] = useState(isChallenge ? "0.00" : "0.000");
  const { balance } = useWallet();
  const styles = useStyles();
  const currentBalanceEther = Number(formatUnits(currentBalance));

  const setMaxInput = () => {
    const walletBalance = Number(formatUnits(balance.number.toString(), 18));
    const maxInput =
      walletBalance + currentBalanceEther - GAS_BUFFER > TOKEN_CAP[chain.id].max
        ? TOKEN_CAP[chain.id].max - currentBalanceEther
        : Math.max(walletBalance - GAS_BUFFER, 0);

    setInputValue(maxInput.toString());
    onChange(maxInput.toString());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const decimalsCount =
      event.target.value.split(".")?.[1]?.length ??
      event.target.value.split(",")?.[1]?.length ??
      0;

    const numericValue =
      decimalsCount > chain.decimals
        ? Number(event.target.value).toFixed(chain.decimals)
        : event.target.value;
    setInputValue(numericValue);
    onChange(numericValue);
  };

  return (
    <div>
      <Typography variant="body2" display="inline">
        Available funds:{" "}
        {formatTokenValue(balance.formattedString, chain.token)}
      </Typography>
      <Typography
        onClick={setMaxInput}
        className={styles.linkText}
        variant="body2"
        display="inline"
      >
        (Max)
      </Typography>
      <TextField
        required
        autoFocus
        id={id}
        label={`Amount (${chain.token})`}
        variant="filled"
        type="number"
        defaultValue={isChallenge ? "0.00" : "0.000"}
        value={inputValue}
        fullWidth
        onChange={handleInputChange}
        onFocus={(evt) => evt.target.select()}
        helperText={helperText}
        error={error}
        inputProps={{
          min: 0,
          step: 0.1,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{chain.token}</InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default StakeInput;
