import React, { useContext, useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { parseEther } from "@ethersproject/units";

import { TOKEN_CAP } from "utils/constants";
import { useWallet } from "utils/wallet";
import StakeInput from "components/StakeInput";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";
import { formatTokenValue, getNetworkById } from "utils/utils";

interface StakeModalProps {
  isShown: boolean;
  hide: () => void;
}

const StakeModal: React.FC<StakeModalProps> = ({ isShown, hide }) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);
  const chain = getNetworkById(pool.chainId);
  const token = chain.token;
  const [stakeAmount, setStakeAmount] = useState(0);
  const [helperText, setHelperText] = useState("");
  const [errorEntry, setErrorEntry] = useState(false);
  const [firstEntry, setFirstEntry] = useState(false);

  const handleInputChange = (value: string) => {
    setStakeAmount(Number(value));
    setFirstEntry(true);
  };

  const { balance } = useWallet();

  const validateSubmit = async () => {
    if (
      stakeAmount <= 0 ||
      stakeAmount > TOKEN_CAP[pool.chainId.toLowerCase()]?.max
    ) {
      console.warn("INVALID: ", stakeAmount);
      setErrorEntry(true);
    } else if (stakeAmount * 10 ** chain.decimals > balance.number) {
      console.warn(
        "INVALID: ",
        stakeAmount * 10 ** chain.decimals,
        " > ",
        balance.number
      );
      setErrorEntry(true);
    } else {
      setErrorEntry(false);

      const stakeAmountWei = parseEther(stakeAmount.toFixed(chain.decimals));
      setSubmitting(true);
      try {
        await pool.stake(stakeAmountWei);
        hide();
        // trigger success popup
      } catch (e: any) {
        console.error(e);
        window.rollbar.warn("StakeModal:validateSubmit", e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (stakeAmount * 10 ** chain.decimals > balance.number) {
      setHelperText(
        "Amount is greater than wallet balance: " + balance.formattedString
      );
      setErrorEntry(true);
    } else if (
      stakeAmount <= 0 ||
      stakeAmount > TOKEN_CAP[pool.chainId.toLowerCase()]?.max
    ) {
      setHelperText(
        stakeAmount <= 0
          ? `Amount must be greater than ${formatTokenValue("0", token)}`
          : `Amount must be less than ${formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(1),
              token
            )}`
      );
      setErrorEntry(firstEntry);
    } else {
      setHelperText("");
      setErrorEntry(false);
    }
  }, [balance, firstEntry, stakeAmount, token, pool.chainId, chain.decimals]);

  return (
    <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
      <DialogTitle>Stake Ante Test</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" display="block" gutterBottom>
            Enter the amount you would like to stake (up to{" "}
            {formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(3),
              token
            )}{" "}
            total)
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <StakeInput
            id="stake-amount"
            onChange={handleInputChange}
            helperText={helperText}
            error={errorEntry}
            chain={getNetworkById(pool.chainId)}
          />
        </DialogContentText>
        <DialogContentText>
          <Typography variant="subtitle1" display="block" gutterBottom>
            <strong>Staker Rewards</strong>
          </Typography>
          <Typography variant="body1" display="block" gutterBottom>
            Stakers can earn between <strong>1–2%</strong> per year proportional
            to their share of the total amount staked and challenged. Rewards
            can be claimed by withdrawing your stake. However, if the test
            fails, you lose your stake, including any unclaimed rewards.
          </Typography>
          <Typography
            variant="body2"
            display="block"
            color="textSecondary"
            gutterBottom
          >
            Note: Stakes must be held for at least 1 day before withdrawing.
          </Typography>
          <Typography
            variant="body2"
            display="block"
            color="textSecondary"
            gutterBottom
          >
            *Total {token} cannot exceed{" "}
            {formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(1),
              token
            )}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Cancel
        </Button>
        <Button
          onClick={validateSubmit}
          variant="contained"
          color="primary"
          disabled={submitting || errorEntry || stakeAmount === 0}
        >
          {submitting ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StakeModal;
