import { Pool, Protocol } from "types";
import { BigNumber } from "ethers";
import { formatEther, parseEther } from "ethers/lib/utils";
import {
  calculateTrustScore,
  getBadges,
  getFailedBlockTimestamp,
  updateStakerAmounts,
} from "utils/antepools/utils";
import {
  convertTokenToCurrency,
  convertTokenToFiat,
  formatFiatValue,
  getNetworkById,
  formatTokenValue,
  toLocaleNumber,
  getUnderlyingAssetsTooltipFromChainStats,
} from "utils/utils";
import { ProtocolTestRowProps } from "./useColumns";
import { CellValueType } from "components/AnteTable/cells";
import { PoolBalancesMap } from "types";
import { KPIProps } from "components/KPI/KPI";
import { TokenPrice } from "hooks/useFetchTokenPrice";
import {
  BASE_CHAIN,
  Chain,
  SupportedChainId,
  SUPPORTED_CURRENCIES,
} from "utils/constants";

export type ProtocolChainStatsMap = Record<
  SupportedChainId,
  ProtocolChainStats
>;

export type ProtocolStats = {
  aggregateTrustScore: number;
  totalTvlInEth: number;
  stakedTotalInEth: number;
  statsByChain: ProtocolChainStatsMap;
};

export type ProtocolChainStats = {
  totalTvl: number;
  totalStakedOverall: number;
  totalStakedByProtocol: number;
  totalChallenged: number;
  isTvlGteThreshold: boolean;
  preFailureTotalTvl: number;
};

export const calculateAggregateProtocolStats = async (
  pools: Pool[],
  prices: TokenPrice
): Promise<ProtocolStats> => {
  const statsByChain: ProtocolChainStatsMap = {};
  for (const pool of pools) {
    if (!pool.chainId) {
      continue;
    }
    if (!statsByChain.hasOwnProperty(pool.chainId)) {
      statsByChain[pool.chainId] = {
        isTvlGteThreshold: false,
        totalChallenged: 0,
        totalTvl: 0,
        totalStakedByProtocol: 0,
        totalStakedOverall: 0,
        preFailureTotalTvl: 0,
      };
    }

    statsByChain[pool.chainId].isTvlGteThreshold =
      statsByChain[pool.chainId].isTvlGteThreshold || pool.isTvlGteThreshold;
    // Exclude AVL from failed pools
    statsByChain[pool.chainId].totalTvl += pool.pendingFailure ? 0 : pool.tvl;
    // Exclude protocol stake from failed pools
    statsByChain[pool.chainId].totalStakedByProtocol += pool.stakedByProtocol;
    statsByChain[pool.chainId].totalChallenged += pool.pendingFailure
      ? 0
      : pool.challengedTotalAmount;
    statsByChain[pool.chainId].totalStakedOverall += pool.pendingFailure
      ? 0
      : pool.stakedAndPendingWithdraw;

    // Increase pre-failed tvl only if the failure occurred in the last 3 months
    const failedTimestamp = await getFailedBlockTimestamp(pool);
    if (
      failedTimestamp !== undefined &&
      failedTimestamp > Date.now() / 1000 - 90 * 24 * 3600
    ) {
      statsByChain[pool.chainId].preFailureTotalTvl += pool.tvl;
    }
  }

  let stakedTotalInEth = 0;
  let totalTvlInEth = 0;
  let preFailureTotalTvlInEth = 0;

  for (let chainId of Object.keys(statsByChain)) {
    stakedTotalInEth += convertTokenToCurrency(
      chainId,
      statsByChain[chainId].totalStakedOverall,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
    totalTvlInEth += convertTokenToCurrency(
      chainId,
      statsByChain[chainId].totalTvl,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
    preFailureTotalTvlInEth += convertTokenToCurrency(
      chainId,
      statsByChain[chainId].preFailureTotalTvl,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
  }

  return {
    statsByChain,
    totalTvlInEth,
    stakedTotalInEth,
    aggregateTrustScore:
      totalTvlInEth && stakedTotalInEth
        ? calculateTrustScore(
            parseEther(`${totalTvlInEth.toFixed(18)}`),
            parseEther(`${stakedTotalInEth.toFixed(18)}`),
            parseEther(`${preFailureTotalTvlInEth.toFixed(18)}`)
          )
        : NaN,
  };
};

export const getFormattedAntePools = (
  pools: Pool[],
  protocol: Protocol
): Pool[] => {
  return pools.map((pool) => ({
    ...pool,
    stakedByProtocol: (protocol?.verifiedAddresses ?? []).reduce(
      (prev: number, protocolAddr) => {
        const stakedAmount =
          pool.verifiedStakers.find(
            (stakerInfo) => stakerInfo.address === protocolAddr
          )?.amount ?? "0";
        return (prev += Number(formatEther(BigNumber.from(stakedAmount))));
      },
      0
    ),
  }));
};

export const getDerivedPools = (
  pools: Array<Pool>,
  balances: PoolBalancesMap
): Array<Pool> => {
  return (
    pools?.map((pool) => {
      const { stakerBalances } = balances[pool.antePoolAddress] ?? {};
      if (pool.verifiedStakers === undefined)
        return { ...pool, verifiedStakers: [] };

      if (
        pool.verifiedStakers !== undefined &&
        (stakerBalances === undefined ||
          Object.entries(stakerBalances).length === 0)
      ) {
        return pool;
      }

      return {
        ...pool,
        verifiedStakers: updateStakerAmounts(
          pool.verifiedStakers,
          stakerBalances
        ),
      };
    }) ?? []
  );
};
export const getKPIs = (
  protocolName: string,
  statsByChain: ProtocolChainStatsMap,
  prices: TokenPrice,
  aggregateTrustScore: number
): Array<KPIProps> => {
  let totalStakedByProtocolInEth = 0;
  let totalTvlInEth = 0;
  for (let chainId of Object.keys(statsByChain)) {
    totalStakedByProtocolInEth += convertTokenToCurrency(
      chainId,
      statsByChain[chainId].totalStakedByProtocol,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
    totalTvlInEth += convertTokenToCurrency(
      chainId,
      statsByChain[chainId].totalTvl,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
  }

  return [
    {
      tooltip: getUnderlyingAssetsTooltipFromChainStats(
        statsByChain,
        "totalStakedByProtocol",
        prices
      ),
      label: `Staked by ${protocolName}`,
      value: formatTokenValue(
        isNaN(totalStakedByProtocolInEth)
          ? "-"
          : totalStakedByProtocolInEth.toFixed(3),
        SUPPORTED_CURRENCIES.eth.symbol
      ),
      footnote: formatFiatValue(
        toLocaleNumber(
          convertTokenToFiat(BASE_CHAIN.id, totalStakedByProtocolInEth, prices)
        )
      ),
    },
    {
      tooltip: getUnderlyingAssetsTooltipFromChainStats(
        statsByChain,
        "totalTvl",
        prices
      ),
      label: "Total Ante Value Locked",
      value: formatTokenValue(
        isNaN(totalTvlInEth) ? "-" : totalTvlInEth.toFixed(3),
        SUPPORTED_CURRENCIES.eth.symbol
      ),
      footnote: formatFiatValue(
        toLocaleNumber(convertTokenToFiat(BASE_CHAIN.id, totalTvlInEth, prices))
      ),
    },
    {
      label: "Protocol Trust Score",
      value: isNaN(aggregateTrustScore) ? "-" : aggregateTrustScore.toFixed(2),
      footnote: null,
    },
  ];
};

const getTvlValue = (
  totalTvl: number,
  prices: TokenPrice,
  chain?: Chain
): CellValueType => {
  return {
    numericValue: chain ? convertTokenToFiat(chain.id, totalTvl, prices) : 0,
    value: formatTokenValue(
      isNaN(totalTvl) ? "-" : totalTvl.toFixed(3),
      chain?.token
    ),
    subValue:
      chain === undefined || isNaN(totalTvl)
        ? "-"
        : formatFiatValue(
            toLocaleNumber(convertTokenToFiat(chain.id, totalTvl, prices))
          ),
  };
};

const failedTvlCell: CellValueType = {
  numericValue: 0,
  value: "-",
  subValue: null,
};

export const getTestsRows = (
  pools: Pool[],
  prices: TokenPrice
): Array<ProtocolTestRowProps> => {
  return pools.map((pool) => {
    const {
      anteTestName,
      anteTestAddress,
      antePoolAddress,
      chainId,
      stakedByProtocol,
      pendingFailure,
      tvl,
      isTvlGteThreshold,
      trustScore,
      stakedAndPendingWithdraw,
      challengedTotalAmount,
    } = pool;

    const chain = getNetworkById(chainId);
    return {
      name: {
        name: anteTestName,
        poolAddress: antePoolAddress,
        address: anteTestAddress,
        badges: getBadges(pool),
      },
      address: anteTestAddress,
      chain: chain,
      totalStakedByProtocol: getTvlValue(
        stakedByProtocol ?? NaN,
        prices,
        chain
      ),
      totalValueLocked: pendingFailure
        ? failedTvlCell
        : getTvlValue(tvl ?? NaN, prices, chain),
      trustScore: {
        isTvlGteThreshold,
        trustScore: pendingFailure ? 0 : trustScore,
        totalStakedOverall: chainId
          ? {
              [chainId]: stakedAndPendingWithdraw,
            }
          : {},
        totalChallenged: chainId
          ? {
              [chainId]: challengedTotalAmount,
            }
          : {},
        hasPoolFailed: pendingFailure,
      },
    };
  });
};
