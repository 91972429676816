import { SourceCode } from "types";
import { SupportedChainId } from "utils/constants";
import { useSourceCodes } from "./useSourceCodes";

type SourceCodeResponse = {
  sourceCode: SourceCode;
  loading: boolean;
};

export const useSourceCode = (
  address: string,
  chainId: SupportedChainId
): SourceCodeResponse => {
  const { sourceCodes, loading } = useSourceCodes({
    addresses: [address],
    chainId,
  });

  return {
    sourceCode: sourceCodes?.[0],
    loading: loading,
  };
};
