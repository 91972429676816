import React, { useCallback, useEffect, useState } from "react";
import { bnToStringPrecision, getNetworkById } from "./utils";
import { sendEvent, setIdentify, AnalyticsEvents } from "analytics/analytics";
import { CHAINS, web3Onboard } from "utils/onboard/onboardUtils";
import { ConnectOptions, WalletState } from "@web3-onboard/core";
import { Storage } from "./storage";
import { BigNumber } from "@ethersproject/bignumber";
import { SupportedChainId, SUPPORTED_CHAINS } from "./constants";
import { ethers } from "ethers";

export interface WalletBalance {
  formattedString: string;
  number: number;
}

export interface WalletData {
  connectedWallet?: WalletState;
  account?: string;
  networkId?: SupportedChainId;
  networkName?: string;
  token?: string;
  balance?: WalletBalance;
  provider?: any;
  isNetworkSupported: boolean;
}

export interface Wallet extends WalletData {
  connect: VoidFunction;
  disconnect: VoidFunction;
  connecting: boolean;
  refreshBalance: VoidFunction;
  setChain: (chainId: string) => void;
}

const initialWalletContext: Wallet = {
  account: undefined,
  networkId: undefined,
  networkName: undefined,
  token: undefined,
  connect: () => undefined,
  disconnect: () => undefined,
  refreshBalance: () => undefined,
  setChain: () => undefined,
  connecting: false,
  provider: undefined,
  isNetworkSupported: false,
};

const WalletContext = React.createContext<Wallet>(initialWalletContext);

export function useWallet(): Wallet {
  return React.useContext(WalletContext);
}

const OnboardWallet: React.FunctionComponent = (props) => {
  if (!web3Onboard) throw new Error("Must initialize before using this hook.");

  const [connectedWallet, setConnectedWallet] = useState<WalletState | null>(
    undefined
  );
  const [connecting, setConnecting] = useState(false);
  const [formattedBalance, setFormattedBalance] = useState<
    WalletBalance | undefined
  >({
    formattedString: "0",
    number: 0,
  });

  const connect = React.useCallback(async (options?: ConnectOptions) => {
    setConnecting(true);

    await web3Onboard.connectWallet(options);

    setConnecting(false);
  }, []);

  const disconnect = React.useCallback(async () => {
    setConnecting(true);

    await web3Onboard.disconnectWallet({ label: connectedWallet?.label });

    setConnecting(false);
  }, [connectedWallet?.label]);

  const setChain = React.useCallback(
    async (chainId: string) => {
      if (!connectedWallet) return;

      await web3Onboard.setChain({
        chainId: chainId,
        wallet: connectedWallet?.label,
      });
    },
    [connectedWallet]
  );

  const refreshBalance = useCallback(async () => {
    if (connectedWallet?.provider === undefined) return;

    const web3 = new ethers.providers.Web3Provider(connectedWallet.provider);
    const unformatted = await web3.getBalance(
      connectedWallet.accounts?.[0].address
    );
    const formatted = bnToStringPrecision(BigNumber.from(unformatted));

    setFormattedBalance({
      formattedString: formatted,
      number: Number(unformatted),
    });
  }, [connectedWallet]);

  useEffect(() => {
    const formatBalance = (balance?: string): WalletBalance | undefined => {
      if (typeof balance === "undefined" || balance === null)
        return {
          formattedString: "0",
          number: 0,
        };

      return {
        formattedString: Number(balance).toFixed(3),
        number: Number(balance) * 1e18,
      };
    };

    const walletsSub = web3Onboard.state.select("wallets");
    const subscription = walletsSub.subscribe(async (wallets) => {
      let wallet = wallets?.[0];
      const chainId = wallet?.chains?.[0]?.id;

      setConnectedWallet(wallet);
      setFormattedBalance(
        formatBalance(wallet?.accounts?.[0].balance?.[CHAINS[chainId].token])
      );
      Storage.setConnectedWallet(wallet?.label);
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const previouslyConnectedWallet = Storage.getConnectedWallet();

    if (previouslyConnectedWallet) {
      // Connect "silently" and disable all onboard modals to avoid them flashing on page load
      connect({
        autoSelect: { label: previouslyConnectedWallet, disableModals: true },
      });
    }
  }, [connect]);

  useEffect(() => {
    const account = connectedWallet?.accounts?.[0].address;
    if (account) {
      setIdentify("account", account);
      sendEvent(AnalyticsEvents.walletConnected, { account: account });
    }
  }, [connectedWallet?.accounts]);

  const value = React.useMemo<Wallet>(() => {
    const networkId = connectedWallet?.chains?.[0]?.id;

    return {
      connectedWallet: connectedWallet,
      account: connectedWallet?.accounts?.[0]?.address,
      networkId: networkId,
      networkName: networkId ? getNetworkById(networkId)?.label : undefined,
      token: getNetworkById(networkId)?.token,
      isNetworkSupported:
        connectedWallet?.accounts?.[0]?.address &&
        Object.values(SUPPORTED_CHAINS)
          .map((chain) => chain.id.toLowerCase())
          .includes(networkId.toLowerCase()),
      provider: connectedWallet?.provider,
      connect,
      disconnect,
      refreshBalance,
      balance: formattedBalance,
      connecting,
      setChain,
    };
  }, [
    connectedWallet,
    connect,
    disconnect,
    connecting,
    formattedBalance,
    refreshBalance,
    setChain,
  ]);

  return (
    <WalletContext.Provider value={value}>
      {props.children}
    </WalletContext.Provider>
  );
};

const Web3WalletProvider: React.FunctionComponent = (props) => {
  return <OnboardWallet>{props.children}</OnboardWallet>;
};

export default Web3WalletProvider;
