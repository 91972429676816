import Web3Onboard, { OnboardAPI } from "@web3-onboard/core";
import { Chain } from "@web3-onboard/common";
import injectedModule from "@web3-onboard/injected-wallets";
import AnteLogo from "assets/ante/ante-logo.svg";
import ledgerModule from "@web3-onboard/ledger";
import trezorModule from "@web3-onboard/trezor";
import gnosisModule from "@web3-onboard/gnosis";
import walletConnectModule from "@web3-onboard/walletconnect";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import {
  LATEST_APP_BASE_URL,
  SupportedChainId,
  SUPPORTED_CHAINS,
} from "utils/constants";
import type { WalletInit } from "@web3-onboard/common";

const email = "contact@ante.finance";

const ledger = ledgerModule();
const injected = injectedModule();
const trezor = trezorModule({
  email: email,
  appUrl: LATEST_APP_BASE_URL,
});
const coinbase = coinbaseWalletModule({ darkMode: true });

// We are forcing the cast to WalletInit in order to avoid a Typescript error
// The cast can be removed after OnboardJS has a new version for these connectors
const gnosis = gnosisModule() as WalletInit;
const walletConnect = walletConnectModule({
  qrcodeModalOptions: {
    mobileLinks: [
      "rainbow",
      "metamask",
      "argent",
      "trust",
      "imtoken",
      "pillar",
    ],
  },
}) as WalletInit;

export const CHAINS = Object.values(SUPPORTED_CHAINS).reduce(
  (prevResult, chain) => {
    prevResult[chain.id] = {
      id: chain.id,
      token: chain.token,
      label: chain.label,
      rpcUrl: chain.rpcUrl,
    };
    return prevResult;
  },
  {} as Record<SupportedChainId, Chain>
);

export let web3Onboard: OnboardAPI | null = null;

export const initOnboard = (): OnboardAPI => {
  web3Onboard = Web3Onboard({
    wallets: [injected, ledger, trezor, gnosis, walletConnect, coinbase],
    chains: Object.values(CHAINS),
    appMetadata: {
      name: "Ante",
      icon: AnteLogo,
      description: LATEST_APP_BASE_URL,
    },
    accountCenter: {
      desktop: {
        enabled: false,
      },
      mobile: {
        enabled: false,
      },
    },
  });
  return web3Onboard;
};
