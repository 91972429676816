import { Variables } from "db/graphql-fetcher";
import { DocumentNode } from "graphql";
import { GetAntePoolQuery } from "hooks/antepools/useAntePool";
import { QueryKey } from "react-query";
import { Pool } from "types";
import queryClient from "utils/queryClient";
import { WalletData } from "utils/wallet";

const offChainKeysPrefix = ["pools", "offChain"];

const offChainKeys = (query: DocumentNode, variables?: Variables) => [
  ...offChainKeysPrefix,
  query,
  variables,
];

const keys = {
  all: (withDetails: boolean = false, walletData?: Partial<WalletData>) => [
    "pools",
    "onChain",
    withDetails,
    walletData?.account,
    walletData?.provider?.chainId,
  ],

  filtered: (
    withDetails: boolean = false,
    walletData?: Partial<WalletData>,
    filters?: Record<string, any>
  ) =>
    [...keys.all(withDetails, walletData), "filtered", filters].filter(
      (value) => typeof value !== "undefined"
    ),

  single: (
    withDetails: boolean = false,
    address: string,
    walletData?: Partial<WalletData>
  ) => [...keys.all(withDetails, walletData), "single", address],
};

const updateOrInvalidateCache = (key: QueryKey, updater: any) => {
  const newData = queryClient.setQueryData(key, updater);
  if (newData === undefined) {
    queryClient.invalidateQueries({
      queryKey: key,
      exact: true,
      refetchActive: false,
    });
  }
};

const updateOrInvalidateWildcardCache = (key: QueryKey, updater: any) => {
  try {
    const updates = queryClient.setQueriesData(key, updater);
    for (const update of updates) {
      const [key, newData] = update;
      if (newData === undefined) {
        queryClient.invalidateQueries({
          queryKey: key,
          refetchActive: false,
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const setPool = (newPool: Pool, walletData?: Partial<WalletData>) => {
  const updater = (pools: Array<Pool>) => {
    if (pools === undefined) return undefined;
    const newPools = [...pools];
    const index = newPools.findIndex(
      (oldPool) => oldPool.antePoolAddress === newPool.antePoolAddress
    );
    if (index > -1) {
      newPools[index] = newPool;
    } else {
      newPools.push(newPool);
    }
    return newPools;
  };

  const replacer = (pools: Array<Pool>) => {
    if (pools === undefined) return undefined;
    const index = pools.findIndex(
      (oldPool) => oldPool.antePoolAddress === newPool.antePoolAddress
    );
    if (index > -1) {
      const newPools = [...pools];
      newPools[index] = newPool;
      return newPools;
    }

    return undefined;
  };

  const offChainReplacer = (response: Pool[] | GetAntePoolQuery) => {
    if (response === undefined) return undefined;
    return Array.isArray(response) ? replacer(response as Pool[]) : response;
  };

  updateOrInvalidateCache(keys.all(true, walletData), updater);
  updateOrInvalidateCache(keys.all(false, walletData), updater);

  queryClient.setQueryData(
    keys.single(true, newPool.antePoolAddress, walletData),
    newPool
  );
  queryClient.setQueryData(
    keys.single(false, newPool.antePoolAddress, walletData),
    newPool
  );

  updateOrInvalidateWildcardCache(keys.filtered(true, walletData), replacer);
  updateOrInvalidateWildcardCache(keys.filtered(false, walletData), replacer);

  updateOrInvalidateWildcardCache(offChainKeysPrefix, offChainReplacer);
};

export const PoolsCache = {
  offChainKeys,
  keys,
  setPool,
};
