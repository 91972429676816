import { useContext } from "react";
import { ActionPanelContext } from "../ActionPanelContext";
import { StakedPositionLockedActions } from "./StakedPositionLockedActions";
import { StakedPositionUnlockedActions } from "./StakedPositionUnlockedActions";

type StakedPositionActionsProps = {
  unlocking: boolean;
  currentTimestamp: number;
  secondsUntilUnlock: number;
};

export const StakedPositionActions = ({
  unlocking,
  currentTimestamp,
  secondsUntilUnlock,
}: StakedPositionActionsProps) => {
  const { pool } = useContext(ActionPanelContext);

  const locked =
    pool.stakedBalanceUnit?.gt(0) && pool.pendingWithdrawAmount?.eq(0);

  if (locked) {
    return <StakedPositionLockedActions />;
  }
  return (
    <StakedPositionUnlockedActions
      unlocking={unlocking}
      currentTimestamp={currentTimestamp}
      secondsUntilUnlock={secondsUntilUnlock}
    />
  );
};
