import { Card, CardActions, CardContent, Grid } from "@material-ui/core";
import { useLeaderboardStyles } from "pages/Leaderboard";
import cn from "classnames";
import {
  cardStyles as useCardStyles,
  containerStyles as useContainerStyles,
} from "style/styles";
import { useStyles } from "./AnteTestDetail.styles";
import { Skeleton } from "@material-ui/lab";

export const SkeletonAnteTestDetail = () => {
  const leaderboardStyles = useLeaderboardStyles();
  const cardStyles = useCardStyles();
  const styles = useStyles();
  const containerStyles = useContainerStyles();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={8}
        lg={8}
        xl={9}
        className={leaderboardStyles.tableContainer}
      >
        <Card className={cn(cardStyles.root, styles.root)}>
          <CardContent>
            {/* header skeleton */}
            <div>
              <Skeleton animation="wave" width={120} height={26} />
              <Skeleton
                animation="wave"
                height={36}
                style={{ marginBottom: "8px" }}
              />
              <Skeleton
                animation="wave"
                width={200}
                height={20}
                style={{ marginBottom: "8px" }}
              />
              <Skeleton
                animation="wave"
                width={160}
                height={20}
                style={{ marginBottom: "24px" }}
              />
            </div>
            {/* kpi skeleton */}
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={6} lg={6}>
                <Skeleton
                  animation="wave"
                  width={80}
                  height={16}
                  style={{ marginBottom: "4px" }}
                />
                <Skeleton animation="wave" width={120} height={24} />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Skeleton
                  animation="wave"
                  width={80}
                  height={16}
                  style={{ marginBottom: "4px" }}
                />
                <Skeleton animation="wave" width={120} height={24} />
              </Grid>
            </Grid>
            {/* tab skeleton */}
            <div style={{ display: "flex", gap: "24px", marginBottom: "16px" }}>
              <Skeleton animation="wave" width={120} height={48} />
              <Skeleton animation="wave" width={120} height={48} />
              <Skeleton animation="wave" width={120} height={48} />
            </div>
            {/* content skeleton */}
            <div>
              <Skeleton variant="rect" animation="wave" height={320} />
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        lg={4}
        xl={3}
        className={leaderboardStyles.stats}
      >
        <Card
          className={cn(containerStyles.root, containerStyles.rainbowBorder)}
        >
          <CardContent>
            <Skeleton
              animation="wave"
              width={80}
              height={16}
              style={{ marginBottom: "4px" }}
            />
            <Skeleton animation="wave" width={120} height={32} />
            <Skeleton animation="wave" height={18} />
            <Skeleton animation="wave" height={18} />
          </CardContent>
          <CardActions>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Skeleton variant="rect" animation="wave" height={36} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="rect" animation="wave" height={36} />
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
