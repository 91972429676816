import { useCallback, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useLocalStorage, useSessionStorage } from "react-use-storage";

import KPICard from "components/KPICard/KPICard";

import { useWallet } from "utils/wallet";
import { getTotalKPIs, getUserKPIs } from "./components/TestsView/utils";
import { useLeaderboardStyles } from "./Leaderboard.styles";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import { ProtocolsView } from "./components/ProtocolsView";
import { TestsView } from "./components/TestsView";
import { LATEST_APP_BASE_URL, SupportedChainId } from "utils/constants";
import { getNetworkById } from "utils/utils";
import { useTypography } from "style/typography";
import { useGlobalStyles } from "utils/styles";

export type PoolChainTotals = Record<SupportedChainId, PoolTotals>;

export type PoolTotals = {
  staked?: number;
  challenged?: number;
  tvl?: number;
  count?: number;
};

const LeaderboardPage = () => {
  const { prices, loading: isEthPriceLoading } = useFetchTokenPrice();
  const wallet = useWallet();
  const connectedChain = getNetworkById(wallet.networkId);
  const styles = useLeaderboardStyles();
  const typography = useTypography();
  const globalStyles = useGlobalStyles();

  const [showFailed] = useLocalStorage("leaderboard_show_failed_tests", false);

  const [showProtocols] = useSessionStorage("leaderboard_show_protocols", true);

  const [poolsTotals, setPoolsTotals] = useState<PoolChainTotals>(undefined);

  const handleKPIsChange = useCallback(
    (totals: PoolChainTotals) => setPoolsTotals(totals),
    []
  );

  return (
    <Grid container className={styles.grid}>
      <Grid item sm={12} md={12} lg={9} className={styles.tableContainer}>
        <div className={styles.header}>
          <Typography variant="h4" display="block" className={styles.title}>
            Ante Leaderboard
          </Typography>
        </div>
        <div className={globalStyles.warningBox}>
          <Typography className={typography.bodySmall}>
            This only includes tests with Ante v0.5 pools. To see all tests, go
            to the&nbsp;
            <a href={LATEST_APP_BASE_URL} rel="noopener noreferrer">
              latest version of the app
            </a>
            .
          </Typography>
        </div>
        {showProtocols ? (
          <ProtocolsView onKPIsChange={handleKPIsChange} />
        ) : (
          <TestsView onKPIsChange={handleKPIsChange} />
        )}
      </Grid>
      <Grid item sm={12} md={12} lg={3} className={styles.stats}>
        {wallet.account && connectedChain && (
          <KPICard
            kpis={getUserKPIs(poolsTotals, prices, connectedChain)}
            isLoaded={poolsTotals !== undefined && !isEthPriceLoading}
          />
        )}
        <KPICard
          kpis={getTotalKPIs(poolsTotals, prices, showFailed)}
          isLoaded={poolsTotals !== undefined && !isEthPriceLoading}
        />
      </Grid>
    </Grid>
  );
};

export { LeaderboardPage };
