import { gql } from "graphql-tag";

export const SOURCE_CODE_FIELDS = gql`
  fragment SourceCodeFields on SourceCodeCollection {
    _id
    address
    sourceCode
    contractName
  }
`;
