import { useContext } from "react";
import { ActionPanelContext } from "../ActionPanelContext";
import { formatUnits as formatUnitsEthers } from "@ethersproject/units";
import {
  convertBNTokenToBNFiat,
  formatBNFiatValue,
  formatTokenValue,
  getTokenByChain,
} from "utils/utils";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import { BigNumber } from "@ethersproject/bignumber";
import KPI from "components/KPI/KPI";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { accordionStyles as useAccordionStyles } from "style/styles";

export const ChallengedPositionContent = () => {
  const accordionStyles = useAccordionStyles();
  const { pool, loading } = useContext(ActionPanelContext);

  const { prices } = useFetchTokenPrice();

  const {
    challengedStartAmount,
    challengedBalanceUnit,
    challengerInfo,
    stakingInfo,
    totalPendingWithdraw,
  } = pool;

  const decayLost = Number(
    formatUnitsEthers(challengedStartAmount.sub(challengedBalanceUnit))
  );
  const initialBalance = Number(formatUnitsEthers(challengedStartAmount));
  const currentBalanceETH = Number(formatUnitsEthers(challengedBalanceUnit));

  const usdValue = `${formatBNFiatValue(
    convertBNTokenToBNFiat(pool.chainId, challengedBalanceUnit, prices)
  )}`;

  const reward = challengerInfo?.totalAmount.gt(0)
    ? stakingInfo.totalAmount
        .add(totalPendingWithdraw)
        .mul(challengedBalanceUnit)
        .div(challengerInfo.totalAmount)
        .mul(19)
        .div(20)
    : BigNumber.from(0);
  const rewardUSD = `~${formatBNFiatValue(
    convertBNTokenToBNFiat(pool.chainId, reward, prices)
  )}`;
  const token = getTokenByChain(pool.chainId);
  return (
    <div>
      <KPI
        label="My Challenge Position"
        value={formatTokenValue(currentBalanceETH.toFixed(3), token)}
        footnote={usdValue}
        isLoaded={!loading}
      />
      <br />
      <KPI
        label="Potential Reward on Test Failure"
        value={`~${formatTokenValue(
          Number(formatUnitsEthers(reward)).toFixed(3),
          token
        )}`}
        footnote={rewardUSD}
        isLoaded={!loading}
      />

      <Accordion className={accordionStyles.root}>
        <AccordionSummary
          className={accordionStyles.root}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Challenge Breakdown</Typography>
        </AccordionSummary>
        <AccordionDetails className={accordionStyles.root}>
          <TableContainer>
            <Table size="small" aria-label="challenge breakdown">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Initial balance
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        initialBalance.toFixed(16),
                        token
                      )}
                      placement="top"
                      arrow
                    >
                      <span>{initialBalance.toFixed(3)}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Decay lost
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(decayLost.toFixed(16), token)}
                      placement="top"
                      arrow
                    >
                      <span>{decayLost.toFixed(3)}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Total balance
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        currentBalanceETH.toFixed(16),
                        token
                      )}
                      placement="top"
                      arrow
                    >
                      <span>
                        {formatTokenValue(currentBalanceETH.toFixed(3), token)}
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
