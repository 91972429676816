import { Container } from "@material-ui/core";
import Footer from "components/Footer/Footer";
import { TopBanner } from "components/TopBanner";
import TopBar from "components/TopBar/TopBar";
import V06Banner from "components/V06Banner/V06Banner";
import { Route, RouteProps } from "react-router-dom";
import { useLocalStorage } from "react-use-storage";
import { useStyles } from "./AppRoute.styles";

const AppRoute = ({ exact, path, component: Component }: RouteProps) => {
  const [showBanner, setShowBanner] = useLocalStorage(
    "show-ante-warning-banner",
    true
  );

  const styles = useStyles();
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        <div className={styles.root}>
          {showBanner && <TopBanner closeBanner={() => setShowBanner(false)} />}
          <V06Banner />
          <TopBar />
          <Container maxWidth="xl" className={styles.container}>
            <Component {...props} />
          </Container>
          <Footer />
        </div>
      )}
    />
  );
};
export { AppRoute };
