import { Typography } from "@material-ui/core";
import FailedImage from "assets/img/test-failed.svg";
import KPI from "components/KPI/KPI";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import { useContext } from "react";
import {
  bnToStringPrecision,
  convertBNTokenToBNFiat,
  formatBNFiatValue,
  formatTokenValue,
  getTokenByChain,
} from "utils/utils";
import { ActionPanelContext } from "../ActionPanelContext";

export const StakedContent = () => {
  const { pool, loading } = useContext(ActionPanelContext);

  const { prices } = useFetchTokenPrice();
  const { stakedBalanceUnit, pendingWithdrawAmount } = pool;

  const totalLocked = stakedBalanceUnit.add(pendingWithdrawAmount);

  return (
    <>
      <Typography variant="body1">
        The test failed! Your stake (including any pending withdrawal) has been
        locked and is no longer retrievable.
      </Typography>
      <Typography align="center">
        <img src={FailedImage} alt="fail" height="128" />
      </Typography>
      <KPI
        label="My Position (locked)"
        value={
          stakedBalanceUnit &&
          `${formatTokenValue(
            bnToStringPrecision(totalLocked),
            getTokenByChain(pool.chainId)
          )}`
        }
        footnote={
          stakedBalanceUnit &&
          `${formatBNFiatValue(
            convertBNTokenToBNFiat(pool.chainId, totalLocked, prices)
          )}`
        }
        isLoaded={!loading}
      />
    </>
  );
};
