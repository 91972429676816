import { AnteColumn } from "components/AnteTable";
import { Row } from "react-table";
import { TrustScoreStats } from "types";
import {
  CellValueType,
  ChainCell,
  PositionCellType,
  SkeletonTestHeadingCell,
  SkeletonTrustScoreCell,
  SkeletonValueCell,
  TestHeadingCell,
  TestHeadingCellValueType,
  TrustScoreCell,
  ValueCell,
} from "components/AnteTable/cells";
import { PoolRowLabelType } from "./PoolRow";
import { useCellStyles } from "components/AnteTable/cells.styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Chain } from "utils/constants";
import { useWallet } from "utils/wallet";

export interface PoolRowProps {
  label: PoolRowLabelType;
  name: TestHeadingCellValueType;
  address: string;
  chain: Chain;
  totalValueLocked: CellValueType;
  trustScore: TrustScoreStats;
  position: PositionCellType;
}

const useColumns = () => {
  const cellStyles = useCellStyles();
  const theme = useTheme();
  const { networkId } = useWallet();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getColumns = (isWalletActive: boolean): AnteColumn<PoolRowProps>[] => {
    const columns: AnteColumn<PoolRowProps>[] = [
      {
        Header: "Protocol / Test name",
        accessor: "name",
        Cell: TestHeadingCell,
        SkeletonCell: SkeletonTestHeadingCell,
        styles: cellStyles,
        classNames: cellStyles.headingCellContainer,
        sortType: (a: Row<PoolRowProps>, b: Row<PoolRowProps>): number => {
          return a.original.name?.name.localeCompare(b.original.name?.name);
        },
      },
      {
        id: "ante-value-locked",
        Header: "Ante Value Locked",
        accessor: "totalValueLocked",
        sortType: (a: Row<PoolRowProps>, b: Row<PoolRowProps>): number => {
          return (
            a.original.totalValueLocked?.numericValue -
            b.original.totalValueLocked?.numericValue
          );
        },
        align: "right",
        Cell: ValueCell,
        SkeletonCell: SkeletonValueCell,
        styles: cellStyles,
      },
      {
        Header: "",
        accessor: "chain",
        Cell: ChainCell,
      },
      {
        Header: isMobile ? "Decen. Trust Score" : "Decentralized Trust Score",
        accessor: "trustScore",
        sortType: (a: Row<PoolRowProps>, b: Row<PoolRowProps>): number => {
          return (
            a.original.trustScore.trustScore - b.original.trustScore.trustScore
          );
        },
        align: "center",
        tooltip: `The Decentralized Trust Score is calculated by dividing the total amount staked supporting the test (not including funds pending withdrawal) by the total amount staked + challenged`,
        Cell: TrustScoreCell,
        SkeletonCell: SkeletonTrustScoreCell,
        styles: cellStyles,
      },
    ];

    if (isWalletActive) {
      columns.push({
        Header: "My position",
        accessor: "position",
        sortType: (
          a: Row<PoolRowProps>,
          b: Row<PoolRowProps>,
          _columnId,
          desc: boolean
        ): number => {
          // If left is not from this chain move to the bottom
          if (networkId !== a.original.chain.id.toLowerCase()) {
            return desc ? -1 : 1;
          }

          // If right is not from this chain move to the bottom
          if (networkId !== b.original.chain.id.toLowerCase()) {
            return desc ? 1 : -1;
          }

          // If balance is undefined, move to the bottom
          if (
            a.original.position.balance === undefined ||
            b.original.position.balance === undefined
          ) {
            return desc ? 1 : -1;
          }

          return a.original.position.balance - b.original.position.balance;
        },
        align: "center",
        Cell: ValueCell,
        SkeletonCell: SkeletonValueCell,
        styles: cellStyles,
      });
    }

    return columns;
  };

  return { getColumns };
};

export default useColumns;
