import { DocumentNode } from "graphql";
import * as Realm from "realm-web";
import { fetchGraphQL, Variables } from "./graphql-fetcher";
import jwt, { JwtPayload } from "jsonwebtoken";

export const APP_ID = process.env.REACT_APP_REALM_APP_ID;
const apiKey = process.env.REACT_APP_REALM_API_KEY;
const credentials = Realm.Credentials.apiKey(apiKey);

// const graphqlUri = `https://realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;
// Local apps should use a local URI!
const graphqlUri = `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;
// const graphqlUri = `https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;
// const graphqlUri = `https://ap-southeast-1.aws.realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;

const app = new Realm.App(APP_ID);

const getValidAccessToken = async () => {
  if (!app.currentUser) {
    await app.logIn(credentials);
  } else {
    /**
     * Refresh the access token and the user's data only if it's expired.
     * This saves some good ms on each request
     */
    if (
      (jwt.decode(app.currentUser.accessToken) as JwtPayload)?.exp <
      Date.now() / 1000
    ) {
      await app.currentUser.refreshCustomData();
    }
  }
  return app.currentUser.accessToken;
};

export const queryResolver = async <T extends any>(
  query: DocumentNode,
  variables?: Variables
): Promise<T> => {
  const accessToken = await getValidAccessToken();

  return fetchGraphQL<T, any>(graphqlUri, query, variables, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
