import { Button, Grid, Typography } from "@material-ui/core";
import AddStakeModal from "pages/AnteTestDetail/modals/AddStakeModal";
import UnlockStakeModal from "pages/AnteTestDetail/modals/UnlockStakeModal";
import { useContext, useState } from "react";
import { useSelectTxnPending } from "state/selectors";
import { ActionPanelContext } from "../ActionPanelContext";

export const StakedPositionLockedActions = () => {
  const { submitting } = useContext(ActionPanelContext);
  const [addStakeVisible, setAddStakeVisible] = useState(false);
  const [unlockStakeVisible, setUnlockStakeVisible] = useState(false);

  const isTxnPending = useSelectTxnPending();

  const handleAddStakeOpen = () => {
    setAddStakeVisible(true);
  };
  const handleUnlockStakeOpen = () => {
    setUnlockStakeVisible(true);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddStakeOpen}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Add Stake
        </Button>
        <AddStakeModal
          isShown={addStakeVisible}
          hide={() => {
            setAddStakeVisible(false);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleUnlockStakeOpen}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Start Withdrawal
        </Button>
        <Typography
          variant="caption"
          component="div"
          style={{
            color: "#777777",
            marginTop: "8px",
            textAlign: "center",
          }}
        >
          Staked funds take one day to unlock before you can withdraw
        </Typography>

        <UnlockStakeModal
          isShown={unlockStakeVisible}
          hide={() => {
            setUnlockStakeVisible(false);
          }}
        />
      </Grid>
    </Grid>
  );
};
