import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";

import colors from "style/colors";
import KPI, { KPIProps } from "components/KPI/KPI";
import { containerStyles } from "style/styles";

export const styles = makeStyles((theme) => ({
  card: {
    display: "inline-flex",
    flexWrap: "wrap",
    rowGap: "24px",
    columnGap: "1rem",
    padding: "16px",
    width: "250px",
    marginBottom: "48px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "& div": {
        minWidth: "240px",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "space-between",
    },
  },
}));

const KPICard = ({ kpis, isLoaded = true }) => {
  return (
    <Card className={cn(containerStyles().root, styles().card)}>
      {kpis.map((kpi: KPIProps, index: number) => (
        <span key={`${index}-${kpi.label}-${kpi.value}`}>
          <KPI
            tooltip={kpi.tooltip}
            label={kpi.label}
            labelColor={colors.highEmphasis}
            value={kpi.value}
            footnote={kpi.footnote}
            size="medium"
            isLoaded={isLoaded}
          />
        </span>
      ))}
    </Card>
  );
};

export default KPICard;
