import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

import { useWallet } from "utils/wallet";
import AnteLogoWordmark from "assets/ante/ante-logo-wordmark.svg";
import AnteLogo from "assets/ante/ante-logo.svg";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import WalletPopover from "components/DropdownMenu/WalletPopover";
import colors from "style/colors";
import { sendEvent, AnalyticsEvents } from "analytics/analytics";
import LinkDropdown from "components/TopBar/LinkDropdown";
import MoreLinks from "components/TopBar/MoreLinks";
import Popover from "@material-ui/core/Popover";
import NetworkPopover from "components/DropdownMenu/NetworkPopover";
import { Chip } from "@material-ui/core";

const drawerWidth = 265;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginBottom: "24px",
  },
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "24px 24px 0 24px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 0px 0 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0px 0 12px",
    },
  },
  appLogo: {
    marginRight: "8px",
    display: "flex",
  },
  menuButton: {
    marginLeft: "0px",
  },
  title: {
    color: colors.primaryBlue,
    marginRight: "32px",
  },
  toolbar: {
    justifyContent: "space-between",
    minHeight: "40px",
    height: "40px",
    padding: "0",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  wallet: {
    justifySelf: "end",
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
  },
  tab: {
    color: colors.linkBlue,
    fontSize: "18px",
    textTransform: "none",
    lineHeight: "28px",
    opacity: 1,
    fontWeight: 400,
    padding: "0",
    margin: "0 12px",
    transition: ".3s",
    "&:hover": {
      color: colors.linkBlue60,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
      margin: "0 8px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      "& span": {
        display: "inline-block",
        textAlign: "left",
        paddingLeft: "8px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "88px",
    },
  },
  tabContainer: {
    marginTop: "16px",
  },
  menuLabel: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  },
}));

function TopBar() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname } = useLocation();
  let tabIndex;
  if (pathname === "/") {
    tabIndex = 0;
  } else if (pathname === "/community-leaderboard") {
    tabIndex = 1;
  } else if (pathname === "/create-pool") {
    tabIndex = 2;
  } else {
    tabIndex = false;
  }
  const [tabValue, setTabValue] = React.useState(tabIndex);

  useEffect(() => {
    setTabValue(tabIndex);
  }, [tabIndex]);

  const { connecting, account, isNetworkSupported } = useWallet();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClose = () => {
    setMobileOpen(false);
    handleClose();
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const tabs = [
    { url: "/", label: "Leaderboard", component: Link },
    { url: "/community-leaderboard", label: "Community", component: Link },
    { url: "/create-pool", label: "Create Pool", component: Link },
  ];

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    sendEvent(AnalyticsEvents.navTabClicked, {
      url: tabs[newValue].url,
    });
    setTabValue(newValue);
    handleMenuClose();
  };

  const formattedTabs = tabs.map((tab) => (
    <Tab
      component={tab.component}
      to={tab.url}
      label={tab.label}
      className={classes.tab}
      key={tab.label}
    />
  ));
  const renderTabs = (orientation) => (
    <Tabs
      TabIndicatorProps={{
        style: {
          borderRadius: "2px",
          ...(orientation === "horizontal" && {
            height: "4px",
            background: "linear-gradient(90deg, #0064D6 0%, #DE458E 100%)",
          }),
          ...(orientation === "vertical" && {
            width: "4px",
            background: "linear-gradient(#0064D6 0%, #DE458E 100%)",
          }),
        },
      }}
      value={tabValue}
      onChange={handleTabChange}
      orientation={orientation}
    >
      {formattedTabs}
    </Tabs>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar} color="inherit">
        <Toolbar className={classes.toolbar}>
          <div className={classes.menuItems}>
            <Hidden smDown>
              <Link to="/" className={classes.appLogo}>
                <img src={AnteLogoWordmark} alt="Ante Protocol" height="40" />
              </Link>
            </Hidden>
            <Hidden mdUp>
              <Link to="/" className={classes.appLogo}>
                <img src={AnteLogo} alt="Ante Protocol" height="40" />
              </Link>
            </Hidden>
            <Chip
              size="small"
              label="v0.5"
              color="primary"
              style={{ backgroundColor: colors.statPurple, fontWeight: 600 }}
            />
            <Hidden smDown implementation="css">
              {renderTabs("horizontal")}
            </Hidden>
            <Hidden smDown>
              <LinkDropdown />
            </Hidden>
          </div>

          <div className={classes.menuLabel}>
            {!connecting && account && isNetworkSupported ? (
              <>
                <NetworkPopover />
                <WalletPopover />
              </>
            ) : (
              <ConnectWalletButton />
            )}
            <Hidden smDown implementation="css">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleClick}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden mdUp implementation="css">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MoreLinks onMenuClose={handleMenuClose} />
            </Popover>
          </div>
        </Toolbar>
      </AppBar>

      <nav aria-label="nav links">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Hidden mdUp implementation="css">
            <div className={classes.tabContainer}>{renderTabs("vertical")}</div>
            <LinkDropdown />
          </Hidden>
          <MoreLinks onMenuClose={handleMenuClose} />
        </Drawer>
      </nav>
    </div>
  );
}

export default TopBar;
