import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@material-ui/core";
import { SearchField } from "components/SearchField/SearchField";
import { SelectMenuProps, useStyles } from "./TestsFilters.styles";
import { Filters } from "../TestsView";
import { useWallet } from "utils/wallet";
import { useLocalStorage } from "react-use-storage";
import { DashboardSelector } from "../../DashboardSelector/DashboardSelector";
import FilterIcon from "assets/img/icons/filter-icon.svg";
import { useState } from "react";
import {
  SupportedChainId,
  SUPPORTED_CHAINS,
  SUPPORTED_CHAIN_IDS,
} from "utils/constants";

export const HIDDEN_NETWORKS_KEY = "leaderboard_hidden_networks";
export const SHOW_FAILED_KEY = "leaderboard_show_failed_tests";
export const SHOW_UNVERIFIED_KEY = "leaderboard_show_unverified_tests";
export const SHOW_USER_POSITIONS_KEY = "leaderboard_show_user_interface_tests";

type FiltersProps = {
  filters: Filters;
  onChange: (newFilters: Filters) => void;
};

export const TestsFilters = ({ filters, onChange }: FiltersProps) => {
  const styles = useStyles();
  const wallet = useWallet();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hiddenNetworks, setHiddenNetworks] = useLocalStorage<
    SupportedChainId[]
  >(HIDDEN_NETWORKS_KEY, []);

  const selectedNetworks = SUPPORTED_CHAIN_IDS.filter(
    (chainId) => !hiddenNetworks.includes(chainId)
  );

  const [showFailed, setShowFailed] = useLocalStorage(
    "leaderboard_show_failed_tests",
    false
  );
  const [showUnverified, setShowUnverified] = useLocalStorage(
    "leaderboard_show_unverified_tests",
    true
  );
  const [showUserInterface, setShowUserInterface] = useLocalStorage(
    "leaderboard_show_user_interface_tests",
    false
  );
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleShowUnverified = () => {
    setShowUnverified(!showUnverified);
  };
  const toggleShowUserInterface = () => {
    setShowUserInterface(!showUserInterface);
    if (!showUserInterface) {
      setShowUnverified(!showUserInterface);
      setShowFailed(!showUserInterface);
    }
  };
  const toggleShowFailed = () => {
    setShowFailed(!showFailed);
  };

  const handleChangeMultiple = (event) => {
    const { value } = event.target;

    if (value[value.length - 1] === "all") {
      setHiddenNetworks(
        hiddenNetworks.length === SUPPORTED_CHAIN_IDS.length
          ? []
          : SUPPORTED_CHAIN_IDS
      );
      return;
    }

    setHiddenNetworks(
      SUPPORTED_CHAIN_IDS.filter((chainId) => !value.includes(chainId))
    );
  };

  const hasSelectedAllNetworks =
    selectedNetworks.length === Object.keys(SUPPORTED_CHAINS).length;

  const activeFiltersCount =
    Number(showFailed === true) +
    Number(showUnverified === true) +
    Number(showUserInterface === true);
  return (
    <FormGroup className={styles.controls}>
      <DashboardSelector />
      <div className={styles.filtersContainer}>
        <SearchField
          placeholder="Search pools..."
          onChange={(event) =>
            onChange({ ...filters, name: event.target.value })
          }
          value={filters.name}
        />
        {isMobile && (
          <div
            className={styles.filtersToggler}
            onClick={() => setShowFilters(!showFilters)}
          >
            <img src={FilterIcon} alt="filter" width={16} height={16} />
            {activeFiltersCount > 0 && (
              <span className={styles.iconBadge}>{activeFiltersCount}</span>
            )}
          </div>
        )}
      </div>
      <FormControl className={styles.formControl}>
        <Select
          labelId="network-selector-label"
          multiple
          displayEmpty
          value={selectedNetworks}
          onChange={handleChangeMultiple}
          className={styles.networkSelector}
          inputProps={{
            className: styles.networkInput,
          }}
          input={<OutlinedInput />}
          renderValue={(selected) =>
            (selected as string[]).length === 0
              ? "No network selected"
              : Object.values(SUPPORTED_CHAINS)
                  .filter((chain) => (selected as string[]).includes(chain.id))
                  .map((chain) => chain.label)
                  .join(", ")
          }
          MenuProps={SelectMenuProps}
        >
          <MenuItem value="all">
            <Checkbox
              classes={{ indeterminate: styles.indeterminateColor }}
              checked={hasSelectedAllNetworks}
              indeterminate={
                selectedNetworks.length > 0 &&
                selectedNetworks.length < SUPPORTED_CHAIN_IDS.length
              }
            />
            <ListItemText primary="All networks" />
          </MenuItem>
          {Object.values(SUPPORTED_CHAINS).map((chain) => (
            <MenuItem key={chain.id} value={chain.id}>
              <Checkbox checked={selectedNetworks.indexOf(chain.id) > -1} />
              <ListItemText primary={chain.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(!isMobile || (isMobile && showFilters)) && (
        <div className={styles.switchContainer}>
          {wallet.account && (
            <Tooltip
              title={
                "Display only Ante Tests that you hold a stake or challenge position in"
              }
              placement="bottom"
              TransitionComponent={Zoom}
              arrow
              interactive
              classes={{
                tooltip: styles.tooltip,
              }}
            >
              <FormControlLabel
                className={styles.switches}
                control={
                  <Switch
                    checked={showUserInterface}
                    onChange={toggleShowUserInterface}
                  />
                }
                label="Show Only My Positions"
              />
            </Tooltip>
          )}
          <Tooltip
            title={
              "Include Ante Tests that have not been vetted by the Ante team on the leaderboard; please exercise caution"
            }
            placement="bottom"
            TransitionComponent={Zoom}
            arrow
            interactive
            classes={{
              tooltip: styles.tooltip,
            }}
          >
            <FormControlLabel
              className={styles.switches}
              control={
                <Switch
                  checked={showUnverified}
                  onChange={toggleShowUnverified}
                />
              }
              label="Show Unverified"
            />
          </Tooltip>
          <Tooltip
            title={"Include Ante Tests that have failed on the leaderboard"}
            placement="bottom"
            TransitionComponent={Zoom}
            arrow
            interactive
            classes={{
              tooltip: styles.tooltip,
            }}
          >
            <FormControlLabel
              className={styles.switches}
              control={
                <Switch checked={showFailed} onChange={toggleShowFailed} />
              }
              label="Show Failed"
            />
          </Tooltip>
        </div>
      )}
    </FormGroup>
  );
};
