// sorts based upon number of test written and then by earliest contribution
export const sortTestWriters = (a, b) => {
  if (a.anteTestsSubmitted !== b.anteTestsSubmitted) {
    return Number(b.anteTestsSubmitted) - Number(a.anteTestsSubmitted);
  }

  const dateA = new Date(a.firstContribution);
  const dateB = new Date(b.firstContribution);

  if (dateA === dateB) {
    return 0;
  }

  return dateA > dateB ? 1 : 0;
};
