import { makeStyles, Theme } from "@material-ui/core";
import colors from "style/colors";

export const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: "none",
    color: colors.greyscale,
    padding: "8px",
    borderColor: colors.linkBlue,
    borderRadius: "8px",
    backgroundColor: "white",
    "&:hover": {
      bordercolor: colors.primaryBlueLite,
      backgroundColor: "white",
    },
    display: "flex",
    justifyContent: "space-between",
    width: "248px",
    [theme.breakpoints.down("sm")]: {
      width: "160px",
    },
  },
  balance: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  account: {
    letterSpacing: "0.15px",
    display: "flex",
    alignItems: "center",
  },
}));

export const usePopoverStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "300px",
    margin: "0px",
  },
  account: {
    color: colors.highEmphasis,
  },
  balanceTitle: {
    color: colors.greyscale,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  linkText: {
    width: "100%",
  },
  sideLinkContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
}));
