import React, { useContext, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { parseEther, formatUnits } from "@ethersproject/units";

import { TOKEN_CAP } from "utils/constants";
import { useWallet } from "utils/wallet";
import StakeInput from "components/StakeInput";
import { ActionPanelContext } from "../components/ActionPanel/ActionPanelContext";
import { formatTokenValue, getNetworkById } from "utils/utils";

interface AddStakeModalProps {
  isShown: boolean;
  hide: () => void;
}

const AddStakeModal = ({ isShown, hide }: AddStakeModalProps) => {
  const { pool, submitting, setSubmitting } = useContext(ActionPanelContext);

  const { stakedBalanceUnit: currentBalance, stake } = pool;
  const chain = getNetworkById(pool.chainId);
  const token = chain.token;
  const [addStakeAmt, setAddStakeAmt] = useState(0.0);
  const [helperText, setHelperText] = useState("");
  const [errorEntry, setErrorEntry] = useState(false);
  const [firstEntry, setFirstEntry] = useState(false);

  const handleInputChange = (value: string) => {
    setAddStakeAmt(Number(value));
    setFirstEntry(true);
  };

  const currentBalanceEther = Number(formatUnits(currentBalance));

  const { balance } = useWallet();

  const validateSubmit = async (event) => {
    if (addStakeAmt === 0) {
      setErrorEntry(true);
    } else if (
      addStakeAmt + currentBalanceEther <= 0 ||
      addStakeAmt + currentBalanceEther >
        TOKEN_CAP[pool.chainId.toLowerCase()]?.max
    ) {
      console.warn("INVALID: ", addStakeAmt);
      setErrorEntry(true);
    } else if (addStakeAmt * 10 ** chain.decimals > balance.number) {
      console.warn("INVALID: ", addStakeAmt, " > ", balance.number);
      setErrorEntry(true);
    } else {
      setErrorEntry(false);
      // TODO: Add AddStake Hook
      const addStakeAmtWei = parseEther(addStakeAmt.toFixed(chain.decimals));

      setSubmitting(true);
      try {
        await stake(addStakeAmtWei);
        hide();
        // trigger success popup
      } catch (e: any) {
        console.error(e);
        window.rollbar.warn("AddStakeModal:validateSubmit", e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (addStakeAmt * 10 ** chain.decimals > balance.number) {
      setHelperText(
        "Amount is greater than wallet balance: " + balance.formattedString
      );
      setErrorEntry(true);
    } else if (
      addStakeAmt + currentBalanceEther <= 0 ||
      addStakeAmt + currentBalanceEther >
        TOKEN_CAP[pool.chainId.toLowerCase()]?.max ||
      addStakeAmt === 0
    ) {
      setHelperText(
        addStakeAmt <= 0
          ? `Amount must be greater than ${formatTokenValue("0", token)}`
          : `Total amount must be less than ${formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
              token
            )}`
      );
      setErrorEntry(firstEntry);
    } else {
      setHelperText("");
      setErrorEntry(false);
    }
  }, [
    addStakeAmt,
    balance,
    currentBalanceEther,
    firstEntry,
    token,
    pool.chainId,
    chain.decimals,
  ]);

  return (
    <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
      <DialogTitle>Add Stake</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" display="block" gutterBottom>
            Enter the additional amount you would like to stake (up to{" "}
            {formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
              token
            )}{" "}
            total)
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <StakeInput
            id="add-stake-amount"
            onChange={handleInputChange}
            helperText={helperText}
            error={errorEntry}
            currentBalance={currentBalance}
            chain={getNetworkById(pool.chainId)}
          />
        </DialogContentText>
        <DialogContentText>
          <TableContainer>
            <Table size="small" aria-label="challenge breakdown">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Current balance
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        currentBalanceEther.toFixed(16),
                        token
                      )}
                      placement="top"
                      arrow
                    >
                      <span>
                        {formatTokenValue(
                          currentBalanceEther.toFixed(3),
                          token
                        )}
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Additional stake
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        Number(addStakeAmt).toFixed(16),
                        token
                      )}
                      placement="top"
                      arrow
                    >
                      <span>
                        {formatTokenValue(
                          Number(addStakeAmt).toFixed(3),
                          token
                        )}
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Total balance</strong>
                  </TableCell>
                  {/* Need to add value entered in text field to amount displayed */}
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        (currentBalanceEther + Number(addStakeAmt)).toFixed(16),
                        token
                      )}
                      placement="top"
                      arrow
                    >
                      <span>
                        <strong>
                          {formatTokenValue(
                            (currentBalanceEther + Number(addStakeAmt)).toFixed(
                              3
                            ),
                            token
                          )}
                        </strong>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="body2"
            display="block"
            color="textSecondary"
            gutterBottom
          >
            Note: Stakes must be held for at least 1 day before withdrawing.
          </Typography>
          <Typography
            variant="body2"
            display="block"
            color="textSecondary"
            gutterBottom
          >
            *Total {token} cannot exceed{" "}
            {formatTokenValue(
              TOKEN_CAP[pool.chainId.toLowerCase()]?.max.toFixed(2),
              token
            )}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Cancel
        </Button>
        <Button
          onClick={validateSubmit}
          variant="contained"
          color="primary"
          disabled={submitting || errorEntry || addStakeAmt === 0}
        >
          {submitting ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStakeModal;
