import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

type StyleProps = {
  numColumns: number;
};

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  headers: {
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: (props: StyleProps) =>
      `repeat(${props.numColumns}, minmax(min-content, 1fr))`,
    padding: "0 16px 0 16px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    marginBottom: 20,
    letterSpacing: 0.1,
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      gridTemplateColumns: (props: StyleProps) =>
        `repeat(${props.numColumns - 1}, minmax(min-content, 1fr))`,
    },
  },
  header: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        display: "none",
      },
    },
  },
  content: {
    width: "100%",
  },
  rightAlign: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  filterIcon: {
    position: "absolute",
    top: "50%",
    right: "-16px",
    transform: "translate(0, -50%)",
  },
  ascending: {
    transform: "translate(0, -50%) rotate(180deg)",
  },
  columnName: {
    cursor: "pointer",
    position: "relative",
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: "8px",
    height: "160px",
    marginTop: "8px",
    background: "white",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "450px",
    },
  },
  row: {
    position: "relative",
    width: "100%",
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: (props: StyleProps) =>
      `repeat(${props.numColumns}, minmax(min-content, 1fr))`,
    background: "white",
    marginBottom: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)",
    cursor: "pointer",
    "&:hover": {
      background: "#f5f5f5",
      "& $name": {
        textDecoration: "underline",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: ".5rem 1rem",
      gap: 4,
      gridTemplateColumns: (props: StyleProps) =>
        `repeat(${props.numColumns - 1}, minmax(min-content, 1fr))`,
    },
  },
  cell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "16px",
    color: colors.greyscale,
  },
  subValue: {
    color: colors.mediumEmphasis,
    display: "block",
  },
  tooltip: {
    textAlign: "center",
  },
  emptyTableContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: "8px",
    height: "160px",
    marginTop: "8px",
    background: "white",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "450px",
    },
  },
}));
