import { useGlobalStyles } from "utils/styles";
import { capitalize } from "utils/utils";
import { AlignType } from ".";

export const useAlignStyles = () => {
  const globalStyles = useGlobalStyles();

  const getTextStyle = (align: AlignType) => {
    return align ? globalStyles[`text${capitalize(align)}`] : null;
  };

  const getElementStyle = (align: AlignType) => {
    return align ? globalStyles[`align${capitalize(align)}`] : null;
  };

  return { getTextStyle, getElementStyle };
};
