import { Tooltip, Typography, Zoom } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PositionChip from "components/PositionChip/positionChip";
import { TrustBadge } from "components/TrustBadge";
import TrustScoreChart from "components/TrustScoreChart/trustScoreChart";
import React from "react";
import { TrustScoreStats } from "types/TrustScoreStats";
import { CellStyles } from "./cells.styles";
import { useAlignStyles } from "./useAlignStyles";
import cn from "classnames";
import { PoolBadges } from "components/PoolBadges/PoolBadges";
import { Chain } from "utils/constants";
import { PoolBadgeMap } from "utils/antepools/utils";
import FailureIcon from "assets/img/icons/failure-indicator-icon.svg";
import { ProtocolHeadingProps } from "pages/Leaderboard/components/ProtocolsView/useColumns";

export const HeadingCell = ({ value, column }) => {
  const styles: CellStyles = column.styles;
  return (
    <Typography variant="body1" className={styles.heading}>
      {value}
    </Typography>
  );
};

export const ProtocolHeadingCell = (cellProps) => {
  const value: ProtocolHeadingProps = cellProps.value;
  const styles: CellStyles = cellProps.column.styles;

  return (
    <div className={cn(styles.horizontalContainer, styles.protocolHeader)}>
      <Typography variant="body1" className={styles.heading}>
        {value.name}
      </Typography>
      <div className={styles.headerChainsContainer}>
        {renderChains(value.chains, styles)}
      </div>
    </div>
  );
};

export const SkeletonHeadingCell = ({ column }) => {
  const styles: CellStyles = column.styles;
  return <Skeleton animation="wave" className={styles.skeletonHeadingValue} />;
};

export const RatingCell = ({ value }) => {
  return (
    <div>
      <TrustBadge rating={value} badge={false} showTooltip={true} />
    </div>
  );
};

export const SkeletonRatingCell = ({ column }) => {
  const styles: CellStyles = column.styles;
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.value}>
        <Skeleton width={50} animation="wave" />
      </div>
    </div>
  );
};

export type CellValueType = {
  tooltip?: React.ReactNode;
  numericValue: number;
  value: React.ReactNode;
  subValue?: string;
  status?: string;
};

export const ValueCell = (cellProps) => {
  const cellValue: CellValueType = cellProps.value;
  const cellStyles: CellStyles = cellProps.column.styles;

  const content = (
    <React.Fragment>
      <div className={cellStyles.value}>{cellValue.value}</div>
      {cellValue.subValue && (
        <Typography variant="caption" className={cellStyles.subValue}>
          {cellValue.subValue}
        </Typography>
      )}
      {cellValue.status && (
        <Typography variant="caption" className={cellStyles.subValue}>
          {cellValue.status}
        </Typography>
      )}
    </React.Fragment>
  );
  return cellValue.tooltip === undefined ? (
    content
  ) : (
    <Tooltip title={cellValue.tooltip} arrow placement="top">
      <div>{content}</div>
    </Tooltip>
  );
};

export const SkeletonValueCell = ({ column }) => {
  const styles: CellStyles = column.styles;
  const { getElementStyle } = useAlignStyles();
  return (
    <div
      className={cn(styles.skeletonContainer, getElementStyle(column.align))}
    >
      <Skeleton animation="wave" className={styles.skeletonValue} />
      <Typography variant="caption" className={styles.subValue}>
        <Skeleton width={75} animation="wave" />
      </Typography>
    </div>
  );
};

export const TrustScoreCell = (cellProps) => {
  const {
    hasPoolFailed,
    isTvlGteThreshold,
    trustScore,
    totalStakedOverall,
    totalChallenged,
  }: TrustScoreStats = cellProps.value;
  const { column } = cellProps;
  const styles: CellStyles = column.styles;
  const { getElementStyle } = useAlignStyles();
  if (hasPoolFailed) {
    return (
      <div className={cn(styles.relative, getElementStyle("center"))}>
        <img src={FailureIcon} width={48} height={48} alt="failed" />
        <span className={styles.absoluteCentered}>0</span>
      </div>
    );
  }
  return isTvlGteThreshold ? (
    <TrustScoreChart
      trustScore={trustScore}
      stakedTotals={totalStakedOverall}
      challengedTotals={totalChallenged}
    />
  ) : (
    <PositionChip isPending status="TVL is too low to calculate trust score" />
  );
};

export const SkeletonTrustScoreCell = ({ column }) => {
  const styles: CellStyles = column.styles;
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.value}>
        <Skeleton width={75} animation="wave" />
      </div>
    </div>
  );
};

export type TestHeadingCellValueType = {
  name: string;
  protocolName?: string;
  address: string;
  poolAddress: string;
  badges?: PoolBadgeMap;
};

export const SkeletonTestHeadingCell = () => {
  return (
    <>
      <Skeleton width={75} animation="wave" />
      <Skeleton width={300} animation="wave" />
    </>
  );
};

export const TestHeadingCell = (cellProps) => {
  const value: TestHeadingCellValueType = cellProps.value;
  const { protocolName, name, address, badges } = value;
  const styles: CellStyles = cellProps.column.styles;

  return (
    <>
      {protocolName && (
        <div className={styles.eyebrow}>
          <Typography
            color="textSecondary"
            variant="caption"
            className={styles.eyebrowText}
          >
            {protocolName}
          </Typography>
          <PoolBadges badges={badges} />
        </div>
      )}
      <Typography variant="body1" className={styles.heading}>
        <Tooltip
          title={`${name} - ${address}`}
          placement="top"
          TransitionComponent={Zoom}
          arrow
          interactive
          classes={{
            tooltip: styles.tooltip,
          }}
        >
          <span>{name}</span>
        </Tooltip>
        &nbsp;
        {!protocolName && <PoolBadges badges={badges} />}
      </Typography>
    </>
  );
};

export type PositionCellType = {
  balance?: number;
  value?: JSX.Element | string;
};

export const ChainCell = (cellProps) => {
  const chain: Chain = cellProps.value;

  if (!chain || !chain.logo) return null;

  return (
    <Tooltip title={chain.label} arrow placement="top">
      <img src={chain.logo} alt={chain.label} width={32} height={32} />
    </Tooltip>
  );
};

export const ChainsCell = (cellProps) => {
  const chains: Chain[] = cellProps.value;
  const styles: CellStyles = cellProps.column.styles;

  return (
    <div className={styles.horizontalContainer}>
      {renderChains(chains, styles)}
    </div>
  );
};

export const SkeletonChainsCell = ({ column }) => {
  const styles: CellStyles = column.styles;
  const { getElementStyle } = useAlignStyles();
  return (
    <div
      className={cn(styles.skeletonContainer, getElementStyle(column.align))}
    >
      <Skeleton animation="wave" className={styles.skeletonValue} />
    </div>
  );
};

const renderChains = (chains: Chain[], styles?: CellStyles) => {
  const threshold = 4;
  const moreChains = chains.slice(threshold);
  const moreChainsElements = moreChains.map((chain) => (
    <img
      className={styles.chainLogo}
      key={chain.id}
      src={chain.logo}
      alt={chain.label}
      width={32}
      height={32}
    />
  ));

  return (
    <>
      {chains
        .slice(0, threshold)
        .map((chain) =>
          chain ? (
            <img
              className={styles.chainLogo}
              key={chain.id}
              src={chain.logo}
              alt={chain.label}
              width={32}
              height={32}
            />
          ) : null
        )}
      {moreChains.length > 0 && (
        <Tooltip title={moreChainsElements} arrow placement="left" interactive>
          <span>+{moreChains.length}</span>
        </Tooltip>
      )}
    </>
  );
};
