import { gql } from "graphql-tag";

export const PROTOCOL_CORE_FIELDS = gql`
  fragment ProtocolCoreFields on ProtocolCollection {
    _id
    name
    alternateNames
    isVerified
    logo
    description
    websiteUrl
    verifiedAddresses
    socialMedia {
      type
      url
    }
  }
`;
