const colors = {
  white: "#ffffff",
  primaryBlue: "#2196F3",
  primaryBlueLite: "#3BB0FF",
  secondaryPurple: "#5E35B1",
  purple10: "#f1eff7",
  unverifiedOrange: "#d47a42",
  greyscale: "#555555",
  greyscaleSecondary: "rgba(119, 119, 119, 1)",
  highEmphasis: "rgba(0, 0, 0, 0.87)",
  mediumEmphasis: "rgba(0, 0, 0, 0.6)",
  // new branding colors
  stakeBlue: "rgb(0, 100, 214)",
  challengePink: "rgb(222, 69, 142)",
  statPurple: "rgba(114, 91, 176, 1)",
  linkBlue: "rgba(55, 144, 176, 1)",
  linkBlue60: "rgba(55, 144, 176, .6)",
  linkBlue23: "rgba(55, 144, 176, .23)",
  linkBlue10: "rgba(55, 144, 176, .1)",
  failedRed: "rgba(218, 22, 12, 1)",
  black77: "rgba(0, 0, 0, 0.77)",
  black69: "rgba(0, 0, 0, 0.69)",
  black54: "rgba(0, 0, 0, 0.54)",
  black42: "rgba(0, 0, 0, 0.42)",
  black23: "rgba(0, 0, 0, 0.23)",
  black5: "rgba(0, 0, 0, 0.5)",
  warningYellow: "rgba(222, 176, 69, 1)",
  warningYellow23: "rgba(222, 176, 69, 0.23)",
  newYellow: "rgba(222, 196, 51, 0.65)",
  noticeYellow: "rgb(250, 245, 227)",
};

export default colors;
