import { capitalize, Grid } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import {
  getFormattedAntePools,
  calculateAggregateProtocolStats,
  getKPIs,
  getTestsRows,
  getDerivedPools,
  ProtocolStats,
} from "./utils";
import { useStyles } from "./ProtocolProfilePage.styles";
import { useAntePools } from "hooks/antepools/useAntePools";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import { usePoolsBalances } from "hooks/antepools/usePoolsBalances";
import { getRating } from "utils/utils";
import { DetailsCard } from "./components/DetailsCard/DetailsCard";
import { SecurityCard } from "./components/SecurityCard/SecurityCard";
import { useProtocol } from "hooks/protocols/useProtocol";
import { useProtocolVersions } from "hooks/protocols/useProtocolVersions";
import { Helmet } from "react-helmet-async";
import { ErrorCard } from "pages/ErrorPage";

interface RouteParams {
  name: string;
}

const fetchPoolsWithData = true;
const ProtocolProfilePage = () => {
  const params = useRouteMatch().params as RouteParams;
  const protocolName = capitalize(params.name);
  const { protocol, loading: isLoadingProtocol } = useProtocol(protocolName);
  const { versions } = useProtocolVersions({
    protocol: protocolName,
  });
  const { pools, loadingOffChain } = useAntePools(
    fetchPoolsWithData,
    undefined,
    {
      protocolNames: [protocolName]
        .concat(protocol?.alternateNames)
        .filter((item) => !!item),
    }
  );
  const { balances } = usePoolsBalances(pools);
  const isLoading = loadingOffChain || isLoadingProtocol;
  const { prices } = useFetchTokenPrice();
  const [protocolStats, setProtocolStats] = useState<ProtocolStats>({
    aggregateTrustScore: NaN,
    totalTvlInEth: NaN,
    stakedTotalInEth: NaN,
    statsByChain: {},
  });
  const styles = useStyles();

  // Update the verified stakers amounts from on chain pool data
  const derivedPools = useMemo(() => {
    return getDerivedPools(pools, balances);
  }, [pools, balances]);

  const protocolPools = useMemo(() => {
    return getFormattedAntePools(derivedPools, protocol);
  }, [derivedPools, protocol]);

  const updateProtocolStats = useCallback(async () => {
    setProtocolStats(
      await calculateAggregateProtocolStats(protocolPools, prices)
    );
  }, [protocolPools, prices]);

  useEffect(() => {
    updateProtocolStats();
  }, [updateProtocolStats]);

  const { aggregateTrustScore, statsByChain, totalTvlInEth } = protocolStats;
  const rating = getRating(totalTvlInEth, aggregateTrustScore);

  const displayedTests = getTestsRows(protocolPools, prices);

  if (!isLoading && !protocol) {
    return (
      <div className={styles.noContentContainer}>
        <ErrorCard subtitle="We can't find that page." />
      </div>
    );
  }

  return (
    <>
      {protocol && (
        <Helmet prioritizeSeoTags>
          <title>
            {protocol.name} | {protocol.description}
          </title>
          <meta name="description" content={protocol.description} />
          <meta property="og:title" content={protocol.name} />
          <meta property="og:description" content={protocol.description} />
          <meta property="og:image" content={protocol.logo} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <DetailsCard
            isLoading={isLoadingProtocol}
            protocol={protocol}
            versions={versions}
            protocolName={protocolName}
            rating={rating}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <SecurityCard
            isLoading={isLoading}
            tests={displayedTests}
            protocol={protocol}
            versions={versions}
            kpis={getKPIs(
              protocolName,
              statsByChain,
              prices,
              aggregateTrustScore
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { ProtocolProfilePage };
