import { Typography } from "@material-ui/core";
import { useTypography } from "style/typography";
import { BugBounty as BugBountyType } from "types";
import { useGlobalStyles } from "utils/styles";
import { formatFiatValue, toLocaleNumber } from "utils/utils";
import { useStyles } from "./SecurityCard.styles";

type BugBountyProps = {
  bugBounty: BugBountyType;
};

export const BugBounty = ({ bugBounty }: BugBountyProps) => {
  const styles = useStyles();
  const globalStyles = useGlobalStyles();
  const typography = useTypography();

  if (bugBounty === undefined || Object.keys(bugBounty).length === 0)
    return null;
  return (
    <div className={styles.stats}>
      <Typography className={typography.labelMedium}>Bug Bounty</Typography>
      <div className={styles.bugBounty}>
        <Typography variant="h5" className={styles.bugBountyValue}>
          Up to {formatFiatValue(toLocaleNumber(bugBounty?.value))}
        </Typography>
        {bugBounty.url && bugBounty.name && (
          <a
            href={bugBounty.url}
            target="_blank"
            rel="noopener noreferrer"
            className={globalStyles.link}
          >
            View on {bugBounty.name}
          </a>
        )}
      </div>
    </div>
  );
};
