import { makeStyles } from "@material-ui/core";
import { AnteRow, AnteRowType } from "components/AnteTable/AnteRow";
import { ProtocolTestRowProps } from "pages/ProtocolProfilePage/useColumns";
import { Link } from "react-router-dom";

type StyleProps = {
  numColumns: number;
};

const useStyles = makeStyles((theme) => ({
  row: {
    borderRadius: 0,
    boxShadow: "none",
    borderTop: "1px solid rgba(0, 0, 0, 0.2)",
    marginBottom: 0,
    gridTemplateColumns: (props: StyleProps) =>
      `minmax(100px, 2fr) 32px repeat(${
        props.numColumns - 2
      }, minmax(min-content, 1fr))`,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: (props: StyleProps) =>
        `repeat(${props.numColumns - 1}, minmax(min-content, 1fr))`,
    },
  },
}));

export const TestRow = (props: AnteRowType<ProtocolTestRowProps>) => {
  const { rowData } = props;
  const styles = useStyles({ numColumns: props.numberOfColumns });

  if (typeof rowData.original.name.poolAddress === "undefined") {
    return <AnteRow {...props} classNames={styles.row} />;
  }

  return (
    <Link
      to={`/contract/${rowData.original.chain.id}/${rowData.original.name.poolAddress}`}
      {...rowData.getRowProps()}
    >
      <AnteRow {...props} classNames={styles.row} />
    </Link>
  );
};
