import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useStyles } from "./DetailsCard.styles";

export const DetailsSkeleton = ({ protocolName }: { protocolName: string }) => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.nameContainer}>Protocol Profile</div>
      <div className={styles.protocolTitle}>{protocolName}</div>
      <div className={styles.description}>
        <Skeleton
          animation="wave"
          variant="text"
          data-testid="details-skeleton"
        />
      </div>
    </>
  );
};
