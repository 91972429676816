import cn from "classnames";
import { sortTestWriters } from "./utils";
import { useTestWriters } from "hooks/testWriters/useTestWriters";
import { useStyles } from "./CommunityLeaderboardTable.styles";
import CommunityLeaderboardRow from "./CommunityLeaderboardRow";

const CommunityLeaderboardTable = () => {
  const { writers } = useTestWriters();
  const testWriters = writers ? [...writers].sort(sortTestWriters) : [];
  const styles = useStyles();
  return (
    <div className={styles.root} data-testid="ante-table">
      <div className={styles.tableHeaders}>
        <div className={cn(styles.tableHeader, styles.rank)}>
          <div className={styles.rankLabel}>Rank</div>
        </div>
        <div className={cn(styles.tableHeader, styles.contributor)}>
          Contributor
        </div>
        <div className={cn(styles.tableHeader, styles.testCount)}>
          Ante Tests Submitted
        </div>
        <div className={cn(styles.tableHeader, styles.date)}>
          Test Contributor Since
        </div>
      </div>
      <div>
        {testWriters.map(
          (
            {
              contributor,
              firstContribution,
              anteTestsSubmitted,
              anteTests,
              githubUrl,
            },
            idx
          ) => (
            <CommunityLeaderboardRow
              key={contributor}
              contributor={contributor}
              githubUrl={githubUrl}
              rank={idx + 1}
              firstContribution={new Date(firstContribution)}
              testsSubmitted={anteTestsSubmitted}
              anteTests={anteTests}
            />
          )
        )}
      </div>
    </div>
  );
};

export default CommunityLeaderboardTable;
