import {
  BaseTextFieldProps,
  InputAdornment,
  InputBaseProps,
  makeStyles,
  TextField,
} from "@material-ui/core";
import colors from "style/colors";
import { ReactComponent as SearchIcon } from "assets/img/search.svg";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    width: "225px",
    borderRadius: "4px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  search: {
    padding: "10px 16px 10px 0",
  },
  searchIcon: {
    color: colors.greyscale,
  },
  searchInput: { background: "white", borderRadius: "4px" },
}));

export const SearchField = (props: BaseTextFieldProps & InputBaseProps) => {
  const styles = useStyles();
  return (
    <TextField
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      className={styles.searchContainer}
      inputProps={{
        className: styles.search,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={styles.searchIcon} />
          </InputAdornment>
        ),
        className: styles.searchInput,
      }}
      variant={props.variant ? props.variant : "outlined"}
    />
  );
};
