import React, { useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import colors from "style/colors";
import BytecodeOnlyImage from "assets/img/bytecode.svg";
import { useSourceCode } from "hooks/sourceCode/useSourceCode";
import { Skeleton } from "@material-ui/lab";
import { TableCell } from "@material-ui/core";
import { Chain, CHAIN_EXPLORERS } from "utils/constants";

export const testCodeStyles = makeStyles((theme) => ({
  root: {
    /* can't seem to figure out how to remove the table grid lines... */
    border: "0px",
    borderColor: "rgba(255,255,255,0)",
  },
  address: {
    padding: "6px 12px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
    overflowX: "auto",
    border: 0,
  },
  link: {
    color: colors.primaryBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  anteTest: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  etherscan: {
    padding: "6px 12px",
  },
}));

interface TestCodeViewProps {
  address?: string;
  chain: Chain;
}

const TestCodeView = ({ address, chain }: TestCodeViewProps) => {
  const [testCode, setTestCode] = useState("");
  const [gotTestCode, setGotTestCode] = useState(false);

  const { sourceCode, loading } = useSourceCode(address, chain.id);

  useEffect(() => {
    if (loading) return;

    if (sourceCode !== undefined) {
      setGotTestCode(true);
      setTestCode(sourceCode.sourceCode);
      return;
    }

    const fetchCode = async () => {
      try {
        const code = await CHAIN_EXPLORERS[chain.id].getSourceCode(address);
        const explorerSourceCode = code[0]["SourceCode"];
        if (explorerSourceCode !== undefined && explorerSourceCode !== "") {
          setGotTestCode(true);
          if (
            explorerSourceCode.startsWith("{{") &&
            explorerSourceCode.endsWith("}}")
          ) {
            const contractName = code[0]["ContractName"];
            const codeList = JSON.parse(
              explorerSourceCode.substring(1, explorerSourceCode.length - 1)
            )["sources"];
            Object.keys(codeList).forEach((key) => {
              if (key.includes("/" + contractName + ".sol")) {
                setTestCode(codeList[key]["content"]);
              }
            });
          } else {
            setTestCode(code[0]["SourceCode"]);
          }
        } else {
          setGotTestCode(false);
          setTestCode("Only bytecode available.");
        }
      } catch (e) {
        setGotTestCode(false);
        setTestCode("Error when fetching source code:\n" + e);
      }
    };
    fetchCode();
  }, [address, sourceCode, loading, chain.id]);

  const styles = testCodeStyles();

  if (loading) {
    return <Skeleton animation={"wave"} />;
  }

  return (
    <Grid container spacing={2} style={{ marginTop: "8px" }}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <TableContainer>
            <Table size="small" aria-label="" className={styles.root}>
              <TableBody>
                <TableRow>
                  <TableCell className={styles.address}>
                    <strong className={styles.anteTest}>AnteTest</strong>
                    {CHAIN_EXPLORERS[chain.id] ? (
                      <a
                        href={`${CHAIN_EXPLORERS[chain.id].address}${address}`}
                        color="textSecondary"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.link}
                      >
                        {address}
                      </a>
                    ) : (
                      address
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box
          bgcolor="#F1F1F1"
          borderRadius={16}
          fontSize={14}
          maxHeight={400}
          overflow="auto"
          p={0}
        >
          <SyntaxHighlighter
            language="solidity"
            customStyle={{
              margin: 0,
              padding: 0,
            }}
            lineNumberStyle={{
              background: "#e2e2e2",
              marginRight: 6,
              paddingRight: 10,
              paddingLeft: 10,
            }}
            showLineNumbers
          >
            {testCode}
          </SyntaxHighlighter>
          {!gotTestCode && (
            <img src={BytecodeOnlyImage} alt="Bytecode Contract" height="128" />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" className={styles.etherscan}>
          Powered by Etherscan.io APIs
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TestCodeView;
