import NewIcon from "assets/img/icons/new.svg";
import AnteReviewedIcon from "assets/img/icons/ante-reviewed.svg";
import SelfStakedIcon from "assets/img/icons/self-staked.svg";
import { useStyles } from "./PoolBadges.styles";
import { Tooltip, Zoom } from "@material-ui/core";
import { BadgeType } from "types/BadgeType";
import { PoolBadgeMap } from "utils/antepools/utils";

export type BadgeElementType = {
  content: React.ReactNode;
  tooltip: React.ReactNode;
};

const BADGE_MAP: Record<BadgeType, BadgeElementType> = {
  selfStaked: {
    content: <img src={SelfStakedIcon} alt="Self Staked" />,
    tooltip:
      "This Ante Test has been staked by the protocol it was written for; this shows they have skin in the game!",
  },
  anteReviewed: {
    content: <img src={AnteReviewedIcon} alt="Ante Reviewed" />,
    tooltip:
      "This test was reviewed by a member of the Ante core team. Reviewed tests may still contain bugs.",
  },
  new: {
    content: <img src={NewIcon} alt="New" />,
    tooltip: "This Ante Pool was deployed within the past week.",
  },
};

export type PoolBadgesProps = {
  badges: PoolBadgeMap;
};

export const PoolBadges = ({ badges }: PoolBadgesProps) => {
  const styles = useStyles();

  if (typeof badges === "undefined") return null;

  return (
    <span className={styles.badges}>
      {Object.keys(badges).map((badge) => {
        if (badges[badge] !== true) return null;

        return (
          <Tooltip
            key={badge}
            title={BADGE_MAP[badge].tooltip}
            placement="top"
            TransitionComponent={Zoom}
            arrow
            interactive
            classes={{
              tooltip: styles.tooltip,
            }}
          >
            {BADGE_MAP[badge].content}
          </Tooltip>
        );
      })}
    </span>
  );
};
