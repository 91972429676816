import { createSlice } from "@reduxjs/toolkit";
import { CreatePoolState } from "state/types";

export const initialState: CreatePoolState = {
  hasSubmitted: false,
  isSubmitting: false,
  testAddress: undefined,
  poolAddress: undefined,
  submissionError: undefined,
};

export const createPoolSlice = createSlice({
  name: "CreatePool",
  initialState,
  reducers: {
    patchState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setHasSubmitted: (state, action) => {
      state.hasSubmitted = action.payload;
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setPoolAddress: (state, action) => {
      state.poolAddress = action.payload;
    },
    setTestAddress: (state, action) => {
      state.testAddress = action.payload;
    },
    setSubmissionError: (state, action) => {
      state.submissionError = action.payload;
    },
  },
});

// Actions
export const {
  patchState,
  setHasSubmitted,
  setIsSubmitting,
  setTestAddress,
  setPoolAddress,
  setSubmissionError,
} = createPoolSlice.actions;

export default createPoolSlice.reducer;
