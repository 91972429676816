import { queryResolver } from "db/graphql-query-resolver";
import {
  GET_PROTOCOLS_NODE,
  GET_PROTOCOL_NODE,
  PROTOCOLS_ACCESSOR,
  PROTOCOL_ACCESSOR,
} from "db/queries/protocol";
import { Exact, Maybe, Scalars } from "db/queries/types";
import { UseQueryOptions, useQuery, QueryKey } from "react-query";
import { Protocol } from "types";
import queryClient from "utils/queryClient";
import { GetProtocolsQuery } from "./useProtocols";

export type GetProtocolQueryVariables = Exact<{
  name: Scalars["String"];
  isVisible?: Maybe<Scalars["Boolean"]>;
}>;

export type GetProtocolQuery = {
  [key in typeof PROTOCOL_ACCESSOR]?: Protocol;
};

export const useProtocol = <TData = GetProtocolQuery, TError = unknown>(
  name: string,
  options?: UseQueryOptions<GetProtocolQuery, TError, TData>
) => {
  const variables: GetProtocolQueryVariables = {
    name: name,
    isVisible: true,
  };
  const query = GET_PROTOCOL_NODE;

  const key = [query, variables] as QueryKey;
  const { data, isLoading } = useQuery(
    key,
    () => queryResolver(query, variables),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      initialData: () => {
        const key = [GET_PROTOCOLS_NODE] as QueryKey;
        const cachedWithoutFilters =
          queryClient.getQueryData<GetProtocolsQuery>(key);

        if (typeof cachedWithoutFilters !== "undefined") {
          return {
            [PROTOCOL_ACCESSOR]: cachedWithoutFilters?.[
              PROTOCOLS_ACCESSOR
            ].find((protocol) => protocol.name === name),
          } as GetProtocolQuery as TData;
        }

        return undefined;
      },
      ...options,
    }
  );

  return {
    protocol: data?.[PROTOCOL_ACCESSOR] ?? undefined,
    loading: isLoading,
  };
};
