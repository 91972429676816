import { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import ImportantInfoIcon from "@material-ui/icons/ReportProblemOutlined";
import cn from "classnames";

import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { useWallet } from "utils/wallet";
import colors from "style/colors";
import { containerStyles } from "style/styles";
import { LLAMA_POOLS } from "utils/constants";

import { Pool, PoolPositions } from "types";
import { ActionPanelContext } from "./ActionPanelContext";
import { ChallengedPositionActions } from "./success/ChallengedPositionActions";
import { ChallengedContent } from "./failed/ChallengedContent";
import { ChallengedActions } from "./failed/ChallengedActions";
import { StakedContent } from "./failed/StakedContent";
import { NoPositionContent } from "./success/NoPositionContent";
import { NoPositionActions } from "./success/NoPositionActions";
import { ChallengedPositionContent } from "./success/ChallengedPositionContent";
import { StakedPositionContent } from "./success/StakedPositionContent";
import { StakedPositionActions } from "./success/StakedPositionActions";
import { ConnectWalletContainer } from "components/ConnectWallet/ConnectWalletContainer";
import FailedImage from "assets/img/test-failed.svg";
import { ethers } from "ethers";
import { getNetworkById } from "utils/utils";

type ActionPanelComponentsType = {
  content?: React.ReactNode;
  actions?: React.ReactNode;
};

type PoolPositionComponentsType = {
  [key in PoolPositions]?: ActionPanelComponentsType;
} & {
  default: ActionPanelComponentsType;
};

type PoolStatusComponentsType = {
  failed: PoolPositionComponentsType;
  success: PoolPositionComponentsType;
};

interface ActionPanelProps {
  pool: Pool;
  poolPosition?: PoolPositions;
  loading?: boolean;
}

const useStyles = makeStyles({
  root: {
    borderColor: colors.unverifiedOrange,
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "8px",
    padding: "8px",
    backgroundColor: colors.noticeYellow,
  },
  icon: {
    color: colors.unverifiedOrange,
  },
  link: {
    color: colors.primaryBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const ActionPanel = ({ pool, poolPosition, loading }: ActionPanelProps) => {
  const {
    pendingWithdrawAllowedTime,
    checkTestAllowedBlock,
    pendingFailure: hasPoolFailed,
  } = pool;

  const { account, provider, networkId, isNetworkSupported } = useWallet();

  const [submitting, setSubmitting] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(null);
  const [currentBlock, setCurrentBlock] = useState(null);

  const noteStyles = useStyles();

  useEffect(() => {
    const fetchTimestampBlock = async () => {
      const web3 = new ethers.providers.Web3Provider(provider);
      const blockNumber = await web3.getBlockNumber();
      const block = await web3.getBlock(blockNumber);
      setCurrentTimestamp(block.timestamp);
      setCurrentBlock(blockNumber);
    };
    if (provider) {
      fetchTimestampBlock();
    }
  }, [pendingWithdrawAllowedTime, provider]);

  const secondsUntilUnlock = pendingWithdrawAllowedTime - currentTimestamp;
  const isUnlocking = secondsUntilUnlock > 0;

  const CONTENT: PoolStatusComponentsType = {
    success: {
      [PoolPositions.None]: {
        content: <NoPositionContent />,
        actions: <NoPositionActions />,
      },
      [PoolPositions.Challenged]: {
        content: <ChallengedPositionContent />,
        actions: (
          <ChallengedPositionActions
            canVerify={checkTestAllowedBlock < currentBlock}
          />
        ),
      },
      [PoolPositions.Staked]: {
        content: <StakedPositionContent unlocking={isUnlocking} />,
        actions: (
          <StakedPositionActions
            unlocking={isUnlocking}
            currentTimestamp={currentTimestamp}
            secondsUntilUnlock={secondsUntilUnlock}
          />
        ),
      },
      default: {
        content: <></>,
        actions: (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              Uh-oh, something broke!
            </Grid>
          </Grid>
        ),
      },
    },
    failed: {
      [PoolPositions.Challenged]: {
        content: <ChallengedContent />,
        actions: <ChallengedActions />,
      },
      [PoolPositions.Staked]: {
        content: <StakedContent />,
      },
      default: {
        content: (
          <div>
            <Typography variant="body1">The test failed!</Typography>
            <Typography align="center">
              <img src={FailedImage} alt="fail" height="128" />
            </Typography>
          </div>
        ),
        actions: (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography align="center">No actions available</Typography>
            </Grid>
          </Grid>
        ),
      },
    },
  };

  const containerClasses = containerStyles();

  const renderActionPanelSkeleton = () => {
    return (
      <div>
        <CardContent>
          <Skeleton
            animation="wave"
            width={80}
            height={16}
            style={{ marginBottom: "4px" }}
          />
          <Skeleton animation="wave" width={120} height={32} />
          <Skeleton animation="wave" height={18} />
          <Skeleton animation="wave" height={18} />
        </CardContent>
        <CardActions>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Skeleton variant="rect" animation="wave" height={36} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" animation="wave" height={36} />
            </Grid>
          </Grid>
        </CardActions>
      </div>
    );
  };

  const renderPanelCardContent = () => {
    if (!account || (account && !isNetworkSupported)) {
      return (
        <>
          <CardContent>
            <ConnectWalletContainer message="Connect your wallet to view your position and stake/challenge this pool" />
          </CardContent>
          <CardActions>
            <ConnectWalletButton />
          </CardActions>
        </>
      );
    }

    if (account && pool.chainId.toLowerCase() !== networkId.toLowerCase()) {
      return (
        <>
          <CardContent>
            <ConnectWalletContainer
              title="Wrong network detected"
              message="This Ante Test is hosted on a different network. Switch networks to stake or challenge."
            />
          </CardContent>
          <CardActions>
            <ConnectWalletButton chain={getNetworkById(pool.chainId)} />
          </CardActions>
        </>
      );
    }

    if (account && poolPosition === undefined) {
      return renderActionPanelSkeleton();
    }

    if (poolPosition === undefined) {
      return null;
    }

    const statusIndex = hasPoolFailed ? "failed" : "success";
    return (
      <ActionPanelContext.Provider
        value={{
          pool,
          loading,
          submitting,
          setSubmitting,
        }}
      >
        <CardContent>
          {CONTENT[statusIndex]?.[poolPosition]?.content ??
            CONTENT[statusIndex].default.content}
        </CardContent>
        {pool.antePoolAddress === LLAMA_POOLS[pool.chainId.toLowerCase()] && (
          <Card variant="outlined" className={noteStyles.root}>
            <ImportantInfoIcon fontSize="large" className={noteStyles.icon} />
            <Typography variant="body2">
              If you are challenging this test, it is recommended to deploy and
              use a{" "}
              <a
                href="https://github.com/antefinance/ante-community-tests/blob/main/contracts/llamapay/AnteLlamaPayTestChallengerWrapper.sol"
                target="_blank"
                rel="noreferrer"
                className={noteStyles.link}
              >
                wrapper contract
              </a>{" "}
              in order to prevent potential frontrunning. See{" "}
              <a
                href="https://github.com/antefinance/ante-community-tests/tree/main/contracts/llamapay"
                target="_blank"
                rel="noreferrer"
                className={noteStyles.link}
              >
                notes
              </a>{" "}
              for additional details.
            </Typography>
          </Card>
        )}

        <CardActions>
          {CONTENT[statusIndex]?.[poolPosition]?.actions ??
            CONTENT[statusIndex].default.actions}
        </CardActions>
      </ActionPanelContext.Provider>
    );
  };

  return (
    <Card className={cn(containerClasses.root, containerClasses.rainbowBorder)}>
      {loading ? renderActionPanelSkeleton() : renderPanelCardContent()}
    </Card>
  );
};

export default ActionPanel;
