import { Doughnut } from "react-chartjs-2";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import cn from "classnames";

import colors from "style/colors";
import fontTypes from "style/typography";
import {
  convertTokenToCurrency,
  formatTokenValue,
  getNetworkById,
  toLocaleNumber,
} from "utils/utils";
import { SupportedChainId, SUPPORTED_CURRENCIES } from "utils/constants";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";

const tooltipStyles = makeStyles({
  colorLegend: {
    height: "10px",
    width: "10px",
    marginRight: "4px",
    borderRadius: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  staked: {
    backgroundColor: colors.stakeBlue,
  },
  challenged: {
    backgroundColor: colors.challengePink,
  },
});
type TrustScoreTooltipProps = {
  stakedTotals: Record<SupportedChainId, number>;
  challengedTotals: Record<SupportedChainId, number>;
};
const TrustScoreTooltip = ({
  stakedTotals,
  challengedTotals,
}: TrustScoreTooltipProps) => {
  const styles = tooltipStyles();
  return (
    <div>
      {Object.keys(stakedTotals).map((chainId, index) => {
        const stakedTotal = stakedTotals[chainId];
        const chain = getNetworkById(chainId);
        return (
          <div
            key={`trust-score-tooltip-staked-${index}`}
            className={styles.row}
          >
            <div className={cn(styles.colorLegend, styles.staked)} />
            {` Staked ${chain?.token}: ${formatTokenValue(
              toLocaleNumber(stakedTotal),
              chain?.token
            )}`}
          </div>
        );
      })}
      {Object.keys(challengedTotals).map((chainId, index) => {
        const challengedTotal = challengedTotals[chainId];
        const chain = getNetworkById(chainId);
        return (
          <div
            key={`trust-score-tooltip-challenged-${index}`}
            className={styles.row}
          >
            <div className={cn(styles.colorLegend, styles.challenged)} />
            {` Challenged ${chain?.token}: ${formatTokenValue(
              toLocaleNumber(challengedTotal),
              chain?.token
            )}`}
          </div>
        );
      })}
    </div>
  );
};

type TrustScoreChartProps = {
  trustScore: number;
  stakedTotals: Record<SupportedChainId, number>;
  challengedTotals: Record<SupportedChainId, number>;
};

const TrustScoreChart = ({
  trustScore,
  stakedTotals,
  challengedTotals,
}: TrustScoreChartProps) => {
  const { prices } = useFetchTokenPrice();
  const currency = SUPPORTED_CURRENCIES.eth;

  const stakedTotalInEth = Object.keys(stakedTotals).reduce(
    (previous: number, chainId: string) => {
      return (previous += convertTokenToCurrency(
        chainId,
        stakedTotals[chainId],
        currency,
        prices
      ));
    },
    0
  );

  const challengedTotalInEth = Object.keys(challengedTotals).reduce(
    (previous: number, chainId: string) => {
      return (previous += convertTokenToCurrency(
        chainId,
        challengedTotals[chainId],
        currency,
        prices
      ));
    },
    0
  );

  const data =
    stakedTotalInEth + challengedTotalInEth > 0
      ? {
          datasets: [
            {
              data: [stakedTotalInEth, challengedTotalInEth],
              backgroundColor: [colors.stakeBlue, colors.challengePink],
            },
          ],
          labels: [
            `Staked ${currency.symbol.toUpperCase()}`,
            `Challenged ${currency.code.toUpperCase()}`,
          ],
        }
      : {
          datasets: [
            {
              data: [1],
              backgroundColor: ["grey"],
            },
          ],
          labels: [`No funds staked`],
        };

  const options = {
    layout: {
      //padding: {
      //  right: 60,
      //  left: 60
      //}
    },
    cutout: "80%",
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tooltip
        title={
          <TrustScoreTooltip
            stakedTotals={stakedTotals}
            challengedTotals={challengedTotals}
          />
        }
        placement="top"
        arrow
      >
        <div
          style={{
            ...fontTypes.kpiSmall,
            color: colors.black77,
            height: "48px",
            width: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          {isNaN(trustScore) ? "-" : trustScore.toFixed(0)}
        </div>
      </Tooltip>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 0,
        }}
      >
        <Doughnut data={data} options={options} height={48} width={48} />
      </div>
    </div>
  );
};

export default TrustScoreChart;
