import { Pool } from "types";
import { PoolChainTotals } from ".";

export const computePoolsTotals = (pools: Array<Pool>): PoolChainTotals =>
  pools.reduce(
    (
      accum,
      {
        chainId,
        stakedBalance,
        pendingWithdraw,
        challengedBalance,
        tvl,
        pendingFailure,
      }
    ) => {
      if (!chainId) return accum;
      if (!accum.hasOwnProperty(chainId)) {
        accum[chainId] = {
          staked: 0,
          challenged: 0,
          tvl: 0,
          count: 0,
        };
      }
      if (pendingFailure) {
        return accum;
      }
      accum[chainId] = {
        staked: accum[chainId].staked + stakedBalance + pendingWithdraw,
        challenged: accum[chainId].challenged + challengedBalance,
        tvl: accum[chainId].tvl + tvl,
        count: accum[chainId].count + 1,
      };

      return accum;
    },
    {} as PoolChainTotals
  );
