import { Typography } from "@material-ui/core";
import FailedImage from "assets/img/test-failed.svg";
import KPI from "components/KPI/KPI";
import { useContext } from "react";
import { ActionPanelContext } from "../ActionPanelContext";
import {
  bnToStringPrecision,
  convertBNTokenToBNFiat,
  formatBNFiatValue,
  formatTokenValue,
  getTokenByChain,
} from "utils/utils";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";

export const ChallengedContent = () => {
  const { pool, loading } = useContext(ActionPanelContext);

  const { prices } = useFetchTokenPrice();

  const { challengerPayout } = pool;

  return (
    <>
      <Typography variant="body1">
        The test failed! You have earned a portion of the stake in the protocol!
      </Typography>
      <Typography align="center">
        <img src={FailedImage} alt="fail" height="128" />
      </Typography>
      <KPI
        label="My Earnings"
        value={
          challengerPayout &&
          `${formatTokenValue(
            bnToStringPrecision(challengerPayout),
            getTokenByChain(pool.chainId)
          )}`
        }
        footnote={
          challengerPayout &&
          `${formatBNFiatValue(
            convertBNTokenToBNFiat(pool.chainId, challengerPayout, prices)
          )}`
        }
        isLoaded={!loading}
      />
    </>
  );
};
