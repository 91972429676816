import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

type StyleProps = {
  numColumns: number;
};

export const useStyles = makeStyles((theme) => ({
  label: {
    height: "100%",
    width: "1.5rem",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "0 8px 8px 0",
    writingMode: "vertical-lr",
    transform: "rotate(-180deg)",
    display: "flex",
    justifyContent: "center",
    fontSize: "10px",
    lineHeight: "1.5rem",
    fontWeight: 600,
    color: "white",
    zIndex: 2,
  },
  anteLabel: {
    backgroundColor: colors.stakeBlue,
  },
  partnerLabel: {
    backgroundColor: colors.statPurple,
  },
  unreviewedLabel: {
    backgroundColor: colors.unverifiedOrange,
  },
  tooltip: {
    textAlign: "center",
  },
  poolRow: {
    padding: "16px 16px 16px 32px",
    gridTemplateColumns: (props: StyleProps) =>
      `minmax(100px,3fr) minmax(min-content, 1fr) 32px repeat(${
        props.numColumns - 3
      }, minmax(min-content, 1fr))`,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: (props: StyleProps) =>
        `minmax(min-content, 1fr) 32px repeat(${
          props.numColumns - 3
        }, minmax(min-content, 1fr))`,
    },
  },
}));
