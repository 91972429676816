import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const useStyles = makeStyles({
  text: {
    textAlign: "center",
    padding: "1rem 0",
  },
  link: {
    color: colors.primaryBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});
