import fetchAntePoolBalancesPublicData from "utils/antepools/fetchAntePoolBalancesPublic";
import { useWallet, Wallet, WalletData } from "utils/wallet";
import {
  QueryKey,
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { Pool, PoolBalances, PoolBalancesMap } from "types";
import { useQueryClient } from "react-query";
import { useEffect, useRef, useState } from "react";

export const fetchAntePoolBalances = async (
  walletData: Partial<WalletData>,
  pool: Pool
): Promise<PoolBalances> => {
  const poolStakerBalancesPromise = fetchAntePoolBalancesPublicData(
    walletData,
    pool,
    pool.verifiedStakers.map((s) => s.address),
    false
  );
  const poolChallengerBalancesPromise = fetchAntePoolBalancesPublicData(
    walletData,
    pool,
    pool.verifiedChallengers.map((s) => s.address),
    true
  );
  const [stakerBalances, challengerBalances] = await Promise.all([
    poolStakerBalancesPromise,
    poolChallengerBalancesPromise,
  ]);

  return { stakerBalances, challengerBalances };
};

type PoolsBalancesResult = {
  isLoading: boolean;
  balances: PoolBalancesMap;
};

const fetchPoolBalances = async (
  pool: Pool,
  wallet: Wallet
): Promise<PoolBalances> => {
  const hasStakersOrChallengers =
    pool &&
    (!!pool.verifiedChallengers?.length || !!pool.verifiedStakers?.length);
  if (
    hasStakersOrChallengers &&
    wallet.provider !== undefined &&
    wallet.account !== undefined &&
    pool.chainId &&
    wallet.networkId.toLowerCase() === pool.chainId.toLowerCase()
  ) {
    return fetchAntePoolBalances(wallet, pool);
  }

  return {
    stakerBalances: {},
    challengerBalances: {},
  };
};

export const usePoolsBalances = (antePools: Pool[]) => {
  const wallet = useWallet();
  const queryClient = useQueryClient();
  const prevPoolsQueries = useRef<UseQueryResult<PoolBalances, unknown>[]>();
  const result = useRef<PoolsBalancesResult>({
    isLoading: true,
    balances: {},
  });

  const [queries, setQueries] = useState<
    UseQueryOptions<PoolBalances, unknown, unknown, QueryKey>[]
  >([]);
  useEffect(() => {
    setQueries(
      antePools?.map((pool) => {
        const key = ["pool", "balances", pool.antePoolAddress];
        return {
          queryKey: key,
          queryFn: () => fetchPoolBalances(pool, wallet),
          enabled:
            wallet.account !== undefined && wallet.provider !== undefined,
          refetchOnWindowFocus: false,
          refetchInterval: 60 * 60 * 1000,
          refetchOnMount: !queryClient.getQueriesData(key),
        };
      }) ?? []
    );
  }, [wallet, queryClient, antePools]);

  const poolsQueries: UseQueryResult<PoolBalances, unknown>[] =
    useQueries(queries);

  if (
    poolsQueries.some(
      (query, index: number) =>
        query.data !== prevPoolsQueries.current?.[index].data
    )
  ) {
    prevPoolsQueries.current = poolsQueries;

    result.current = {
      ...result.current,
      balances: poolsQueries?.reduce((prevResult, query, index) => {
        prevResult[antePools[index].antePoolAddress] = query.data;
        return prevResult;
      }, {} as PoolBalancesMap),
    };
  }

  const isAnyLoading = poolsQueries
    ? poolsQueries.some((query) => query.isLoading)
    : false;

  if (result.current.isLoading !== isAnyLoading) {
    result.current = {
      ...result.current,
      isLoading: isAnyLoading,
    };
  }

  return result.current;
};
