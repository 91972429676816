import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import fontType from "style/typography";

import KPI from "components/KPI/KPI";
import { formatTokenValue, getTokenByChain, shortenString } from "utils/utils";
import { Pool } from "types";

export const useStyles = makeStyles((theme) => ({
  root: {},
  kpiLabel: {
    ...fontType.labelMedium,
  },
  kpiStat: {
    ...fontType.kpiLarge,
  },
  active: {
    backgroundColor: "#EAF2FD",
    margin: "-12px 0px -12px -12px",
    padding: "12px",
    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },
  },
  failed: {
    backgroundColor: "#F9DCDB",
    borderColor: "#CC0000",
    padding: "12px",
  },
}));

interface TestKeyStatsProps {
  loading?: boolean;
  pool: Pool;
}

const TestKeyStats = ({ loading, pool }: TestKeyStatsProps) => {
  const styles = useStyles();

  const {
    pendingFailure: hasPoolFailed,
    numTimesVerified,
    lastVerifiedBlock,
    verifier,
    trustScore,
    stakedAndPendingWithdraw,
    tvl,
    totalChallengerStaked,
  } = pool || {};

  const token = getTokenByChain(pool.chainId);
  const payoutClaimed =
    totalChallengerStaked && totalChallengerStaked.length > 0
      ? Math.max(
          0,
          tvl - totalChallengerStaked[totalChallengerStaked.length - 1].value
        )
      : 0;

  if (hasPoolFailed) {
    return (
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6} lg={3}>
          <KPI
            label={"Stake Lost"}
            value={formatTokenValue(stakedAndPendingWithdraw.toFixed(3), token)}
            isLoaded={!loading}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <KPI
            label="Payout Claimed"
            value={formatTokenValue(payoutClaimed.toFixed(3), token)}
            isLoaded={!loading}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <Paper variant="outlined" className={styles.failed}>
            <KPI
              label="Current Test Status"
              value="Failed"
              footnote={`Triggered by ${shortenString(
                verifier
              )} on block ${lastVerifiedBlock}`}
              isLoaded={!loading}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={6} lg={3}>
        <KPI
          label={"Total Value Locked"}
          value={formatTokenValue(tvl.toFixed(3), token)}
          footnote={""}
          isLoaded={!loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <KPI
          label="Decentralized Trust Score"
          value={trustScore.toFixed(2)}
          tooltip={`Trust score calculation does not include staked funds currently staged for withdrawal`}
          isLoaded={!loading}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Paper variant="outlined" className={styles.active}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={6} lg={6}>
              <KPI label="Test Status" value="Active" isLoaded={!loading} />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <KPI
                label="Previous Verifications"
                value={numTimesVerified}
                footnote={
                  numTimesVerified > 0
                    ? `Last verified on block ${lastVerifiedBlock}`
                    : ""
                }
                isLoaded={!loading}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TestKeyStats;
