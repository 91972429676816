import { Network } from "@antefinance/ante-sdk";
import ArbitrumLogo from "assets/img/chains/arbitrum-logo.svg";
import AvalancheLogo from "assets/img/chains/avalanche-logo.svg";
import AuroraLogo from "assets/img/chains/aurora-logo.svg";
import BSCLogo from "assets/img/chains/bsc-logo.svg";
import EthereumLogo from "assets/img/chains/ethereum-logo.svg";
import EtherscanLogo from "assets/img/etherscan-logo.svg";
import FantomLogo from "assets/img/chains/fantom-logo.svg";
import OptimismLogo from "assets/img/chains/optimism-logo.svg";
import PolygonLogo from "assets/img/chains/polygon-logo.svg";
import ScrollLogo from "assets/img/chains/scroll-logo.svg";
import { Currency } from "types/Currency";
import { getSourceCodeFromExplorer } from "./apicall";

// Amount of locked ETH to have trust score showing
export const TVL_THRESHOLD = 0;
// number of milliseconds in past for a pool to be considered "new"
export const NEW_THRESHOLD = 7 * 24 * 60 * 60 * 1000;
// Frontend token stake/challenge cap
type TokenCap = {
  [key in SupportedChainId]: {
    min: number;
    max: number;
  };
};
export const TOKEN_CAP: TokenCap = {
  "0x1": {
    // mainnet
    min: 0.01,
    max: 50,
  },
  "0x4": {
    // rinkeby
    min: 0.01,
    max: 50,
  },
  "0x5": {
    // goerli
    min: 0.01,
    max: 50,
  },
  "0xa": {
    // optimistic ethereum
    min: 0.01,
    max: 50,
  },
  "0x38": {
    // bsc
    min: 0.1,
    max: 500,
  },
  "0x61": {
    // bsc testnet
    min: 0.1,
    max: 500,
  },
  "0x89": {
    // polygon
    min: 1,
    max: 4000,
  },
  "0xfa": {
    // fantom
    min: 1,
    max: 4000,
  },
  "0x1a4": {
    // optimism goerli
    min: 1,
    max: 50,
  },
  "0xfa2": {
    // fantom testnet
    min: 1,
    max: 4000,
  },
  "0xa86a": {
    // avalanche fuji
    min: 1,
    max: 4000,
  },
  "0xa869": {
    // avalanche fuji
    min: 1,
    max: 4000,
  },
  "0x13881": {
    // polygon mumbai
    min: 1,
    max: 4000,
  },
  "0x8274F": {
    // scroll l1 testnet
    min: 0.01,
    max: 50,
  },
  "0x82752": {
    // scroll l2 testnet
    min: 0.01,
    max: 50,
  },
  "0xa4b1": {
    // arbitrumOne
    min: 0.01,
    max: 50,
  },
  "0x66eed": {
    // arbitrumGoerli
    min: 0.01,
    max: 50,
  },
  "0x4e454152": {
    // aurora
    min: 0.01,
    max: 50,
  },
  "0x4e454153": {
    // auroraTestnet
    min: 0.01,
    max: 50,
  },
};
// Estimated Gas Buffer
export const GAS_BUFFER = 0.03;

export const ANTE_DEPLOYED_POOLS = [
  // mainnet
  "0xE48f6A36f3712E389ce666BCEcD88BA60c30aE50",
  "0x5f3555Febf9bF4930ad581dB008f8b0F6239C6Fc",
  "0xFc2Bd420ae071a812Ea238C5916198024E00fE33",
  "0x28b549845B6fE1939783ba0bDb3ba1a598da0394",
  "0x6e1000a6088Eb3dD1493492626E556F6d9A17BD1",
  "0x22075f4cD76299822Eb8D1546f5DcF775c90AA87",
  "0xDC99AD0b3FDdAE6522e26c33439a0b0b476172c5",
  // rinkeby
  "0x9D65F5b91827AFc52C224D4245f892E309d216e0",
  //"0xC9183E91bC2Df3728dB60c466f0A6FD6aBFe6600",
  //"0x8C89E36da2d5b6456b12BB71ebdF351A11e43e70",
  "0xeA9Ccc70196916B8723E9d8d503DdD48D12AC1e8",
  "0x469D04C6D3b5E9f43DDDf498B6CB478711f5Ba28",
  "0x40E9880aAf00A419e6C0f08E82b99088A62Cd02d",
  "0x2fbD279B10248bd0b7d1f656Af585234B2727aA8",
  "0x67758dc06918Cbfd808F5D2Ae72eA21741e19d6E",
];

export const PARTNER_POOLS = [
  "0xeAe27AfaCB56618e1627e10924D66fc5b0A00C3a",
  "0x36CC04E78F698457432b8e833FfF8F38C46E54F1",
  "0xBfc5a7Db53AeCf2d5897a6abC371CF8A9743404B",
  "0xD175Ec2e11FF2Ece50848b5488fc4DF8Fbe1EA49",
  "0x737eD79cC9867E9Ab57Ec8DCf83c322537350787",
  "0x8417a4cD3490265cFb9d63bD9A2bE8c760A40fA6",
];

export const JSEAM_POOL = {
  address: "0xE76EEe525C0Bc488340Ff91167981db4A5C6391f",
  name: "JSeam2",
  githubUrl: "https://github.com/JSeam2",
};

export const RESTRICTED_COUNTRIES = [
  "CU", // Cuba
  "IR", // Iran
  "KP", // North Korea
  "RU", // Russia
  "SY", // Syria
  "UA", // Ukraine
];

export const FRANKIE_POOL = {
  address: "0xc9279Bf55a7dC7cC6d2774435069074Ff5e19eC5",
  name: "FrankieIsLost",
  githubUrl: "https://github.com/FrankieIsLost",
};

export const LLAMA_POOLS = {
  "0x1": "0x18fCb9704D596Ac3cf912F3Bd390579b8c22684F", // eth mainnet
  "0x4": "0x8B29C1f916DD7d537D8438dF3A70f642eCf6794B", // eth rinkeby
  "0xa": "0x4572cf767f8f541858Aa67bc7B9325cF08542056", // optimism
  "0x38": "0x30092Af4E727249e93937EA8ae458b30d9Ae5cf5", // bsc
  "0x89": "0x2992EA84A041F5042CA0062d2E09178A589aEdcb", // polygon mainnet
  "0xfa": "0xBF02833C4a37C3CbE73A9339c069F09246307865", // fantom
  "0xa4b1": "0x73563B0034f5D15849070638B841bcFD225d8182", // arbitrum one
  "0xa86a": "0x99eDEcfE4FE9c2d760b30E782eA0E6C87Bd2F3ac", // avax mainnet
  "0xa869": "", // avax fuji
};

export const isMainnet = process.env.REACT_APP_ENV === "mainnet";

export const ANTE_GOLD_TRUST_THRESHOLD = parseFloat(
  process.env.REACT_APP_ANTE_GOLD_TRUST_THRESHOLD ?? "80"
);

export type Chain = {
  id: string;
  token: string;
  decimals: number;
  label: string;
  logo?: string;
  priceProviderId: string;
  rpcUrl: string;
};

export type ChainExplorer = {
  base: string;
  address: string;
  transaction: string;
  logo?: string;
  getSourceCode?: (address: string) => Promise<any>;
};

type ChainExplorerMap = {
  [key in SupportedChainId]: ChainExplorer;
};

export const CHAIN_EXPLORERS: ChainExplorerMap = {
  // Ethereum Chains
  "0x1": {
    base: "https://etherscan.io/",
    address: "https://etherscan.io/address/",
    transaction: "https://etherscan.io/tx/",
    logo: EtherscanLogo,
    getSourceCode: (address: string) => getSourceCodeFromExplorer(address),
  },
  "0x4": {
    base: "https://rinkeby.etherscan.io/",
    address: "https://rinkeby.etherscan.io/address/",
    transaction: "https://rinkeby.etherscan.io/tx/",
    logo: EtherscanLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api-rinkeby.etherscan.io/`,
        process.env.REACT_APP_ETHERSCAN_API_KEY
      ),
  },
  "0x5": {
    base: "https://goerli.etherscan.io/",
    address: "https://goerli.etherscan.io/address/",
    transaction: "https://goerli.etherscan.io/tx/",
    logo: EtherscanLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api-goerli.etherscan.io/`,
        process.env.REACT_APP_ETHERSCAN_API_KEY
      ),
  },

  // Avalanche Chains
  "0xa86a": {
    base: "https://snowtrace.io/",
    address: "https://snowtrace.io/address/",
    transaction: "https://snowtrace.io/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api.snowtrace.io/`,
        process.env.REACT_APP_SNOWTRACE_API_KEY
      ),
  },
  "0xa869": {
    base: "https://testnet.snowtrace.io/",
    address: "https://testnet.snowtrace.io/address/",
    transaction: "https://testnet.snowtrace.io/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api-testnet.snowtrace.io/`,
        process.env.REACT_APP_SNOWTRACE_API_KEY
      ),
  },

  // Polygon Chains
  "0x89": {
    base: "https://polygonscan.com/",
    address: "https://polygonscan.com/address/",
    transaction: "https://polygonscan.com/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.polygonscan.com/",
        process.env.REACT_APP_POLYGONSCAN_API_KEY
      ),
  },
  "0x13881": {
    base: "https://mumbai.polygonscan.com/",
    address: "https://mumbai.polygonscan.com/address/",
    transaction: "https://mumbai.polygonscan.com/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.polygonscan.com/",
        process.env.REACT_APP_POLYGONSCAN_API_KEY
      ),
  },

  // Binance Smart Chain
  "0x38": {
    base: "https://bscscan.com/",
    address: "https://bscscan.com/address/",
    transaction: "https://bscscan.com/tx/",
    logo: BSCLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.bscscan.com/",
        process.env.REACT_APP_BSCSCAN_API_KEY
      ),
  },
  "0x61": {
    base: "https://testnet.bscscan.com/",
    address: "https://testnet.bscscan.com/address/",
    transaction: "https://testnet.bscscan.com/tx/",
    logo: BSCLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.bscscan.com/",
        process.env.REACT_APP_BSCSCAN_API_KEY
      ),
  },

  // Fantom
  "0xfa": {
    base: "https://ftmscan.com/",
    address: "https://ftmscan.com/address/",
    transaction: "https://ftmscan.com/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.ftmscan.com/",
        process.env.REACT_APP_FTMSCAN_API_KEY
      ),
  },
  "0xfa2": {
    base: "https://testnet.ftmscan.com/",
    address: "https://testnet.ftmscan.com/address/",
    transaction: "https://testnet.ftmscan.com/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.ftmscan.com/",
        process.env.REACT_APP_FTMSCAN_API_KEY
      ),
  },

  // Scroll L1 Tesetnet
  "0x8274F": {
    base: "https://l1scan.scroll.io/",
    address: "https://l1scan.scroll.io/address/",
    transaction: "https://l1scan.scroll.io/tx/",
  },

  // Scroll L2 Testnet
  "0x82752": {
    base: "https://l2scan.scroll.io/",
    address: "https://l2scan.scroll.io/address/",
    transaction: "https://l2scan.scroll.io/tx/",
  },

  // Arbitrum Chains
  "0xa4b1": {
    base: "https://arbiscan.io/",
    address: "https://arbiscan.io/address/",
    transaction: "https://arbiscan.io/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.arbiscan.io/",
        process.env.REACT_APP_ARBISCAN_API_KEY
      ),
  },
  "0x66eed": {
    base: "https://goerli.arbiscan.io/",
    address: "https://goerli.arbiscan.io/address/",
    transaction: "https://goerli.arbiscan.io/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-goerli.arbiscan.io/",
        process.env.REACT_APP_ARBISCAN_API_KEY
      ),
  },

  // Optimism Chains
  "0xa": {
    base: "https://optimistic.etherscan.io/",
    address: "https://optimistic.etherscan.io/address/",
    transaction: "https://optimistic.etherscan.io/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-optimistic.etherscan.io/",
        process.env.REACT_APP_OPTIMISTIC_ETHERSCAN_API_KEY
      ),
  },
  "0x1a4": {
    base: "https://goerli-optimistic.etherscan.io/",
    address: "https://goerli-optimistic.etherscan.io/address/",
    transaction: "https://goerli-optimistic.etherscan.io/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-goerli-optimistic.etherscan.io/",
        process.env.REACT_APP_OPTIMISTIC_ETHERSCAN_API_KEY
      ),
  },

  // Aurora Chains
  "0x4e454152": {
    base: "https://aurorascan.dev/",
    address: "https://aurorascan.dev/address/",
    transaction: "https://aurorascan.dev/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.aurorascan.dev/",
        process.env.REACT_APP_AURORASCAN_API_KEY
      ),
  },
  "0x4e454153": {
    base: "https://testnet.aurorascan.dev/",
    address: "https://testnet.aurorascan.dev/address/",
    transaction: "https://testnet.aurorascan.dev/tx/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.aurorascan.dev/",
        process.env.REACT_APP_AURORASCAN_API_KEY
      ),
  },
};

export const SUPPORTED_CHAINS: SupportedChainsMap = isMainnet
  ? {
      mainnet: {
        id: "0x1",
        token: "ETH",
        decimals: 18,
        label: "Ethereum Mainnet",
        logo: EthereumLogo,
        priceProviderId: "ethereum",
        rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      },
      avalanche: {
        id: "0xa86a",
        token: "AVAX",
        decimals: 18,
        logo: AvalancheLogo,
        label: "Avalanche",
        priceProviderId: "avalanche-2",
        rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
      },
      polygon: {
        id: "0x89",
        token: "MATIC",
        decimals: 18,
        logo: PolygonLogo,
        label: "Polygon (Matic)",
        priceProviderId: "matic-network",
        rpcUrl: "https://matic-mainnet.chainstacklabs.com",
      },
      binance: {
        id: "0x38",
        token: "BNB",
        decimals: 18,
        logo: BSCLogo,
        label: "Binance Smart Chain",
        priceProviderId: "binancecoin",
        rpcUrl: "https://bsc-dataseed.binance.org/",
      },
      fantom: {
        id: "0xfa",
        token: "FTM",
        decimals: 18,
        logo: FantomLogo,
        label: "Fantom Opera",
        priceProviderId: "fantom",
        rpcUrl: "https://rpcapi.fantom.network/",
      },
      arbitrumOne: {
        id: "0xa4b1",
        token: "ETH",
        decimals: 18,
        logo: ArbitrumLogo,
        label: "Arbitrum One",
        priceProviderId: "ethereum",
        rpcUrl: "https://arb1.arbitrum.io/rpc",
      },
      optimism: {
        id: "0xa",
        token: "ETH",
        decimals: 18,
        logo: OptimismLogo,
        label: "Optimistic Ethereum",
        priceProviderId: "ethereum",
        rpcUrl: "https://mainnet.optimism.io",
      },
      aurora: {
        id: "0x4e454152",
        token: "ETH",
        decimals: 18,
        logo: AuroraLogo,
        label: "Aurora",
        priceProviderId: "ethereum",
        rpcUrl: "https://mainnet.aurora.dev",
      },
    }
  : {
      rinkeby: {
        id: "0x4",
        token: "ETH",
        decimals: 18,
        label: "Ethereum Rinkeby",
        logo: EthereumLogo,
        priceProviderId: "ethereum",
        rpcUrl: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      },
      goerli: {
        id: "0x5",
        token: "ETH",
        decimals: 18,
        label: "Ethereum Goerli",
        logo: EthereumLogo,
        priceProviderId: "ethereum",
        rpcUrl: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      },
      avalancheFuji: {
        id: "0xa869",
        token: "AVAX",
        decimals: 18,
        logo: AvalancheLogo,
        label: "Avalanche FUJI C-Chain",
        priceProviderId: "avalanche-2",
        rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
      },
      mumbai: {
        id: "0x13881",
        token: "MATIC",
        decimals: 18,
        logo: PolygonLogo,
        label: "Polygon Matic Mumbai",
        priceProviderId: "matic-network",
        rpcUrl: "https://rpc-mumbai.maticvigil.com",
      },
      binanceTestnet: {
        id: "0x61",
        token: "BNB",
        decimals: 18,
        logo: BSCLogo,
        label: "Binance Smart Chain Testnet",
        priceProviderId: "binancecoin",
        rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      },
      fantomTestnet: {
        id: "0xfa2",
        token: "FTM",
        decimals: 18,
        logo: FantomLogo,
        label: "Fantom Testnet",
        priceProviderId: "fantom",
        rpcUrl: "https://rpc.testnet.fantom.network/",
      },
      arbitrumGoerli: {
        id: "0x66eed",
        token: "ETH",
        decimals: 18,
        logo: ArbitrumLogo,
        label: "Arbitrum Goerli",
        priceProviderId: "ethereum",
        rpcUrl: "https://goerli-rollup.arbitrum.io/rpc",
      },
      scrollL1Testnet: {
        id: "0x8274f",
        token: "TSETH",
        decimals: 18,
        logo: ScrollLogo,
        label: "Scroll L1 Testnet",
        priceProviderId: "ethereum",
        rpcUrl: "https://prealpha.scroll.io/l1",
      },
      scrollL2Testnet: {
        id: "0x82752",
        token: "TSETH",
        decimals: 18,
        logo: ScrollLogo,
        label: "Scroll L2 Testnet",
        priceProviderId: "ethereum",
        rpcUrl: "https://prealpha.scroll.io/l2",
      },
      optimismGoerli: {
        id: "0x1a4",
        token: "ETH",
        decimals: 18,
        logo: OptimismLogo,
        label: "Optimism Goerli",
        priceProviderId: "ethereum",
        rpcUrl: "https://goerli.optimism.io",
      },
      aurora: {
        id: "0x4e454153",
        token: "ETH",
        decimals: 18,
        logo: AuroraLogo,
        label: "Aurora Testnet",
        priceProviderId: "ethereum",
        rpcUrl: "https://testnet.aurora.dev",
      },
    };

export const SUPPORTED_CHAIN_IDS = Object.values(SUPPORTED_CHAINS).map(
  (chain) => chain.id
);
export type SupportedChainId = typeof SUPPORTED_CHAIN_IDS[number];

export const supportedChains = isMainnet
  ? [
      Network.mainnet,
      Network.avalanche,
      Network.polygon,
      Network.binance,
      Network.fantom,
      Network.arbitrumOne,
      Network.optimism,
      Network.aurora,
    ]
  : [
      Network.rinkeby,
      Network.goerli,
      Network.avalancheFuji,
      Network.mumbai,
      Network.binanceTestnet,
      Network.fantomTestnet,
      Network.arbitrumGoerli,
      Network.scrollL1Testnet,
      Network.scrollL2Testnet,
      Network.optimismGoerli,
      Network.auroraTestnet,
    ];

export type SupportedChains = typeof supportedChains[number];

type SupportedChainsMap = {
  [key in SupportedChains]?: Chain;
};

type SupportedCurrencies = {
  [key: string]: Currency;
};

export const SUPPORTED_CURRENCIES: SupportedCurrencies = {
  usd: {
    code: "usd",
    symbol: "$",
  },
  eth: {
    code: "eth",
    symbol: "ETH",
  },
};

export const supportedCurrenciesCodes = Object.values(SUPPORTED_CURRENCIES).map(
  (currency) => currency.code
);
export type SupportedCurencyCode = typeof supportedCurrenciesCodes[number];

export const BASE_CHAIN = isMainnet
  ? SUPPORTED_CHAINS.mainnet
  : SUPPORTED_CHAINS.goerli;

export const BLACKLISTED_ADDRESSES = [].map((address) => address.toLowerCase());

export const LATEST_APP_BASE_URL =
  process.env.REACT_APP_LATEST_BASE_URL || "https://app.ante.finance";
