import PositionChip from "components/PositionChip/positionChip";
import { Pool } from "types";
import { PoolRowProps } from "./useColumns";
import { CellValueType, PositionCellType } from "components/AnteTable/cells";
import {
  convertTokenToCurrency,
  convertTokenToFiat,
  formatFiatValue,
  formatTokenValue,
  getNetworkById,
  toLocaleNumber,
} from "utils/utils";
import QuestionIcon from "assets/img/icons/question-icon.svg";
import { PoolPositions } from "types";
import { getBadges } from "utils/antepools/utils";
import { TokenPrice } from "hooks/useFetchTokenPrice";
import { BASE_CHAIN, Chain, SUPPORTED_CURRENCIES } from "utils/constants";
import { PoolChainTotals } from "pages/Leaderboard/LeaderboardPage";
import { KPIProps } from "components/KPI/KPI";
import { Tooltip } from "@material-ui/core";

interface PositionsProps {
  poolPosition: PoolPositions;
  stakedBalance: number;
  challengedBalance: number;
  pendingWithdraw: number;
  pendingWithdrawAllowedTime: number;
  currentTimestamp: number;
  pendingFailure: boolean;
  chain: Chain;
  connectedChain: Chain;
}

export const getPositionValues = ({
  poolPosition,
  stakedBalance,
  challengedBalance,
  pendingWithdraw,
  pendingWithdrawAllowedTime,
  currentTimestamp,
  pendingFailure,
  chain,
  connectedChain,
}: PositionsProps): PositionCellType => {
  if (chain?.id !== connectedChain?.id) {
    return {
      balance: undefined,
      value: (
        <Tooltip
          title={`Switch networks to view your positions for tests hosted on other networks`}
          arrow
          placement="top"
        >
          <img src={QuestionIcon} alt="info" />
        </Tooltip>
      ),
    };
  }
  let positionValues = {
    balance: undefined,
    value: undefined,
  };

  const isUnlocked = currentTimestamp - pendingWithdrawAllowedTime > 0;
  switch (poolPosition) {
    case PoolPositions.Staked: {
      const isWithdrawPending = pendingWithdraw > 0;
      const balance = isWithdrawPending ? pendingWithdraw : stakedBalance;
      const status =
        pendingFailure || !isWithdrawPending
          ? "locked"
          : isUnlocked
          ? "unlocked"
          : "unlocking";
      positionValues = {
        value: (
          <PositionChip
            isChallenger={false}
            amount={balance.toFixed(3)}
            status={status}
            token={chain?.token}
          />
        ),
        balance,
      };
      break;
    }
    case PoolPositions.Challenged: {
      positionValues = {
        balance: challengedBalance,
        value: (
          <PositionChip
            isChallenger
            amount={challengedBalance.toFixed(3)}
            token={chain?.token}
          />
        ),
      };
      break;
    }
    default: {
      positionValues = {
        balance: null,
        value: null,
      };
      break;
    }
  }
  return positionValues;
};

export const getTotalKPIs = (
  poolsTotalsByChain: PoolChainTotals,
  prices?: TokenPrice,
  showFailed: boolean = true
): Array<KPIProps> => {
  poolsTotalsByChain = poolsTotalsByChain ?? {};
  const totalPools = Object.keys(poolsTotalsByChain).reduce(
    (prev: number, chainId: string) => prev + poolsTotalsByChain[chainId].count,
    0
  );

  let totalTvlInEth = 0;
  for (let chainId of Object.keys(poolsTotalsByChain)) {
    totalTvlInEth += convertTokenToCurrency(
      chainId,
      poolsTotalsByChain[chainId].tvl,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
  }

  return [
    {
      tooltip:
        poolsTotalsByChain === undefined
          ? null
          : Object.keys(poolsTotalsByChain).map((chainId) => {
              const chain = getNetworkById(chainId);
              if (chain === undefined) return null;

              let tooltip = [
                <b key={`TVL-${chainId}`}>
                  <span>
                    {poolsTotalsByChain[chainId].tvl} {chain?.token}
                  </span>
                </b>,
              ];

              const tokenToCurrency = convertTokenToCurrency(
                chainId,
                poolsTotalsByChain[chainId].tvl,
                SUPPORTED_CURRENCIES.eth,
                prices
              );
              if (chainId !== BASE_CHAIN.id) {
                tooltip.push(
                  <span key={`TVL-value-${chainId}`}>
                    &nbsp; ({isNaN(tokenToCurrency) ? "-" : tokenToCurrency}{" "}
                    {SUPPORTED_CURRENCIES.eth.symbol})
                  </span>
                );
              }

              return <div>{tooltip}</div>;
            }),
      label: (showFailed ? "Total" : "Active") + " Ante Value Locked",
      value: formatTokenValue(
        isNaN(totalTvlInEth) ? "-" : totalTvlInEth.toFixed(3),
        SUPPORTED_CURRENCIES.eth.symbol
      ),
      footnote: formatFiatValue(
        toLocaleNumber(convertTokenToFiat(BASE_CHAIN.id, totalTvlInEth, prices))
      ),
    },
    {
      label: (showFailed ? "All " : "Active ") + "All Ante Pools",
      value: totalPools,
      footnote: null,
    },
  ];
};

export const getUserKPIs = (
  poolsTotalsByChain: PoolChainTotals,
  prices: TokenPrice,
  connectedChain: Chain
): Array<KPIProps> => {
  const chain = connectedChain;
  let poolsTotals = poolsTotalsByChain?.[chain.id] ?? {
    staked: 0,
    challenged: 0,
  };
  const { staked, challenged } = poolsTotals;
  const totalUserTvl = staked + challenged;
  return [
    {
      label: "My Total Value Locked",
      value: formatTokenValue(totalUserTvl.toFixed(3), chain.token),
      footnote: formatFiatValue(
        toLocaleNumber(convertTokenToFiat(chain.id, totalUserTvl, prices))
      ),
    },
    {
      label: "Total Staked",
      value: formatTokenValue(staked.toFixed(3), chain.token),
      footnote: formatFiatValue(
        toLocaleNumber(convertTokenToFiat(chain.id, staked, prices))
      ),
    },
    {
      label: "Total Challenged",
      value: formatTokenValue(challenged.toFixed(3), chain.token),
      footnote: formatFiatValue(
        toLocaleNumber(convertTokenToFiat(chain.id, challenged, prices))
      ),
    },
  ];
};

const getTvlValue = (
  totalTvl: number,
  prices: TokenPrice,
  chain?: Chain
): CellValueType => {
  return {
    numericValue: chain ? convertTokenToFiat(chain.id, totalTvl, prices) : 0,
    value: formatTokenValue(
      isNaN(totalTvl) ? "-" : totalTvl.toFixed(3),
      chain?.token
    ),
    subValue:
      chain === undefined
        ? "-"
        : formatFiatValue(
            toLocaleNumber(convertTokenToFiat(chain.id, totalTvl, prices))
          ),
  };
};

const failedTvlCell: CellValueType = {
  numericValue: 0,
  value: "Failed",
  subValue: null,
};

export const getFormattedPools = (
  pools: Array<Pool>,
  currentTimestamp: number,
  prices: TokenPrice,
  connectedChain: Chain
): Array<PoolRowProps> => {
  return pools.map((pool) => {
    const {
      isPartner,
      tvl,
      trustScore,
      antePoolAddress,
      testName,
      protocolName,
      anteTestAddress,
      poolPosition,
      stakedBalance,
      challengedBalance,
      pendingWithdraw,
      pendingWithdrawAllowedTime,
      pendingFailure,
      stakedAndPendingWithdraw,
      challengedTotalAmount,
      isTvlGteThreshold,
      chainId,
    } = pool;

    const chain = getNetworkById(chainId);
    const positionValues = getPositionValues({
      poolPosition,
      stakedBalance,
      challengedBalance,
      pendingWithdraw,
      pendingWithdrawAllowedTime,
      currentTimestamp,
      pendingFailure,
      chain,
      connectedChain,
    });

    const hasPoolFailed = pool.pendingFailure;
    return {
      name: {
        name: testName,
        protocolName,
        badges: getBadges(pool),
        poolAddress: antePoolAddress,
        address: anteTestAddress,
      },
      chain,
      address: antePoolAddress,
      totalValueLocked: pool.pendingFailure
        ? failedTvlCell
        : getTvlValue(tvl ?? 0, prices, chain),
      trustScore: {
        isTvlGteThreshold,
        trustScore: hasPoolFailed ? 0 : trustScore,
        totalStakedOverall: chainId
          ? {
              [chainId]: stakedAndPendingWithdraw,
            }
          : {},
        totalChallenged: chainId
          ? {
              [chainId]: challengedTotalAmount,
            }
          : {},
        hasPoolFailed,
      },
      position: positionValues,
      label: {
        isPartner,
      },
    } as PoolRowProps;
  });
};
