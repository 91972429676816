import {
  makeStyles,
  MenuProps,
  OutlinedInput,
  withStyles,
} from "@material-ui/core";
import colors from "style/colors";

const SelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
}))(OutlinedInput);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SelectMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const useStyles = makeStyles((theme) => ({
  controls: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: "1rem",
    gap: 8,
  },
  formControl: {
    minWidth: "8.5rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  filtersContainer: {
    display: "inline-flex",
    gap: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  filtersToggler: {
    position: "relative",
    background: colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "39px",
    borderRadius: "0.5rem",
    borderColor: colors.black23,
    borderWidth: "1px",
    borderStyle: "solid",
  },
  iconBadge: {
    position: "absolute",
    top: "-0.25rem",
    right: "-0.25rem",
    width: "1rem",
    height: "1rem",
    background: colors.primaryBlue,
    borderRadius: "8rem",
    color: colors.white,
    textAlign: "center",
    fontSize: "12px",
  },
  switchContainer: {
    justifyContent: "center",
    margin: "0px 4px 0px 4px",
  },
  switches: {
    justifyContent: "center",
    margin: "0px 4px 0px 4px",
  },
  tooltip: {
    top: "-.75rem",
    textAlign: "center",
  },
  networkInput: {
    background: colors.white,
    borderRadius: "4px",
    padding: "10px",
  },
  networkSelector: {
    maxWidth: 225,
  },
  indeterminateColor: {
    color: colors.primaryBlue,
  },
}));

export { SelectMenuProps, useStyles, SelectInput };
