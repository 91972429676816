import { Typography } from "@material-ui/core";
import cn from "classnames";
import { useSessionStorage } from "react-use-storage";
import { useStyles } from "./DashboardSelector.styles";

export const DashboardSelector = () => {
  const styles = useStyles();

  const [showProtocols, setShowProtocols] = useSessionStorage(
    "leaderboard_show_protocols",
    false
  );

  const toggleShowProtocols = () => {
    setShowProtocols(!showProtocols);
  };

  return (
    <div className={styles.viewSelector}>
      <Typography
        variant="body1"
        className={cn(styles.option, {
          selected: !showProtocols,
        })}
        onClick={toggleShowProtocols}
      >
        Ante Tests
      </Typography>
      <Typography
        variant="body1"
        className={cn(styles.option, {
          selected: showProtocols,
        })}
        onClick={toggleShowProtocols}
      >
        Protocols
      </Typography>
    </div>
  );
};
