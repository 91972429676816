import TxFlow, { TxnStatuses } from "components/TxFlow/TxFlow";
import { sendEvent, AnalyticsEvent } from "analytics/analytics";
import { setIsTxnPending } from "state/antepools/antepools";
import SnackbarUtils from "./SnackbarUtils";
import { ethers, ContractReceipt } from "ethers";
import { TransactionResponse } from "@ethersproject/providers";
import { getNetworkById, toHex } from "./utils";
import store from "state";

export type ContractCall = {
  method: Function;
  transform?: (value: any) => any;
  valueName?: string;
  skip?: boolean;
};

const WEB3_ERROR_VALUE = 3.9638773911973445e75;

const call = (method: ContractCall) => {
  return new Promise(async (resolve, reject) => {
    const {
      method: contractMethod,
      transform = (value: any) => value,
      valueName,
      skip = false,
    } = method;
    if (skip) {
      return resolve({ valueName, transformedValue: undefined });
    }

    try {
      const result = await contractMethod();

      if (Number(result) === WEB3_ERROR_VALUE) {
        console.error(`${contractMethod.name}.call`, "Contract call failure!");
        return reject(method);
      }

      let transformedValue: any = undefined;
      try {
        transformedValue = transform(result);
      } catch (error) {
        console.warn(`transforming ${valueName} has failed for: `, result);
      }

      return resolve({ valueName, transformedValue });
    } catch (error) {
      console.error(`${contractMethod.toString()} call failed`, error);
      return reject(method);
    }
  });
};

const batch = async (methods: ContractCall[]): Promise<any> => {
  const promises = methods.map((method) => call(method));

  const fetchedData = {};
  let failedMethods = [];

  const results = await Promise.allSettled(promises);
  const handleResult = (result) => {
    if (result.status === "fulfilled") {
      const { valueName, transformedValue } = result.value;
      fetchedData[valueName] = transformedValue;
    } else {
      failedMethods.push(result.reason);
    }
  };
  results.forEach((result) => {
    handleResult(result);
  });

  let retries = 5;
  while (failedMethods.length > 0) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    if (retries === 0) {
      throw new Error("failed to fetch, retries exceeded");
    }
    const retryPromises = failedMethods.map((method) => call(method));
    failedMethods = [];
    const retriedResults = await Promise.allSettled(retryPromises);
    retriedResults.forEach((result) => handleResult(result));
    retries--;
  }
  return fetchedData;
};

export type SendOptions = {
  toastMessage: string;
  analyticsProperties?: AnalyticsEvent;
  onTransactionHash?: (hash: string) => void;
  onReceipt?: (receipt: ContractReceipt) => void;
  onError?: (error: Error, transactionHash: string) => void;
};

const send = async (
  method: () => Promise<TransactionResponse>,
  options: SendOptions,
  provider: ethers.providers.Provider
): Promise<ContractReceipt> => {
  return new Promise(async (resolve, reject) => {
    const chain = getNetworkById(toHex((await provider.getNetwork()).chainId));
    const onError = (err: Error, transactionHash?: string) => {
      if (options.onError) {
        options.onError(err, transactionHash);
      }

      store.dispatch(setIsTxnPending(false));
      if (transactionHash) {
        SnackbarUtils.toast(options.toastMessage, {
          autoHideDuration: 10000,
          content: (key, message) => (
            <TxFlow
              message={message}
              txnHash={transactionHash}
              status={TxnStatuses.Reverted}
              closeSnackbar={() => SnackbarUtils.close(key)}
              chain={chain}
            />
          ),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    };

    const onTransactionHash = (transactionHash: string) => {
      if (options.onTransactionHash) {
        options.onTransactionHash(transactionHash);
      }

      store.dispatch(setIsTxnPending(true));
      SnackbarUtils.toast(options.toastMessage, {
        autoHideDuration: 10000,
        content: (key, message) => (
          <TxFlow
            message={message}
            txnHash={transactionHash}
            status={TxnStatuses.Pending}
            closeSnackbar={() => SnackbarUtils.close(key)}
            chain={chain}
          />
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      if (options.analyticsProperties) {
        const { eventName, options: analyticsOptions } =
          options.analyticsProperties;
        sendEvent(eventName, { ...analyticsOptions, transactionHash });
      }
    };

    const onReceipt = (receipt: ContractReceipt) => {
      if (options.onReceipt) {
        options.onReceipt(receipt);
      }

      store.dispatch(setIsTxnPending(false));
      const { status, transactionHash } = receipt;
      if (status) {
        SnackbarUtils.toast(options.toastMessage, {
          autoHideDuration: 10000,
          content: (key, message) => (
            <TxFlow
              message={message}
              txnHash={transactionHash}
              status={TxnStatuses.Confirmed}
              closeSnackbar={() => SnackbarUtils.close(key)}
              chain={chain}
            />
          ),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    };

    let tx: TransactionResponse;
    try {
      tx = await method();
      onTransactionHash(tx.hash);
    } catch (error: any) {
      console.error("Transaction error", error);
      window.rollbar.warn("Transaction error", error);
      // This is a temporary workaround. Onboard JS hardware provider
      // makes ethers throw an TypeError for getTransactionReceipt
      // It order not to report this false positive, we ignore the TypeError
      if (!(error instanceof TypeError)) {
        return reject(error);
      }
    }

    try {
      // const receipt = await tx.wait();
      // onReceipt(receipt);
      // resolve(receipt);
      // This is a temporary workaround. Onboard JS hardware provider
      // makes ethers throw an TypeError for getTransactionReceipt
      // It order to wait for a receipt we have to pool for transaction hash
      provider.once(tx.hash, (receipt: ContractReceipt) => {
        if (receipt.status === 0) {
          onError(new Error("Transaction Failed"), tx.hash);
          return reject("Transaction Failed");
        }

        onReceipt(receipt);
        return resolve(receipt);
      });
    } catch (error: any) {
      console.error("Receipt error", error);
      window.rollbar.warn("Receipt error", error);
      onError(error, tx.hash);
      return reject(error);
    }

    provider.on("error", (error) => {
      // This is a temporary workaround. Onboard JS hardware provider
      // makes ethers throw an TypeError for getTransactionReceipt
      // It order not to report this false positive, we ignore the TypeError
      if (!(error instanceof TypeError)) {
        return reject(error);
      }
    });
  });
};

const getWeb3Signer = (provider: any, account: string) => {
  return new ethers.providers.Web3Provider(provider).getSigner(account);
};

export const Web3Utils = {
  send,
  batch,
  call,
  getWeb3Signer,
};
