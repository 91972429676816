import { gql } from "graphql-tag";
import {
  ANTEPOOL_CORE_FIELDS,
  ANTEPOOL_DETAILS_FIELDS,
} from "../fragments/pool";

export const ANTEPOOLS_ACCESSOR = "antepoolCollections";

export const ANTEPOOL_ACCESSOR = "antepoolCollection";

/**
 * Use double negation when querying for isHidden in order to treat an
 * unexisting isHidden property as a false value.
 */
export const GET_ANTEPOOLS_NODE = gql`
  ${ANTEPOOL_CORE_FIELDS}
  query GetAntePoolsCore($versions: [String], $protocolName: String, $protocolNames: [String], $chainId: String, $isVisible: Boolean) {
    ${ANTEPOOLS_ACCESSOR}(
      query: { version_in: $versions, protocolName: $protocolName, protocolName_in: $protocolNames, chainId: $chainId, isHidden_ne: $isVisible }
    ) {
      ...AntePoolCoreFields
    }
  }
`;

export const GET_ANTEPOOL_NODE = gql`
  ${ANTEPOOL_CORE_FIELDS}
  query GetAntePoolCore($versions: [String], $address: String!, $chainId: String, $isVisible: Boolean) {
    ${ANTEPOOL_ACCESSOR}(
      query: { version_in: $versions, antePoolAddress: $address, chainId: $chainId, isHidden_ne: $isVisible }
    ) {
      ...AntePoolCoreFields
    }
  }
`;

export const GET_ANTEPOOLS_DETAILS_NODE = gql`
  ${ANTEPOOL_CORE_FIELDS}
  ${ANTEPOOL_DETAILS_FIELDS}
  query GetAntePoolsDetails($versions: [String], $protocolName: String, $protocolNames: [String], $chainId: String, $isVisible: Boolean) {
    ${ANTEPOOLS_ACCESSOR}(
      query: { version_in: $versions, protocolName: $protocolName, protocolName_in: $protocolNames, chainId: $chainId, isHidden_ne: $isVisible }
    ) {
      ...AntePoolCoreFields
      ...AntePoolDetailsFields
    }
  }
`;

export const GET_ANTEPOOL_DETAILS_NODE = gql`
  ${ANTEPOOL_CORE_FIELDS}
  ${ANTEPOOL_DETAILS_FIELDS}
  query GetAntePoolDetails($versions: [String], $address: String!, $chainId: String, $isVisible: Boolean) {
    ${ANTEPOOL_ACCESSOR}(
      query: { version_in: $versions, antePoolAddress: $address, chainId: $chainId, isHidden_ne: $isVisible }
    ) {
      ...AntePoolCoreFields
      ...AntePoolDetailsFields
    }
  }
`;
