import { useMemo, useRef, useState } from "react";

import { formatUnits as formatUnitsEthers } from "@ethersproject/units";
import { makeStyles, Popover, Typography } from "@material-ui/core";
import colors from "style/colors";
import { formatTokenValue, shortenString } from "utils/utils";
import Verified from "assets/img/verified.svg";
import { StakerInfo } from "types";
import { Chain, CHAIN_EXPLORERS } from "utils/constants";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
    color: "#2D9CDB",
    marginTop: "0.25rem",
  },
  popoverContent: {
    pointerEvents: "auto",
    padding: "1rem",
    boxShadow: "none",
    border: "1px solid #dddddd",
    borderRadius: "8px",
  },
  popoverTrigger: {
    cursor: "pointer",
    display: "inline-flex",
    marginLeft: "0.5rem",
    alignItems: "center",
    fontSize: "0.9rem",
  },
  verified: {
    marginRight: "0.25rem",
    marginLeft: "-0.08rem",
    height: "1.25rem",
  },
  link: {
    color: colors.primaryBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  staker: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#555555",
    lineHeight: "24px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  amount: {
    marginLeft: "1rem",
  },
}));

interface Props {
  stakers: StakerInfo[];
  popDirection: "left" | "center" | "right";
  title: string;
  chain: Chain;
}

const StakersPopup = ({ stakers, popDirection, title, chain }: Props) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const transformHorizontal =
    popDirection === "left"
      ? "right"
      : popDirection === "right"
      ? "left"
      : "center";
  const classes = useStyles();

  const handlePopoverOpen = () => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  const StakerView = ({ staker }: { staker: StakerInfo }) => {
    const displayName = useMemo(
      () =>
        staker.displayName?.length > 0
          ? staker.displayName
          : staker.ensName?.length > 0
          ? staker.ensName
          : shortenString(staker.address),
      [staker]
    );

    const amount = useMemo(
      () =>
        formatTokenValue(
          Number(formatUnitsEthers(staker.amount)).toFixed(2),
          chain.token
        ),
      [staker]
    );

    return (
      <div className={classes.staker}>
        {CHAIN_EXPLORERS[chain.id] ? (
          <a
            href={`${CHAIN_EXPLORERS[chain.id].address}${staker.address}`}
            color="textSecondary"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            {displayName}
          </a>
        ) : (
          displayName
        )}
        <span className={classes.amount}>{amount}</span>
      </div>
    );
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <span
        ref={popoverAnchor}
        aria-owns={openedPopover ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.popoverTrigger}
      >
        (
        <img src={Verified} alt="participant" className={classes.verified} />
        <Typography display="inline" color="primary">
          {stakers.length}
        </Typography>
        )
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: transformHorizontal,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: transformHorizontal,
        }}
        PaperProps={{
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
      >
        <div className={classes.title}>{title}</div>
        {stakers.map((staker: StakerInfo, i: number) => (
          <StakerView staker={staker} key={`staker-${i}`} />
        ))}
      </Popover>
    </div>
  );
};

export default StakersPopup;
