import { makeStyles } from "@material-ui/core";
import colors from "style/colors";
import fontType from "style/typography";

export const useStyles = makeStyles({
  testHeaderRoot: {
    marginBottom: "1.5rem",
  },
  nameContainer: {
    ...fontType.eyebrow,
  },
  headerContainer: {
    display: "flex",
    gap: ".25rem",
    alignItems: "center",
  },
  rightSideHeader: {
    display: "inline-flex",
    gap: "0.25rem",
    verticalAlign: "text-top",
    marginLeft: "auto",
  },
  addressContainer: {
    ...fontType.subtextMedium,
    marginBottom: "8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  protocolTitle: { ...fontType.titleMedium, marginBottom: "8px" },
  communityLabel: {
    backgroundColor: colors.statPurple,
    color: "white",
    padding: "4px 8px",
    borderRadius: "16px",
  },
  newTest: {
    marginLeft: "8px",
    color: colors.black69,
    backgroundColor: colors.newYellow,
    fontSize: ".5625rem",
    height: "18px",
    fontWeight: 600,
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  unverifiedLabel: {
    backgroundColor: colors.unverifiedOrange,
    color: "white",
    padding: "4px 8px",
    borderRadius: "16px",
  },
  author: {
    marginBottom: "0.5rem",
  },
  link: {
    color: colors.linkBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  description: {
    overflowWrap: "break-word",
  },
});
