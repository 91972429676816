import { useQuery, useQueryClient } from "react-query";
import { SUPPORTED_CHAINS } from "utils/constants";
import SnackbarUtils from "utils/SnackbarUtils";

export type CurrencyValue = Record<string, number>;

export type TokenPrice = Record<string, CurrencyValue>;

type TokenPriceResult = {
  prices: TokenPrice;
  loading: boolean;
  error: any;
  failureCount: number;
};

const fetcher = (input: RequestInfo, init?: RequestInit) =>
  fetch(input, init).then((res) => res.json());

const tokenIds = Object.values(SUPPORTED_CHAINS).map(
  (chain) => chain?.priceProviderId
);
const uniqueIds = Array.from(new Set(tokenIds));
const ids = uniqueIds.join(",");

const priceUrl = `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd,eth`;

export const useFetchTokenPrice = (): TokenPriceResult => {
  const queryClient = useQueryClient();

  const {
    data: prices,
    isLoading,
    failureCount,
    error,
  } = useQuery(priceUrl, () => fetcher(priceUrl), {
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: !queryClient.getQueryData(priceUrl),
    retry: 6,
    onError: () => {
      SnackbarUtils.error("Unable to fetch prices from provider");
    },
  });

  return {
    prices,
    loading: isLoading,
    error,
    failureCount,
  };
};
