import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const globalStyles = {
  link: {
    color: colors.linkBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textLeft: {
    textAlign: "left" as const,
  },
  textCenter: {
    textAlign: "center" as const,
  },
  textRight: {
    textAlign: "right" as const,
  },
  alignLeft: {
    alignItems: "flex-start",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignRight: {
    alignItems: "flex-end",
  },
  warningBox: {
    border: `1px solid ${colors.warningYellow}`,
    background: colors.warningYellow23,
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
};

export const useGlobalStyles = makeStyles(globalStyles);
