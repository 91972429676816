import Typography from "@material-ui/core/Typography";

import { useLeaderboardStyles } from "pages/Leaderboard";
import CommunityLeaderboardTable from "./CommunityLeaderboardTable";

export const CommunityLeaderboard = () => {
  const leaderboardClasses = useLeaderboardStyles();
  return (
    <div className={leaderboardClasses.grid}>
      <div className={leaderboardClasses.tableContainer}>
        <Typography variant="h4" className={leaderboardClasses.title}>
          Ante Community Leaderboard
        </Typography>
        <CommunityLeaderboardTable />
      </div>
    </div>
  );
};
