import { useEffect, useState } from "react";
import { Pool, PoolBalances } from "types";
import { usePoolsBalances } from "./usePoolsBalances";

export const usePoolBalances = (pool: Pool) => {
  const [fetchedPools, setFetchedPools] = useState([]);

  useEffect(() => {
    setFetchedPools([pool]);
  }, [pool]);

  const { balances, isLoading } = usePoolsBalances(fetchedPools);

  return {
    balances: balances[pool.antePoolAddress] ?? ({} as PoolBalances),
    loading: isLoading,
  };
};
