import { AnteRow, AnteRowType } from "components/AnteTable/AnteRow";
import { Link } from "react-router-dom";
import { ProtocolRowProps } from "./useColumns";

export const ProtocolRow = (props: AnteRowType<ProtocolRowProps>) => {
  const { rowData } = props;

  if (typeof rowData.original.headingProps?.name === "undefined") {
    return <AnteRow {...props} />;
  }

  return (
    <Link
      to={`/protocol/${rowData.original.headingProps?.name}`}
      {...rowData.getRowProps()}
    >
      <AnteRow {...props} />
    </Link>
  );
};
