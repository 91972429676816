import { gql } from "graphql-tag";

export const TEST_WRITER_FIELDS = gql`
  fragment TestWriterFields on TestWriterLeaderboardCollection {
    _id
    contributor
    address
    ens
    anteTestsSubmitted
    firstContribution
    githubUrl
    twitterUrl
    isVerified
    anteTests {
      protocol
      tests {
        name
        githubUrl
      }
    }
  }
`;
