import { useCallback, useEffect, useState } from "react";
import cn from "classnames";

import { isAddress } from "@ethersproject/address";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import PoolFactoryVideo from "assets/video/createpoolfactory.mp4";
import createPoolStyles from "pages/CreatePool/createPool.styles";

import { AnalyticsEvents } from "analytics/analytics";
import { useWallet } from "utils/wallet";

import { errors } from "./errors";
import { ConnectWalletView } from "pages/ConnectWallet";

import CircularProgress from "@material-ui/core/CircularProgress";
import ImportantInfoIcon from "@material-ui/icons/ReportProblemOutlined";
import { useAntePool } from "hooks/antepools/useAntePool";
import { createAntePoolStub } from "./utils";
import { useSelectCreatePool } from "state/selectors";
import { ProgressNotification } from "./components/ProgressNotification";
import {
  initialState,
  patchState,
  setPoolAddress,
} from "state/createPool/createPool";
import { useDispatch } from "react-redux";
import { Web3Utils } from "utils/web3-utils";
import { Contract, ContractReceipt } from "ethers";
import useAnteSDK from "hooks/useAnteSDK";
import { Log } from "@ethersproject/providers";
import { getV05VersionByChain } from "utils/utils";
import { Factory } from "@antefinance/ante-sdk";

export const CreatePool = () => {
  const dispatch = useDispatch();
  const {
    isSubmitting,
    poolAddress,
    testAddress: submittedTestAddress,
  } = useSelectCreatePool();

  const [testAddress, setTestAddress] = useState(submittedTestAddress ?? "");
  const [state, setState] = useState({
    warning: "",
    isValidTest: false,
  });

  const { warning, isValidTest } = state;

  const wallet = useWallet();
  const ante = useAnteSDK();
  const { provider, account, isNetworkSupported, refreshBalance, networkId } =
    wallet;

  // will be null until pool stub is added, used in useEffect hook to
  // display successful creation message to user
  const { pool } = useAntePool(poolAddress, false, networkId);

  const validateTestAddress = useCallback(
    async (address: string) => {
      const validAddress = isAddress(address);
      if (!validAddress) {
        setState({
          warning: "This is not a valid Ethereum address",
          isValidTest: false,
        });
        return;
      }

      try {
        const anteFactory = ante.getPoolFactory(
          getV05VersionByChain(networkId)
        );
        const factoryContract: Contract = anteFactory.getContractInstance(
          anteFactory.poolFactoryAddress
        );
        await factoryContract.callStatic.createPool(address);
      } catch (err: any) {
        window.rollbar.info("CreatePool:validateTestAddress", err);
        const error = errors.find((e) => err.message.includes(e.string));
        setState({
          warning: error
            ? error.helperMessage
            : "There is an error with this address",
          isValidTest: false,
        });
        return;
      }
      setState({
        warning: "",
        isValidTest: true,
      });
    },
    [networkId, ante]
  );

  useEffect(() => {
    if (!testAddress) return;
    validateTestAddress(testAddress);
  }, [validateTestAddress, testAddress]);

  const handleAddressChange = async (evt) => {
    const input = evt.target.value;
    setTestAddress(input);
  };

  const submitPool = async () => {
    dispatch(
      patchState({
        ...initialState,
        testAddress: testAddress,
        hasSubmitted: true,
        isSubmitting: true,
      })
    );

    try {
      const anteFactory = ante.getPoolFactory(
        getV05VersionByChain(networkId)
      ) as Factory.AntePoolFactoryInstance;

      await Web3Utils.send(
        () =>
          anteFactory.createPool(
            { testAddress },
            Web3Utils.getWeb3Signer(provider, account)
          ),
        {
          toastMessage: "Creating Pool",
          analyticsProperties: {
            eventName: AnalyticsEvents.createPoolSubmitted,
          },
          onReceipt: (receipt: ContractReceipt) => {
            const factoryContract: Contract = anteFactory.getContractInstance(
              anteFactory.poolFactoryAddress
            );

            let poolCreatedLog: Log;
            try {
              poolCreatedLog = receipt.logs.find((log) => {
                const logDescription = factoryContract.interface.parseLog(log);
                return logDescription.name === "AntePoolCreated";
              });
            } catch (error: any) {
              console.error("Unable to fetch the created pool", error);
              window.rollbar.error("CreatePool:submitPool:onReceipt", error);
            }
            if (!poolCreatedLog) {
              dispatch(
                patchState({
                  isSubmitting: false,
                  submissionError: "Unable to fetch the created pool.",
                })
              );
              return;
            }

            const event = factoryContract.interface.parseLog(poolCreatedLog);
            const testPool = event.args.testPool;
            createAntePoolStub(wallet, testPool, testAddress);
            dispatch(setPoolAddress(testPool));
          },
        },
        ante.getProvider()
      );

      setTestAddress("");

      dispatch(
        patchState({
          testAddress: "",
          isSubmitting: false,
        })
      );

      refreshBalance();
    } catch (err: any) {
      console.error(err);
      window.rollbar.warn("CreatePool:submitPool", err);
      dispatch(
        patchState({
          isSubmitting: false,
          submissionError: err.message ?? err,
        })
      );
      // TODO: error-specific handling
    }
  };

  const styles = createPoolStyles();

  let createPoolContent;
  if (!account || (account && !isNetworkSupported)) {
    createPoolContent = <ConnectWalletView />;
  } else {
    createPoolContent = (
      <Card className={styles.container}>
        <Typography variant="h5" className={styles.heading}>
          Create an Ante Pool
        </Typography>
        <Card variant="outlined" className={styles.infoCard}>
          <ImportantInfoIcon fontSize="large" />
          <Typography
            variant="body2"
            className={cn(styles.leftSpacing, styles.info)}
          >
            Before creating an Ante Pool, you must first deploy your Ante Test
            on Mainnet (refer to the{" "}
            <a
              className={styles.link}
              href="https://docs.ante.finance/antev05/for-devs/deploying-an-ante-test/deploy-an-ante-test"
              target="_blank"
              rel="noreferrer"
            >
              documentation
            </a>{" "}
            if you need help with this step).
          </Typography>
        </Card>
        <div className={styles.videoPlayer}>
          <video autoPlay loop muted playsInline className={styles.video}>
            <source src={PoolFactoryVideo} type="video/mp4" />
          </video>
        </div>
        <Typography variant="body2" className={styles.info}>
          <p>
            Ante Pools are the structures through which people can stake and
            challenge the Ante Test you've witten. You can create an Ante Pool
            for your Ante Test using the Ante Pool Factory below (this will cost
            gas).
          </p>
          <p>
            After deploying your Ante Pool, it will show up on the Ante
            Leaderboard as <span className={styles.infoLabel}>unverified</span>.
            To get verified, open a pull request against our community
            repository{" "}
            <a
              className={styles.link}
              href="https://github.com/antefinance/ante-community-tests"
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </p>
        </Typography>
        <Typography variant="subtitle2" className={styles.inputLabel}>
          Ante Test address
        </Typography>
        <div className={styles.inputContainer}>
          <TextField
            error={warning ? true : false}
            value={testAddress}
            variant="outlined"
            className={warning ? styles.inputWarning : styles.input}
            inputProps={{ className: styles.addressInput, spellCheck: false }}
            placeholder="0x..."
            onChange={handleAddressChange}
          />
          {testAddress.length > 0 && warning && (
            <Typography variant="caption" className={styles.warning}>
              {warning}
            </Typography>
          )}
        </div>
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          disabled={!isValidTest || isSubmitting}
          onClick={submitPool}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Deploy Ante Pool"}
        </Button>

        <ProgressNotification pool={pool} />
      </Card>
    );
  }

  return createPoolContent;
};
