import { AnteColumn } from "components/AnteTable";
import { Row } from "react-table";
import { TrustScoreStats } from "types";
import {
  CellValueType,
  ChainCell,
  SkeletonTestHeadingCell,
  SkeletonTrustScoreCell,
  SkeletonValueCell,
  TestHeadingCell,
  TestHeadingCellValueType,
  TrustScoreCell,
  ValueCell,
} from "components/AnteTable/cells";
import { useCellStyles } from "components/AnteTable/cells.styles";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import colors from "style/colors";
import { Chain } from "utils/constants";

export interface ProtocolTestRowProps {
  name: TestHeadingCellValueType;
  address: string;
  chain: Chain;
  totalStakedByProtocol: CellValueType;
  totalValueLocked: CellValueType;
  trustScore: TrustScoreStats;
}

const useTestCellStyles = makeStyles((theme) => ({
  heading: {
    color: colors.primaryBlue,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      margin: "0 4px",
    },
  },
}));

const useColumns = (protocolName: string) => {
  const cellStyles = useCellStyles();
  const testCellStyles = useTestCellStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const columns: AnteColumn<ProtocolTestRowProps>[] = [
    {
      Header: "Ante test",
      accessor: "name",
      Cell: TestHeadingCell,
      SkeletonCell: SkeletonTestHeadingCell,
      styles: { ...cellStyles, ...testCellStyles },
      classNames: cellStyles.headingCellContainer,
    },
    {
      Header: "",
      accessor: "chain",
      Cell: ChainCell,
    },
    {
      Header: `Staked by ${protocolName}`,
      accessor: "totalStakedByProtocol",
      sortType: (
        a: Row<ProtocolTestRowProps>,
        b: Row<ProtocolTestRowProps>
      ): number => {
        return (
          a.original.totalStakedByProtocol?.numericValue -
          b.original.totalStakedByProtocol?.numericValue
        );
      },
      align: "right",
      Cell: ValueCell,
      SkeletonCell: SkeletonValueCell,
      styles: cellStyles,
    },
    {
      id: "ante-value-locked",
      Header: "Ante Value Locked",
      accessor: "totalValueLocked",
      sortType: (
        a: Row<ProtocolTestRowProps>,
        b: Row<ProtocolTestRowProps>
      ): number => {
        return (
          a.original.totalValueLocked?.numericValue -
          b.original.totalValueLocked?.numericValue
        );
      },
      align: "right",
      Cell: ValueCell,
      SkeletonCell: SkeletonValueCell,
      styles: cellStyles,
    },
    {
      Header: isMobile ? "Decen. Trust Score" : "Decentralized Trust Score",
      accessor: "trustScore",
      sortType: (
        a: Row<ProtocolTestRowProps>,
        b: Row<ProtocolTestRowProps>
      ): number => {
        return (
          a.original.trustScore?.trustScore - b.original.trustScore?.trustScore
        );
      },
      align: "center",
      tooltip: `The Decentralized Trust Score is calculated by dividing the total amount staked supporting the test (not including funds pending withdrawal) by the total amount staked + challenged`,
      Cell: TrustScoreCell,
      SkeletonCell: SkeletonTrustScoreCell,
      styles: cellStyles,
    },
  ];

  return { columns };
};

export default useColumns;
