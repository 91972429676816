import { makeStyles } from "@material-ui/core";
import colors from "style/colors";
import fontType from "style/typography";

export const useStyles = makeStyles((theme) => ({
  root: { padding: "8px" },
  tableHeading: {
    ...fontType.headingSmall,
    fontWeight: 600,
    color: colors.black69,
    margin: "1rem 0",
  },
  statsRow: {
    display: "flex",
    gap: "1rem",
    marginBottom: "1.5rem",
  },
  kpisContainer: {
    display: "flex",
    padding: ".5rem 0 1rem 0",
    flexWrap: "wrap",
    gap: ".5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "& div": {
        minWidth: "8rem",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "space-between",
    },
  },
  kpi: {
    flex: 1,
  },
  stats: {
    flex: 1,
  },
  audit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      alignItems: "initial",
      flexDirection: "column",
    },
  },
  auditName: {
    ...fontType.kpiMedium,
    color: colors.statPurple,
    display: "inline-flex",
    marginTop: ".375rem",
  },
  bugBounty: {
    display: "flex",
    flexDirection: "column",
  },
  bugBountyValue: {
    ...fontType.kpiMedium,
    color: colors.statPurple,
    margin: ".375rem 0",
  },
  securityTitle: {
    ...fontType.headingSmall,
    fontWeight: 600,
  },
}));
