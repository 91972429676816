import { AnteColumn } from "components/AnteTable";
import { Row } from "react-table";
import { Rating, ratings, TrustScoreStats } from "types";
import {
  CellValueType,
  ChainsCell,
  ProtocolHeadingCell,
  RatingCell,
  SkeletonChainsCell,
  SkeletonHeadingCell,
  SkeletonRatingCell,
  SkeletonTrustScoreCell,
  SkeletonValueCell,
  TrustScoreCell,
  ValueCell,
} from "components/AnteTable/cells";
import { useCellStyles } from "components/AnteTable/cells.styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Chain } from "utils/constants";

export type ProtocolHeadingProps = {
  name: string;
  chains: Chain[];
};

export interface ProtocolRowProps {
  headingProps: ProtocolHeadingProps;
  rating: Rating;
  chains: Chain[];
  totalValueLocked: CellValueType;
  trustScore: TrustScoreStats;
  totalStakedByProtocol: CellValueType;
}

const useColumns = () => {
  const cellStyles = useCellStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let columns: AnteColumn<ProtocolRowProps>[] = [
    {
      Header: "Protocol",
      accessor: "headingProps",
      Cell: ProtocolHeadingCell,
      SkeletonCell: SkeletonHeadingCell,
      styles: cellStyles,
      classNames: cellStyles.headingCellContainer,
    },
    {
      Header: "Rating",
      accessor: "rating",
      sortType: (
        a: Row<ProtocolRowProps>,
        b: Row<ProtocolRowProps>
      ): number => {
        return (
          ratings.indexOf(b.original.rating) -
          ratings.indexOf(a.original.rating)
        );
      },
      align: "center",
      Cell: RatingCell,
      SkeletonCell: SkeletonRatingCell,
      styles: cellStyles,
    },
    {
      id: "ante-value-locked",
      Header: "Ante Value Locked",
      accessor: "totalValueLocked",
      sortType: (
        a: Row<ProtocolRowProps>,
        b: Row<ProtocolRowProps>
      ): number => {
        return (
          a.original.totalValueLocked?.numericValue -
          b.original.totalValueLocked?.numericValue
        );
      },
      align: "right",
      Cell: ValueCell,
      SkeletonCell: SkeletonValueCell,
      styles: cellStyles,
    },
    {
      Header: isMobile ? "Decen. Trust Score" : "Decentralized Trust Score",
      accessor: "trustScore",
      sortType: (
        a: Row<ProtocolRowProps>,
        b: Row<ProtocolRowProps>
      ): number => {
        return (
          (isNaN(a.original.trustScore?.trustScore)
            ? 0
            : a.original.trustScore?.trustScore) -
          (isNaN(b.original.trustScore?.trustScore)
            ? 0
            : b.original.trustScore?.trustScore)
        );
      },
      align: "center",
      tooltip: `The Decentralized Trust Score is calculated by dividing the total amount staked supporting the test (not including funds pending withdrawal) by the total amount staked + challenged`,
      Cell: TrustScoreCell,
      SkeletonCell: SkeletonTrustScoreCell,
      styles: cellStyles,
    },
    {
      Header: "Staked by protocol",
      accessor: "totalStakedByProtocol",
      sortType: (
        a: Row<ProtocolRowProps>,
        b: Row<ProtocolRowProps>
      ): number => {
        return (
          a.original.totalStakedByProtocol?.numericValue -
          b.original.totalStakedByProtocol?.numericValue
        );
      },
      align: "right",
      Cell: ValueCell,
      SkeletonCell: SkeletonValueCell,
      styles: cellStyles,
    },
  ];

  if (!isMobile) {
    columns.splice(1, 0, {
      Header: "Chains",
      accessor: "chains",
      Cell: ChainsCell,
      SkeletonCell: SkeletonChainsCell,
      styles: cellStyles,
      sortType: (
        a: Row<ProtocolRowProps>,
        b: Row<ProtocolRowProps>
      ): number => {
        return a.original.chains?.length - b.original.chains?.length;
      },
    });
  }

  return { columns };
};

export default useColumns;
