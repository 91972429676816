import { makeStyles } from "@material-ui/core";
import GearBg from "assets/img/gearwave.png";
import colors from "style/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: `center / cover no-repeat url(${GearBg})`,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  versionTag: {
    color: "white",
    backgroundColor: colors.statPurple,
    padding: "8px 16px",
    borderRadius: "20px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    width: "fit-content",
    position: "absolute",
    bottom: "24px",
    right: "24px",
    zIndex: 1000,
  },
  container: {
    width: 640,
    maxWidth: "95%",
    display: "flex",
    flexDirection: "row",
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  leftContainer: {
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginBottom: "1rem",
    },
  },
  rightContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  titleText: {
    fontSize: "2rem",
    fontWeight: 500,
    lineHeight: "3rem",
  },
  subtitleText: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
}));
