import { fetchPoolStub } from "utils/antepools/fetchAntePoolsPublic";
import { createPoolStub } from "utils/antepools/utils";
import { PoolsCache } from "utils/antepools/cache";
import { WalletData } from "utils/wallet";

export const createAntePoolStub = async (
  walletData: Partial<WalletData>,
  antePoolAddress,
  anteTestAddress
) => {
  const poolStub = createPoolStub(antePoolAddress, anteTestAddress);
  const pool = await fetchPoolStub(poolStub, walletData);

  PoolsCache.setPool(pool, walletData);
};
