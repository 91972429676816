import { Button, Grid, Typography } from "@material-ui/core";
import AddChallengeModal from "pages/AnteTestDetail/modals/AddChallengeModal";
import VerifyModal from "pages/AnteTestDetail/modals/VerifyModal";
import WithdrawChallengeModal from "pages/AnteTestDetail/modals/WithdrawChallengeModal";
import { useContext, useState } from "react";
import { useSelectTxnPending } from "state/selectors";
import { ActionPanelContext } from "../ActionPanelContext";

type ChallengedPositionActionsProps = {
  canVerify: boolean;
};
export const ChallengedPositionActions = ({
  canVerify,
}: ChallengedPositionActionsProps) => {
  const { pool, submitting } = useContext(ActionPanelContext);

  const [addChallengeVisible, setAddChallengeVisible] = useState(false);
  const [withdrawChallengeVisible, setWithdrawChallengeVisible] =
    useState(false);
  const [verifyVisible, setVerifyVisible] = useState(false);

  const handleAddChallengeOpen = () => {
    setAddChallengeVisible(true);
  };
  const handleWithdrawChallengeOpen = () => {
    setWithdrawChallengeVisible(true);
  };
  const handleVerifyOpen = () => {
    setVerifyVisible(true);
  };

  const isTxnPending = useSelectTxnPending();
  const verifiability = `Available at block ${String(
    pool.checkTestAllowedBlock
  )}`;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddChallengeOpen}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Add Challenge
        </Button>
        <AddChallengeModal
          isShown={addChallengeVisible}
          hide={() => {
            setAddChallengeVisible(false);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleWithdrawChallengeOpen}
          fullWidth
          disabled={submitting || isTxnPending}
        >
          Withdraw Challenge
        </Button>
        <WithdrawChallengeModal
          isShown={withdrawChallengeVisible}
          hide={() => {
            setWithdrawChallengeVisible(false);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleVerifyOpen}
          fullWidth
          disabled={!canVerify || submitting || isTxnPending}
        >
          Check Test
        </Button>
        {!canVerify && (
          <Typography
            variant="caption"
            component="div"
            style={{
              color: "#777777",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            {verifiability}
          </Typography>
        )}
        {
          <VerifyModal
            isShown={verifyVisible}
            hide={() => {
              setVerifyVisible(false);
            }}
          />
        }
      </Grid>
    </Grid>
  );
};
