import React, { useCallback, useEffect } from "react";

import { useWallet } from "utils/wallet";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { SUPPORTED_CHAINS } from "utils/constants";
import { useButtonStyles, usePopoverStyles } from "./NetworkPopover.styles";
import { getNetworkById } from "utils/utils";
import { useMediaQuery, useTheme } from "@material-ui/core";
import cn from "classnames";

const NetworkPopover = () => {
  const { account, networkId, setChain } = useWallet();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const buttonStyles = useButtonStyles();
  const popoverStyles = usePopoverStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const updateSelectedChain = useCallback((chainId: string): void => {
    const selectedChain = Object.values(SUPPORTED_CHAINS).find(
      (chain) => chain.id === chainId
    );

    if (!selectedChain) {
      return;
    }

    // Close popover
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (networkId === undefined) {
      return;
    }
    updateSelectedChain(networkId);
  }, [networkId, updateSelectedChain]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChainChange = (chainId: string) => {
    if (account) {
      setChain(chainId);
    }

    updateSelectedChain(chainId);
  };

  const _renderSupportedChains = () => {
    return Object.keys(SUPPORTED_CHAINS).map((networkKey) => {
      const network = SUPPORTED_CHAINS[networkKey];

      return (
        <Grid
          item
          xs={12}
          className={popoverStyles.network}
          key={network.id}
          onClick={() => handleChainChange(network.id)}
        >
          <Grid container key={network.id}>
            <Grid item xs={2}>
              {network.logo && (
                <img
                  src={network.logo}
                  alt={network.label}
                  className={buttonStyles.logo}
                />
              )}
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="body1"
                className={cn({
                  [popoverStyles.selected]: networkId === network.id,
                })}
              >
                {network.label}
              </Typography>
              {networkId === network.id && (
                <Typography variant="caption" color="textSecondary">
                  Currently selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const chain = getNetworkById(networkId);

  return (
    <div>
      <Button
        variant="outlined"
        className={buttonStyles.root}
        onClick={handleClick}
      >
        {chain.logo && (
          <img
            src={chain.logo}
            alt={chain.label}
            className={buttonStyles.logo}
          />
        )}
        {!isMobile && (
          <Typography variant="body1" className={buttonStyles.text}>
            {chain.label}
          </Typography>
        )}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: popoverStyles.root,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Container className={popoverStyles.container}>
          <Grid container spacing={2}>
            {_renderSupportedChains()}
          </Grid>
        </Container>
        <Divider />
      </Popover>
    </div>
  );
};

export default NetworkPopover;
