import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";

import { cardStyles } from "style/styles";
import TestHeader from "pages/AnteTestDetail/components/TestHeader";
import TestKeyStats from "pages/AnteTestDetail/components/TestKeyStats";
import AntePoolSummary from "pages/AnteTestDetail/components/SummaryView";
import ContractsView from "pages/AnteTestDetail/components/ContractsView";
import TestCodeView from "pages/AnteTestDetail/components/TestCodeView";
import ActionPanel from "pages/AnteTestDetail/components/ActionPanel/ActionPanel";
import { sendEvent } from "analytics/analytics";
import { useLeaderboardStyles } from "pages/Leaderboard";
import { ErrorCard } from "pages/ErrorPage";
import { useAntePool } from "hooks/antepools/useAntePool";
import { PoolPositions } from "types";
import { useStyles } from "./AnteTestDetail.styles";
import { SkeletonAnteTestDetail } from "./AnteTestDetail.skeleton";
import { LinkTab } from "./components/LinkTab";
import { TabPanel } from "./components/TabPanel";
import { PoolAnalyticsEvents } from "analytics/pool";
import { getNetworkById, getTokenByChain } from "utils/utils";
import { Helmet } from "react-helmet-async";

interface RouteParams {
  address: string;
  chainId?: string;
}

const fetchPoolWithData = true;
export const AnteTestDetail = () => {
  const params = useRouteMatch().params as RouteParams;
  const contractAddress = params.address;
  const { pool, loading, loadingOffChain } = useAntePool(
    contractAddress,
    fetchPoolWithData,
    params.chainId
  );
  const cardClasses = cardStyles();
  const leaderboardClasses = useLeaderboardStyles();
  const styles = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (pool === undefined) return;
    const { protocolName, antePoolAddress } = pool;
    if (protocolName === undefined || antePoolAddress === undefined) return;

    sendEvent(PoolAnalyticsEvents.testDetailsView, {
      address: antePoolAddress,
      protocol: protocolName,
    });
  }, [pool]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue !== value) {
      sendEvent(PoolAnalyticsEvents.testDetailsTabClicked, {
        tab: tabs[newValue].label,
      });
    }
    setValue(newValue);
  };

  if (loadingOffChain) {
    return <SkeletonAnteTestDetail />;
  }

  if (!loadingOffChain && pool === undefined) {
    return (
      <div className={styles.noContentContainer}>
        <ErrorCard subtitle="We can't find that page." />
      </div>
    );
  }

  const {
    challengedBalanceUnit,
    challengerInfo,
    stakedBalanceUnit,
    stakingInfo,
    pendingWithdrawAmount,
    testedContracts,
    totalStaked: stakedAvl,
    totalChallengerStaked: challengerAvl,
    stakedAndPendingWithdraw,
    stakedTotalAmount,
    pendingWithdraw,
    challengedTotalAmount,
    verifiedStakers,
    verifiedChallengers,
  } = pool || {};
  const tabs = [
    { label: "Summary", href: "/" },
    { label: "Tested Contracts", href: "/source" },
    { label: "Ante Test", href: "/test-code" },
  ];

  let position: PoolPositions = undefined;
  if (stakedBalanceUnit && challengedBalanceUnit) {
    if (stakedBalanceUnit.gt(0) || pendingWithdrawAmount?.gt(0)) {
      position = PoolPositions.Staked;
    } else if (challengedBalanceUnit.gt(0)) {
      position = PoolPositions.Challenged;
    } else {
      position = PoolPositions.None;
    }
  }
  const chain = getNetworkById(pool.chainId);
  const metaDescription = pool.pendingFailure
    ? ``
    : `Trust Score: ${Number(pool.trustScore.toFixed(2))} | TVL: ${
        pool.tvl
      } ${getTokenByChain(pool.chainId)}`;
  return (
    <Grid container>
      {pool && (
        <Helmet>
          <title>Ante Test | {pool.anteTestName}</title>
          <meta name="description" content={metaDescription} />
          <meta
            property="og:title"
            content={`Ante Test | ${pool.anteTestName}`}
          />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      )}
      <Grid
        item
        xs={12}
        md={8}
        lg={8}
        xl={9}
        className={leaderboardClasses.tableContainer}
      >
        <Card className={cn(cardClasses.root, styles.root)}>
          <CardContent>
            <TestHeader
              loading={loadingOffChain}
              contractAddress={contractAddress}
              pool={pool}
            />
            <TestKeyStats loading={loadingOffChain} pool={pool} />
            <Tabs
              value={value}
              indicatorColor="primary"
              onChange={handleChange}
              variant="scrollable"
              className={styles.tabs}
            >
              {tabs.map((tab) => (
                <LinkTab label={tab.label} href={tab.href} key={tab.label} />
              ))}
            </Tabs>
            <TabPanel value={value} index={0}>
              <AntePoolSummary
                pool={pool}
                stakingInfo={stakingInfo}
                challengerInfo={challengerInfo}
                pendingWithdraw={pendingWithdraw}
                verifiedStakers={verifiedStakers}
                verifiedChallengers={verifiedChallengers}
                stakedAvl={stakedAvl}
                challengerAvl={challengerAvl}
                stakedAndPendingWithdraw={stakedAndPendingWithdraw}
                stakedTotalAmount={stakedTotalAmount}
                challengedTotalAmount={challengedTotalAmount}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContractsView testedContracts={testedContracts} chain={chain} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TestCodeView address={pool?.anteTestAddress} chain={chain} />
            </TabPanel>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        lg={4}
        xl={3}
        className={leaderboardClasses.stats}
      >
        <ActionPanel pool={pool} poolPosition={position} loading={loading} />
      </Grid>
    </Grid>
  );
};
