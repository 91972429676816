import { makeStyles } from "@material-ui/core";
import GearBg from "assets/img/gearwave.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: `center / cover no-repeat url(${GearBg})`,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    minHeight: "calc(100vh - 200px)",
    padding: "0 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 12px",
      minHeight: "calc(100vh - 300px)",
    },
  },
}));
