import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

const fontType = {
  titleLarge: {
    fontSize: "32px",
    lineHeight: "48px",
    fontWeight: 500,
  },
  titleMedium: {
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  headingSmall: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 400,
  },
  eyebrow: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 600,
    textTransform: "uppercase" as const,
    letterSpacing: "0.05em",
    color: colors.black54,
  },
  labelLarge: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    marginBottom: "4px",
  },
  labelMedium: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    marginBottom: "4px",
    color: colors.black69,
  },
  labelSmall: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    marginBottom: "4px",
    color: colors.black69,
  },
  kpiLarge: {
    fontSize: "32px",
    lineHeight: "36px",
    fontWeight: 600,
  },
  kpiMedium: {
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors.statPurple,
  },
  kpiSmall: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
  },
  nav: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 400,
    color: colors.linkBlue,
  },
  bodyMedium: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  bodySmall: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
  subtextMedium: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: colors.black42,
  },
  subtextSmall: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
  },
  buttonMedium: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors.linkBlue,
  },
  tabHeading: {
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 400,
    color: colors.linkBlue,
    textTransform: "capitalize" as const,
  },
};

export const useTypography = makeStyles(fontType);

export default fontType;
