import { createSlice } from "@reduxjs/toolkit";
import { PoolsState } from "state/types";

const initialState: PoolsState = {
  isTxnPending: false,
};

export const antePoolsSlice = createSlice({
  name: "Pools",
  initialState,
  reducers: {
    setIsTxnPending: (state, action) => {
      state.isTxnPending = action.payload;
    },
  },
});

// Actions
export const { setIsTxnPending } = antePoolsSlice.actions;

export default antePoolsSlice.reducer;
