import { Card, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./ErrorPage.styles";
import AnteLogoWordmark from "assets/ante/ante-logo-wordmark.svg";
import ErrorMaskot from "assets/img/error-maskot.png";
import { useGlobalStyles } from "utils/styles";

export type ErrorCardProps = {
  title?: string;
  subtitle?: string;
  text?: React.ReactNode;
};

const ErrorCard = ({ title, subtitle, text }: ErrorCardProps) => {
  const styles = useStyles();
  const globalStyles = useGlobalStyles();
  return (
    <Card className={styles.container} data-testid="not-found-card">
      <div className={styles.leftContainer}>
        <RouterLink to="/">
          <img src={AnteLogoWordmark} alt="Ante Protocol" height="40" />
        </RouterLink>
        <div className={styles.title}>
          <Typography variant={"h3"} className={styles.titleText}>
            {title ? title : "Sorry!"}
          </Typography>
          <Typography variant={"h5"} className={styles.subtitleText}>
            {subtitle}
          </Typography>
        </div>
        {text ? (
          text
        ) : (
          <Typography variant={"body1"}>
            You can return to{" "}
            <RouterLink to="/" className={globalStyles.link}>
              our homepage
            </RouterLink>{" "}
            or{" "}
            <a
              href="https://ante.finance/feedback"
              target="_blank"
              rel="noopener noreferrer"
              className={globalStyles.link}
            >
              drop us a line
            </a>{" "}
            if you can't find what you're looking for.
          </Typography>
        )}
      </div>
      <div className={styles.rightContainer}>
        <img src={ErrorMaskot} alt="Error" />
      </div>
    </Card>
  );
};

export { ErrorCard };
