export const errors = [
  {
    string: "Ante: TESTPOOL_EXISTS",
    helperMessage: "A pool already exists for this Ante Test",
  },
  {
    string: "ANTE: Pool already created",
    helperMessage: "A pool already exists for this Ante Test",
  },
  {
    string:
      "ANTE: AnteTest either does not implement checkTestPasses or test currently fails",
    helperMessage:
      "This test is currently failing or does not implement checkTestPasses",
  },
  {
    string:
      "function selector was not recognized and there's no fallback function",
    helperMessage: "This address does not point to an AnteTest",
  },
  {
    string: "ANTE: AnteTest must be a smart contract",
    helperMessage: "This address is not a smart contract",
  },
];
