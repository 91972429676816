import { formatEther, formatUnits } from "@ethersproject/units";
import { WeiPerEther } from "@ethersproject/constants";
import { BigNumber } from "@ethersproject/bignumber";

import { TVL_THRESHOLD } from "utils/constants";
import ObjectID from "bson-objectid";
import { StakerInfo, Pool, PoolPositions } from "types";
import {
  ALTERNATE_PROTOCOL_NAMES,
  getNetworkById,
  newPoolDateThreshold,
  toHex,
} from "utils/utils";
import { BadgeType } from "types/BadgeType";
import { ethers } from "ethers";

export const createPoolStub = (
  antePoolAddress: string,
  anteTestAddress: string
) => {
  const timestamp = new Date().getTime();
  let stub = {
    antePoolAddress: antePoolAddress,
    anteTestAddress: anteTestAddress,
    timestamp: timestamp,
    totalChallengerStaked: [
      {
        value: 0,
        timestamp: timestamp,
      },
    ],
    totalStaked: [
      {
        value: 0,
        timestamp: timestamp,
      },
    ],
    verifiedStakers: [],
    verifiedChallengers: [],
  };
  return stub as Pool;
};

export const getFailedBlockTimestamp = async (
  pool: Pool
): Promise<number | undefined> => {
  if (!pool.pendingFailure) return undefined;
  const chain = getNetworkById(pool.chainId);
  if (!chain) return undefined;
  let failedTimestamp: number;

  const httpProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);

  let remainingRetries = 3;
  while (failedTimestamp === undefined && remainingRetries > 0) {
    try {
      const block = await httpProvider.getBlock(
        toHex(Number(pool.lastVerifiedBlock))
      );
      failedTimestamp = block.timestamp;
    } catch (e) {
      console.error(
        "Failed to fetch block",
        toHex(Number(pool.lastVerifiedBlock)),
        e
      );
      await new Promise((resolve) => setTimeout(resolve, 200));
    } finally {
      remainingRetries--;
    }
  }

  return failedTimestamp;
};

/**
 * Trust Score = (total staked in non-failed tests) /
 *    (AVL in non-failed tests + 5% of pre-failure AVL in tests that failed within the past 3 months)
 */
export const calculateTrustScore = (
  tvl: BigNumber,
  totalStaked: BigNumber,
  preFailureTvl: BigNumber = BigNumber.from(0)
): number => {
  if (!tvl || tvl.eq(0)) return 0;

  return Number(
    formatUnits(
      totalStaked.mul(10000).div(
        tvl.add(
          // 5% of pre-failure TVL
          preFailureTvl.div(20)
        )
      ),
      2
    )
  );
};

export const formatPools = async (pools: Pool[]): Promise<Pool[]> => {
  return await Promise.all(pools.map(formatPool));
};

export const formatPool = async (pool): Promise<Pool> => {
  const {
    stakingInfo,
    challengerInfo,
    totalPendingWithdraw,
    pendingWithdrawAmount,
    stakedBalanceUnit,
    challengedBalanceUnit,
    pendingFailure,
  } = pool;
  let trustScore = 0;
  let stakedTotalAmount = 0;
  let challengedTotalAmount = 0;
  let pendingWithdraw = 0;
  let stakedBalance = 0;
  let challengedBalance = 0;
  let poolPosition = PoolPositions.None;
  let tvl_bn = BigNumber.from(0);
  let tvl = 0;
  let isTvlGteThreshold = true;
  let stakedAndPendingWithdraw = 0;
  let stakedAndPendingWithdraw_bn = BigNumber.from(0);
  // get time record was created
  let timestamp = pool._id
    ? new ObjectID(pool._id).getTimestamp()
    : pool.timestamp;

  try {
    if (stakingInfo && challengerInfo && totalPendingWithdraw) {
      tvl_bn = stakingInfo.totalAmount
        .add(challengerInfo.totalAmount)
        .add(totalPendingWithdraw);
      stakedAndPendingWithdraw_bn =
        stakingInfo.totalAmount.add(totalPendingWithdraw);
      stakedAndPendingWithdraw = Number(
        formatEther(stakedAndPendingWithdraw_bn)
      );
      stakedTotalAmount = Number(formatEther(stakingInfo.totalAmount));
      challengedTotalAmount = Number(formatEther(challengerInfo.totalAmount));
    }

    if (stakedBalanceUnit && challengedBalanceUnit && pendingWithdrawAmount) {
      stakedBalance = Number(formatEther(stakedBalanceUnit));
      challengedBalance = Number(formatEther(challengedBalanceUnit));
      pendingWithdraw = Number(formatEther(pendingWithdrawAmount));
      if (stakedBalanceUnit.gt(0) || pendingWithdrawAmount.gt(0)) {
        poolPosition = PoolPositions.Staked;
      } else if (challengedBalanceUnit.gt(0)) {
        poolPosition = PoolPositions.Challenged;
      }
    }

    trustScore = pendingFailure
      ? 0
      : calculateTrustScore(tvl_bn, stakedAndPendingWithdraw_bn);
    tvl = Number(formatEther(tvl_bn));
    isTvlGteThreshold = tvl_bn.gte(WeiPerEther.mul(TVL_THRESHOLD));
  } catch (e) {}

  return {
    ...pool,
    protocolName:
      ALTERNATE_PROTOCOL_NAMES[pool.protocolName] ?? pool.protocolName,
    timestamp,
    tvl,
    tvl_bn,
    trustScore,
    stakedTotalAmount,
    challengedTotalAmount,
    isTvlGteThreshold,
    pendingWithdraw,
    stakedBalance,
    challengedBalance,
    poolPosition,
    stakedAndPendingWithdraw,
  };
};

export const updateStakerAmounts = (
  stakers: StakerInfo[],
  updatedStakerBalances: Record<string, BigNumber>
) => {
  if (
    updatedStakerBalances === undefined ||
    Object.entries(updatedStakerBalances).length === 0
  ) {
    return stakers;
  }

  return stakers.map((staker: StakerInfo) => {
    let newAmount = updatedStakerBalances[staker.address]
      ? BigNumber.from(updatedStakerBalances[staker.address]).toString()
      : null;
    return {
      ...staker,
      amount: newAmount ?? staker.amount,
    };
  });
};

export type PoolBadgeMap = {
  [key in BadgeType]?: boolean;
};

export const getBadges = (pool: Pool): PoolBadgeMap => {
  const {
    protocolStaked,
    teamVerified,
    isCommunityPool,
    writtenByTeam,
    timestamp,
  } = pool;

  return {
    selfStaked: protocolStaked,
    anteReviewed: teamVerified && (isCommunityPool || writtenByTeam),
    new: timestamp > newPoolDateThreshold(),
  };
};
