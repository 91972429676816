import {
  calculateAggregateProtocolStats,
  getFormattedAntePools,
  ProtocolChainStatsMap,
  ProtocolStats,
} from "pages/ProtocolProfilePage/utils";
import { Pool, Protocol, TrustScoreStats } from "types";
import {
  convertTokenToCurrency,
  convertTokenToFiat,
  formatFiatValue,
  formatTokenValue,
  getNetworkById,
  getRating,
  getUnderlyingAssetsTooltipFromChainStats,
  toLocaleNumber,
} from "utils/utils";
import { CellValueType } from "components/AnteTable/cells";
import { ProtocolRowProps } from "./useColumns";
import { TokenPrice } from "hooks/useFetchTokenPrice";
import {
  BASE_CHAIN,
  SUPPORTED_CHAINS,
  SUPPORTED_CURRENCIES,
} from "utils/constants";

const getTvlValue = (
  statsByChain: ProtocolChainStatsMap,
  prices: TokenPrice
): CellValueType => {
  let totalTvlInEth = 0;
  for (let chainId of Object.keys(statsByChain)) {
    totalTvlInEth += convertTokenToCurrency(
      chainId,
      statsByChain[chainId].totalTvl,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
  }

  return {
    tooltip: getUnderlyingAssetsTooltipFromChainStats(
      statsByChain,
      "totalTvl",
      prices
    ),
    numericValue: convertTokenToFiat(BASE_CHAIN.id, totalTvlInEth, prices),
    value: formatTokenValue(
      isNaN(totalTvlInEth) ? "-" : totalTvlInEth.toFixed(3),
      SUPPORTED_CURRENCIES.eth.symbol
    ),
    subValue: formatFiatValue(
      toLocaleNumber(convertTokenToFiat(BASE_CHAIN.id, totalTvlInEth, prices))
    ),
  };
};

const getTotalStakedValue = (
  statsByChain: ProtocolChainStatsMap,
  prices: TokenPrice
): CellValueType => {
  let totalStakedByProtocolInEth = 0;
  for (let chainId of Object.keys(statsByChain)) {
    totalStakedByProtocolInEth += convertTokenToCurrency(
      chainId,
      statsByChain[chainId].totalStakedByProtocol,
      SUPPORTED_CURRENCIES.eth,
      prices
    );
  }

  return {
    tooltip: getUnderlyingAssetsTooltipFromChainStats(
      statsByChain,
      "totalStakedByProtocol",
      prices
    ),
    numericValue: convertTokenToFiat(
      BASE_CHAIN.id,
      totalStakedByProtocolInEth,
      prices
    ),
    value: formatTokenValue(
      isNaN(totalStakedByProtocolInEth)
        ? "-"
        : totalStakedByProtocolInEth.toFixed(3),
      SUPPORTED_CURRENCIES.eth.symbol
    ),
    subValue: formatFiatValue(
      toLocaleNumber(
        convertTokenToFiat(BASE_CHAIN.id, totalStakedByProtocolInEth, prices)
      )
    ),
  };
};

const getTrustScoreValue = (
  protocolStats: ProtocolStats,
  hasPoolFailed: boolean = false
): TrustScoreStats => {
  const { aggregateTrustScore, statsByChain } = protocolStats;

  return {
    isTvlGteThreshold: Object.values(statsByChain).some(
      (chainStats) => chainStats.isTvlGteThreshold
    ),
    trustScore: aggregateTrustScore,
    totalStakedOverall: Object.keys(statsByChain).reduce((prev, chainId) => {
      if (!chainId) return prev;
      prev[chainId] = statsByChain[chainId].totalStakedOverall;
      return prev;
    }, {}),
    totalChallenged: Object.keys(statsByChain).reduce((prev, chainId) => {
      if (!chainId) return prev;
      prev[chainId] = statsByChain[chainId].totalChallenged;
      return prev;
    }, {}),
    hasPoolFailed: hasPoolFailed,
  };
};

const defaultSupportedChainMap = Object.values(SUPPORTED_CHAINS).reduce(
  (obj, chain) => {
    obj[chain.id] = false;
    return obj;
  },
  {}
);

export const getFormattedProtocols = async (
  pools: Array<Pool>,
  protocols: Array<Protocol>,
  prices: TokenPrice
): Promise<Array<ProtocolRowProps>> => {
  return (
    await Promise.all(
      protocols.map(async (protocol) => {
        const chainIds = { ...defaultSupportedChainMap };

        const protocolPools = pools.filter((pool) => {
          const isPoolForProtocol =
            pool.protocolName.toLowerCase() === protocol.name.toLowerCase();

          if (isPoolForProtocol) {
            chainIds[pool.chainId] = true;
          }

          return isPoolForProtocol;
        });

        if (protocolPools.length === 0) {
          return null;
        }

        const formattedPools = getFormattedAntePools(protocolPools, protocol);
        const protocolStats = await calculateAggregateProtocolStats(
          formattedPools,
          prices
        );
        const { aggregateTrustScore, totalTvlInEth, statsByChain } =
          protocolStats;

        const rating = getRating(totalTvlInEth, aggregateTrustScore);

        const chains = Object.keys(chainIds)
          .filter((chainId) => chainIds[chainId])
          .map((chainId) => getNetworkById(chainId));

        return {
          headingProps: {
            name: protocol.name,
            chains,
          },
          rating,
          chains,
          totalValueLocked: getTvlValue(statsByChain, prices),
          trustScore: getTrustScoreValue(protocolStats),
          totalStakedByProtocol: getTotalStakedValue(statsByChain, prices),
        };
      })
    )
  ).filter((v) => !!v);
};
