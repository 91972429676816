import { useState } from "react";
import cn from "classnames";
import CaretIcon from "assets/img/caret.svg";
import { useStyles } from "./CommunityLeaderboardRow.styles";
import { useStyles as useCommunityLeaderboardStyles } from "./CommunityLeaderboardTable.styles";

const CommunityLeaderboardRow = ({
  contributor,
  githubUrl,
  rank,
  testsSubmitted,
  firstContribution,
  anteTests,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const styles = useCommunityLeaderboardStyles();
  const rowStyles = useStyles();
  return (
    <div
      className={rowStyles.root}
      key={contributor}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className={rowStyles.row}>
        <div className={cn(rowStyles.rowItem, styles.rank)}>
          <div
            className={cn(rowStyles.medal, {
              [rowStyles.first]: rank === 1,
              [rowStyles.second]: rank === 2,
              [rowStyles.third]: rank === 3,
            })}
          >
            {rank}
          </div>
        </div>
        <div className={cn(rowStyles.rowItem, styles.contributor)}>
          <a
            href={githubUrl}
            className={rowStyles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contributor}
          </a>
        </div>
        <div className={cn(rowStyles.rowItem, styles.testCount)}>
          {testsSubmitted}
        </div>
        <div
          className={cn(rowStyles.rowItem, styles.date, rowStyles.rowItemSmall)}
        >
          {firstContribution.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </div>
        <div
          className={cn(rowStyles.iconContainer, {
            [rowStyles.rotate]: !isExpanded,
          })}
        >
          <img src={CaretIcon} alt="caret" className={rowStyles.expandIcon} />
        </div>
      </div>
      <div
        className={cn(rowStyles.expandedSection, {
          [rowStyles.hideSection]: !isExpanded,
        })}
      >
        <div className={rowStyles.testList}>
          {anteTests.map((testGroup) => {
            return (
              <div className={rowStyles.protocolGroup} key={testGroup.protocol}>
                <div className={rowStyles.protocolName}>
                  {testGroup.protocol.toUpperCase()}
                </div>
                {testGroup.tests.map(({ name, githubUrl }) => (
                  <a
                    key={`${name}-${githubUrl}`}
                    href={githubUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cn(rowStyles.testName, rowStyles.link)}
                    onClick={(evt) => evt.stopPropagation()}
                  >
                    {name}
                  </a>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CommunityLeaderboardRow;
