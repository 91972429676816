import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import KPI from "components/KPI/KPI";
import { useContext } from "react";
import { accordionStyles as useAccordionStyles } from "style/styles";
import { ActionPanelContext } from "../ActionPanelContext";
import {
  formatUnits as formatUnitsEthers,
  parseUnits,
} from "@ethersproject/units";
import {
  convertBNTokenToBNFiat,
  convertTokenToFiat,
  formatFiatValue,
  formatTokenValue,
  getTokenByChain,
  toLocaleNumber,
} from "utils/utils";
import { useFetchTokenPrice } from "hooks/useFetchTokenPrice";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type StakedPositionContentProps = {
  unlocking: boolean;
};

export const StakedPositionContent = ({
  unlocking,
}: StakedPositionContentProps) => {
  const { prices } = useFetchTokenPrice();

  const { pool, loading } = useContext(ActionPanelContext);

  const {
    stakedBalanceUnit,
    pendingWithdrawAmount,
    stakingInfo,
    challengerInfo,
    stakedStartAmount,
  } = pool;
  const token = getTokenByChain(pool.chainId);
  const accordionStyles = useAccordionStyles();
  const isLocked = stakedBalanceUnit?.gt(0) && pendingWithdrawAmount?.eq(0);

  const rewards = Number(
    formatUnitsEthers(stakedBalanceUnit.sub(stakedStartAmount))
  );
  const initialBalance = Number(formatUnitsEthers(stakedStartAmount));
  const currentBalanceETH = Number(formatUnitsEthers(stakedBalanceUnit));

  const DECAY_PER_WEEK_PER_ETH = parseUnits("100", "gwei").mul(44000);
  const decay_per_week =
    stakingInfo?.totalAmount.gt(0) && challengerInfo?.totalAmount
      ? Number(
          formatUnitsEthers(
            DECAY_PER_WEEK_PER_ETH.mul(stakedBalanceUnit)
              .div(stakingInfo.totalAmount)
              .mul(challengerInfo.totalAmount)
              .div(parseUnits("1", "ether"))
          )
        )
      : 0;
  const usdDecay = `~${formatFiatValue(
    toLocaleNumber(convertTokenToFiat(pool.chainId, decay_per_week, prices))
  )}`;
  const value = pendingWithdrawAmount?.gt(0)
    ? pendingWithdrawAmount
    : stakedBalanceUnit;
  const formatted = Number(formatUnitsEthers(value)).toFixed(3);
  const usdValue = formatFiatValue(
    toLocaleNumber(
      Number(
        formatUnitsEthers(
          convertBNTokenToBNFiat(pool.chainId, value, prices) ?? 0
        )
      )
    )
  );

  const status = isLocked
    ? "(locked)"
    : unlocking
    ? "(unlocking)"
    : "(unlocked)";

  if (!isLocked) {
    return (
      <div>
        <KPI
          label="My Staker Position"
          value={formatTokenValue(formatted, token)}
          status={status}
          footnote={usdValue}
          isLoaded={!loading}
        />
      </div>
    );
  }
  return (
    <div>
      <KPI
        label="My Staker Position"
        value={formatTokenValue(formatted, token)}
        status={status}
        footnote={usdValue}
        isLoaded={!loading}
      />
      <br />
      <KPI
        label="Potential Weekly Rewards"
        value={`~${formatTokenValue(decay_per_week.toFixed(3), token)}`}
        footnote={usdDecay}
        isLoaded={!loading}
      />

      <Accordion className={accordionStyles.root}>
        <AccordionSummary
          className={accordionStyles.root}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Stake Breakdown</Typography>
        </AccordionSummary>
        <AccordionDetails className={accordionStyles.root}>
          <TableContainer>
            <Table size="small" aria-label="challenge breakdown">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Initial balance
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        initialBalance.toFixed(16),
                        token
                      )}
                      placement="top"
                      arrow
                    >
                      <span>{initialBalance.toFixed(3)}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Rewards earned
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(rewards.toFixed(16), token)}
                      placement="top"
                      arrow
                    >
                      <span>{rewards.toFixed(3)}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Total balance
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={formatTokenValue(
                        currentBalanceETH.toFixed(16),
                        token
                      )}
                      placement="top"
                      arrow
                    >
                      <span>{currentBalanceETH.toFixed(3)}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
