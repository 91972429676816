import { Tooltip, Zoom } from "@material-ui/core";
import cn from "classnames";
import { AnteRow, AnteRowType } from "components/AnteTable/AnteRow";
import { Link } from "react-router-dom";
import { useStyles } from "./PoolRow.styles";
import { PoolRowProps } from "./useColumns";

export type PoolRowLabelType = {
  isPartner: boolean;
};

export const PoolRow = (props: AnteRowType<PoolRowProps>) => {
  const { rowData } = props;
  const styles = useStyles({ numColumns: props.numberOfColumns });

  const LABEL_MAP = {
    partner: {
      tooltip:
        "Ante Partners have demonstrated their commitment to responsible smart contract security by working with the Ante Team to write and stake their own Ante Tests.",
      style: styles.partnerLabel,
      text: "PARTNER",
    },
  };

  const getLabelDetails = () => {
    if (!rowData.original?.label) return null;
    const value: PoolRowLabelType = rowData.original.label;
    const { isPartner } = value;

    if (isPartner === true) {
      return LABEL_MAP["partner"];
    }

    return null;
  };

  const renderLabel = () => {
    const label = getLabelDetails();
    if (label === null) return null;
    return (
      <Tooltip
        title={label.tooltip}
        placement="top"
        TransitionComponent={Zoom}
        arrow
        interactive
        classes={{
          tooltip: styles.tooltip,
        }}
      >
        <div className={cn(styles.label, label.style)}>{label.text}</div>
      </Tooltip>
    );
  };

  if (typeof rowData.original.address === "undefined") {
    return (
      <AnteRow {...props} prepend={renderLabel()} classNames={styles.poolRow} />
    );
  }

  return (
    <Link
      data-testselector="table-test-row"
      to={`/contract/${rowData.original.chain?.id}/${rowData.original.address}`}
      {...rowData.getRowProps()}
    >
      <AnteRow {...props} prepend={renderLabel()} classNames={styles.poolRow} />
    </Link>
  );
};
