import { makeStyles, OutlinedInput, withStyles } from "@material-ui/core";

export const SelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
}))(OutlinedInput);

export const useStyles = makeStyles((theme) => ({
  controls: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: "1rem",
    marginBottom: "1rem",
    gap: 8,
  },
  formControl: {
    minWidth: "8.5rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  select: {
    padding: "10px",
    background: "white",
    borderRadius: "4px",
  },
}));
