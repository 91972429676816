import { Card, CardContent, Typography } from "@material-ui/core";
import cn from "classnames";
import Verified from "assets/img/verified.svg";
import twitter from "assets/img/social_media/twitter.svg";
import discord from "assets/img/social_media/discord.svg";
import telegram from "assets/img/social_media/telegram.svg";
import { cardStyles as useCardStyles } from "style/styles";
import { useStyles } from "./DetailsCard.styles";
import { Protocol } from "types";
import { TrustBadge } from "components/TrustBadge";
import { ProtocolVersion, Rating } from "types";
import { DetailsSkeleton } from "./DetailsCard.skeleton";
import { useGlobalStyles } from "utils/styles";

const SOCIAL_MEDIA_IMAGES = {
  twitter,
  discord,
  telegram,
} as const;

type DetailsCardProps = {
  isLoading: boolean;
  rating: Rating;
  protocol: Protocol;
  versions: Array<ProtocolVersion>;
  protocolName: string;
};

export const DetailsCard = ({
  isLoading,
  protocol,
  versions,
  rating,
  protocolName,
}: DetailsCardProps) => {
  const globalStyles = useGlobalStyles();
  const cardStyles = useCardStyles();
  const styles = useStyles();
  const latestVersion = versions?.[0];

  const renderFeaturedLinks = () => {
    if (latestVersion === undefined || latestVersion?.resources.length === 0)
      return null;
    return (
      <>
        <br />
        <Typography variant={"subtitle2"} className={styles.featuredTitle}>
          <b>Featured links</b>
        </Typography>
        {latestVersion?.resources.map((resource) => (
          <a
            key={resource?.url}
            className={styles.link}
            rel="noopener noreferrer"
            target="_blank"
            href={resource?.url}
          >
            {resource?.linkText?.replaceAll(/https?:\/\//g, "")}
          </a>
        ))}
      </>
    );
  };
  const renderContent = () => {
    if (isLoading) {
      return <DetailsSkeleton protocolName={protocolName} />;
    }
    if (protocol === undefined) return null;
    return (
      <>
        <div data-testid="page-title" className={styles.nameContainer}>
          Protocol Profile
        </div>
        <div className={styles.protocolInfoContainer}>
          <div className={styles.protocolLeftSideContainer}>
            <div className={styles.logoContainer}>
              <img
                src={protocol.logo}
                alt={protocol.name}
                width={"100%"}
                height={"100%"}
                onLoad={(event) =>
                  ((
                    event.target as HTMLImageElement
                  ).parentElement.style.display = "flex")
                }
              />
            </div>
            <div className={styles.protocolTitle}>
              <span data-testid="protocol-name">{protocol.name}</span>
              {protocol.isVerified && (
                <img
                  src={Verified}
                  alt="Verified Protocol"
                  className={styles.verified}
                />
              )}
            </div>
          </div>
          <div className={styles.protocolRightSideContainer}>
            <TrustBadge rating={rating} showTooltip={true} />
          </div>
        </div>
        <div className={styles.protocolContactsContainer}>
          <a
            className={styles.protocolUrl}
            rel="noopener noreferrer"
            target="_blank"
            href={protocol.websiteUrl}
          >
            {protocol.websiteUrl?.replaceAll(/https?:\/\//g, "")}
          </a>
          <div className={styles.protocolSocialMediaContainer}>
            {protocol.socialMedia.map(
              ({ type, url }) =>
                type &&
                url &&
                SOCIAL_MEDIA_IMAGES[type] && (
                  <a href={url} key={`social-${type}`}>
                    <img src={SOCIAL_MEDIA_IMAGES[type]} alt={type} />
                  </a>
                )
            )}
          </div>
        </div>
        <div className={styles.description}>{protocol.description}</div>
        {renderFeaturedLinks()}
      </>
    );
  };
  return (
    <Card className={cn(cardStyles.root, styles.root)}>
      <CardContent>
        {renderContent()}
        <div className={styles.contactUs}>
          Need to edit this page?&nbsp;
          <a
            href={`mailto:help@ante.finance?subject=[EDIT REQUEST for ${protocolName}]: `}
            rel="noopener noreferrer"
            target="_blank"
            className={globalStyles.link}
          >
            Contact us
          </a>
          .
        </div>
      </CardContent>
    </Card>
  );
};
